import { Route, Routes, useNavigate } from "react-router-dom";
import AddUnit from "./AddUnit";
import MoreDetails from "./UnitDetails";
import Units from "./Units";

import styles from "../../../../styles/sections/admin/units/Units.module.css";

const UnitsRoutes = () => {
  return (
    <div className={styles.admin_units_content}>
      <Routes>
        <Route exact path="/" element={<Units />} />
        <Route exact path="/addunit/:unitId?" element={<AddUnit />} />
        <Route path="/:unitId" element={<MoreDetails />} />
      </Routes>
    </div>
  );
};

export default UnitsRoutes;
