import { useState } from "react";
import styles from "../../../styles/common/image_verification/ImageVerification.module.css";
import BackDrop from "../back_drop/BackDrop";

const FileViewer = (props) => {
  const [previewState, setPreviewState] = useState(false);
  const clickHandler = () => setPreviewState(!previewState);

  return (
    <div className={styles.image_verification}>
      <p className={styles.verification_header}>{props.header}</p>
      <div className={styles.img_wrap}>
        <p>{props.fileName}</p>
        <div className={styles.button_wrap}>
          <span
            onClick={() => {
              if (props.fileName === "No File uploaded") {
                alert("No file uploaded");
              } else {
                setPreviewState(!previewState);
              }
            }}
          >
            Preview
          </span>
          {/* <span>Download</span> */}
        </div>
      </div>
      {previewState && (
        <BackDrop
          state={clickHandler}
          title={props.fileName}
          tenantId={props?.tenantId}
          assetName={props.fileId}
          additionalData={props?.additionalData}
          // button_text="Send Contract"
        />
      )}
    </div>
  );
};

export default FileViewer;
