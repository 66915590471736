// const Joi = require("joi");
import Joi from "joi";

const indianPhoneNumberPattern = /^[6789]\d{9}$/; // Valid Indian mobile numbers start with 6, 7, 8, or 9
const australianPhoneNumberPattern = /^(?:\+61|0)[2378]\d{8}$/; // Valid Australian phone numbers start with +61 or 0

// Login Validation
const loginValidation = Joi.object({
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .required()
    .trim()
    .custom((value, helpers) => {
      if (!value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
        return helpers.message(
          "Invalid email format. Please enter a valid email address."
        );
      }
      return value;
    })
    .messages({
      "string.email": "Email must be a valid email address.",
      "any.required": "Email is required.",
      "string.empty": "Email cannot be empty.",
    }),
  password: Joi.string()
    .min(8)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^_&*])/)
    .messages({
      "string.pattern.base":
        "Password must include at least one lowercase, uppercase, digit, and special character.",
      "string.min": "Password must be at least 8 characters long",
    }),
});

//Change password validation
const changePasswordValidation = Joi.object({
  password: Joi.string()
    .min(8)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^_&*])/)
    .messages({
      "string.pattern.base":
        "Password must include at least one lowercase, uppercase, digit, and special character.",
      "string.min": "Password must be at least 8 characters long",
    }),
  confirmPassword: Joi.string().valid(Joi.ref("password")).required().messages({
    "any.only": "Passwords must match",
    "any.required": "Confirm password is required",
  }),
});

/** *******************************
 * ADD NEW TENANT DATA ADMIN-FORM *
 ********************************** */

const paymentModeOptions = ["CREDIT CARD", "CASH", "NET BANKING"];

const tenantSchema = Joi.object({
  tenantName: Joi.string().required(),
  unitId: Joi.string().required(),
  // unitName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string(),
  whatsapp: Joi.string(),
  // .pattern(australianPhoneNumberPattern)
  // .message("Invalid Australian contact number format."),
  weeklyRent: Joi.number().required().min(1),
  bondAmount: Joi.number().required().min(1),
  incrementPercent: Joi.number().required().allow(null),
  startDate: Joi.date().iso().required(),
  endDate: Joi.date().iso(),
  tentativeStayDuration: Joi.number().required().min(1), // Allow null if it's optional
  paymentMode: Joi.string()
    .valid(...paymentModeOptions)
    .insensitive()
    .required(),
  isActive: Joi.boolean(),
  // startweekPaymentDate: Joi.date().iso(),
}).options({ abortEarly: false });

const tenantUpdateProfileSchema = Joi.object({
  tenantName: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string().required(),
}).options({ abortEarly: false });
/** *******************************
 * TENANT FORM II DATA VALIDATION *
 ********************************** */

const tenantFormIIValidation = Joi.object({
  companyName: Joi.string().required().allow("", null),
  mobile: Joi.string().required(),
  whatsapp: Joi.string(),
  indianContactNumber: Joi.string(),
  visaType: Joi.string().valid("long term", "short term").allow("", null),
  visaValidity: Joi.number().integer().positive().allow(null),
  bankName: Joi.string().allow("", null),
  bankAccountNo: Joi.string().allow("", null),
  ifscCode: Joi.string().allow("", null),
  emergencyContact: Joi.string(),
  country: Joi.string().allow("", null),
  state: Joi.string().allow("", null),
  permanentAddress: Joi.string().allow("", null),
  passportUrl: Joi.object().allow("", null),
  identificationDocUrl: Joi.object().allow("", null),
}).options({ abortEarly: false });

/** *****************************
 * ADD NEW UNIT DATA ADMIN-FORM *
 ******************************** */

const unitSchema = Joi.object({
  unitName: Joi.string().required(),
  unitAddress: Joi.string().required(),
  leaseTakenOn: Joi.date().required(),
  agencyName: Joi.string().required(),
  weeklyRent: Joi.number().required().min(1),
  agentName: Joi.string(),
  bondAmount: Joi.number().required().min(1),
  contactEmail: Joi.string().email({ tlds: { allow: false } }),
  electricityAgencyName: Joi.string(),
  gasAgencyName: Joi.string(),
  contactLandline: Joi.number(),
  noOfPosition: Joi.number().required().min(1),
  availablePosition: Joi.number(),
  internetProvider: Joi.string(),
  unitType: Joi.string().valid("Male", "Female", "Family", "All").required(),
  nextCleaningDate: Joi.date().iso(),
  cleaningFrequency: Joi.number(),
  plannedExpenses: Joi.object({
    electricityAgency: Joi.object({
      budgetAllocatedWeekly: Joi.number().min(1),
      budgetAllocatedMonthly: Joi.number(),
    }),
    gasAgency: Joi.object({
      budgetAllocatedWeekly: Joi.number(),
      budgetAllocatedMonthly: Joi.number(),
    }),
    internetProvider: Joi.object({
      budgetAllocatedWeekly: Joi.number(),
      budgetAllocatedMonthly: Joi.number().required().min(1),
    }),
    cleaner: Joi.object({
      budgetAllocatedWeekly: Joi.number(),
      budgetAllocatedMonthly: Joi.number(),
    }),
    employee: Joi.object({
      budgetAllocatedWeekly: Joi.number(),
      budgetAllocatedMonthly: Joi.number(),
    }),
    miscellaneous: Joi.object({
      budgetAllocatedWeekly: Joi.number(),
      budgetAllocatedMonthly: Joi.number(),
    }),
  }),
}).options({ abortEarly: false });

const setupExpenseSchema = Joi.object({
  expenseId: Joi.string(),
  name: Joi.string().required(),
  price: Joi.number().required().min(0),
}).options({ abortEarly: false });

/** *****************************
 * paymentValidationSchema form *
 ******************************** */

const paymentValidationSchema = Joi.object({
  paymentCategory: Joi.string().valid("Deposit", "Rent", "Other").required(),
  paymentMode: Joi.string()
    .valid("CREDIT CARD", "CASH", "NET BANKING")
    .required(),
  paymentAmount: Joi.number().required().min(1),
  // balance: Joi.number(),
  // bankAccountNo: Joi.string().required(),
  // bankName: Joi.string(),
  // startPaymentDate: Joi.date().iso(),
  // endPaymentDate: Joi.date().iso(),
  actualPaymentDate: Joi.date().iso().required(),
  description: Joi.string(),
  // tenantId: Joi.string(),
  // tenantName: Joi.string(),
  // isPaymentApproved: Joi.boolean(),
  asset: Joi.object().required(),
}).options({ abortEarly: false });

const adminPaymentValidationSchema = Joi.object({
  paymentCategory: Joi.string().valid("Deposit", "Rent", "Other").required(),
  paymentMode: Joi.string()
    .valid("CREDIT CARD", "CASH", "NET BANKING")
    .required(),
  // rentAmount: Joi.number().min(1),
  paymentAmount: Joi.number().required(),
  // balance: Joi.number().min(0),
  actualPaymentDate: Joi.date().iso().required(),
  description: Joi.string(),
  rentFrequency: Joi.number(),
}).options({ abortEarly: false });

const contractFormValidationSchema = Joi.object({
  asset: Joi.object().required(),
  tenantId: Joi.string().required(),
});

const signedContractFormValidationSchema = Joi.object({
  asset: Joi.object().required(),
  requestId: Joi.string().required(),
});

//PARKING SLOT SCHEMA
const parkingSlotValidationSchema = Joi.object({
  parkingSlot: Joi.string().required(),
  provider: Joi.string().allow("", null),
  vehicleNumber: Joi.string().allow("", null),
  contactName: Joi.string().allow("", null),
  rent: Joi.number().min(1).allow(0, null),
  contactNumber: Joi.string(),
  isOccupied: Joi.boolean(),
});

//CLEANING SCHEMA
const objectIdSchemaPattern = /^[0-9a-fA-F]{24}$/;
const cleaningSchema = Joi.object({
  cleanerName: Joi.string().required(),
  fee: Joi.number().required(),
  cleaningDate: Joi.date(),
  cleaningType: Joi.string()
    .valid("Regular", "Deep", "Special")
    .default("Regular"),
});

/** ****************
 * Employee Schema *
 ******************* */

const employeeSchema = Joi.object({
  userType: Joi.string().valid("EMPLOYEE"),
  name: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  mobile: Joi.string().required(),
  whatsapp: Joi.string(),
  designation: Joi.string(),
  salary: Joi.number(),
  startDate: Joi.date().required(),
  endDate: Joi.date(),
  isActive: Joi.boolean().required(),
  featureAccess: Joi.array().required(),
}).options({ abortEarly: false });

const reviseRentSchema = Joi.object({
  date: Joi.date().required(),
  rentPerWeek: Joi.number().required().min(1),
  description: Joi.string(),
}).options({ abortEarly: false });

const deactivateActivateTenantSchema = Joi.object({
  flag: Joi.string().valid("ACTIVATE", "DEACTIVATE").required(),
  date: Joi.date().iso().required(),
  unitId: Joi.string().when("flag", { is: "ACTIVATE", then: Joi.required() }),
  bondAmount: Joi.string().when("flag", {
    is: "ACTIVATE",
    then: Joi.required(),
  }),
  rentPerWeek: Joi.number()
    .positive()
    .when("flag", { is: "ACTIVATE", then: Joi.required() }),
}).options({ abortEarly: false });

// add inventory schema
const addInventorySchema = Joi.object({
  name: Joi.string().required(),
  quantity: Joi.number().required().min(1),
  // price: Joi.number().required().min(1),
  // description: Joi.string().required(),
  // image: Joi.object().required(),
});

const addIssue = Joi.object({
  content: Joi.string().required(),
});
const adminAddIssue = Joi.object({
  content: Joi.string().required(),
  unitId: Joi.string().required(),
});

const updateIssue = Joi.object({
  status: Joi.string().valid("open", "in_progress", "on_hold", "resolved"),
});

//CAR PARKING & AMENITIES REQUEST
const tenantRequestSchema = Joi.object({
  requestType: Joi.string().valid("Amenities", "Car Parking").required(),
  requestedItems: Joi.string().when("requestType", {
    is: "Amenities",
    then: Joi.required(),
  }),
  unitId: Joi.string(),
  vehicleNumber: Joi.string().when("requestType", {
    is: "Car Parking",
    then: Joi.required(),
  }),
});
export {
  tenantSchema,
  unitSchema,
  employeeSchema,
  setupExpenseSchema,
  tenantFormIIValidation,
  tenantUpdateProfileSchema,
  loginValidation,
  changePasswordValidation,
  paymentValidationSchema,
  adminPaymentValidationSchema,
  parkingSlotValidationSchema,
  cleaningSchema,
  contractFormValidationSchema,
  signedContractFormValidationSchema,
  reviseRentSchema,
  deactivateActivateTenantSchema,
  addInventorySchema,
  addIssue,
  adminAddIssue,
  updateIssue,
  tenantRequestSchema,
};
