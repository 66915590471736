import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useState } from "react";
import FormDropDown from "../../../common/form_dropdown/FormDropDown";
import ActionButton from "../../../common/action_button/ActionButton";
import AddIcon from "../../../../assets/common/add_icon.svg";

const CarParkingForm = ({
  form_fields,
  handleFieldChange,
  currentParkingId = 0,
  setOpenFormModal,
  formHandler,
}) => {
  const [options, setOptions] = useState([]);
  const [image, setImage] = useState([]);

  const handleSelect = (id, option) => {
    handleFieldChange(id, option, false, "");
    setOptions([...options, { [`${id}`]: option }]);
  };

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {currentParkingId === 0 ? "Add" : "Edit"} Parking Slot
          </div>
          <button
            onClick={() => {
              setOpenFormModal(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <div className={styles.form__components}>
          {form_fields.map((item, index) => {
            return (
              <div className={styles.input_wrap} key={index}>
                <label className={styles.input_name}>{item.field_name}</label>
                {(item.type === "text" ||
                  item.type === "number" ||
                  item.type === "date" ||
                  item.type === "email" ||
                  item.type === "address") && (
                  <input
                    className={
                      `${styles.input_field}  ${styles.field}` +
                      " " +
                      (item.error ? styles.field_error : "")
                    }
                    type={item.type}
                    // onChange={(event) => {
                    //   item.value = event.target.value;
                    // }}
                    onChange={(event) => {
                      handleFieldChange(
                        item.field_id,
                        event.target.value,
                        false,
                        ""
                      );
                    }}
                    placeholder={item.placeholder}
                    value={item.value}
                  />
                )}
                {item.type === "button" && (
                  <input
                    type="file"
                    onChange={({ target: { files } }) => {
                      // files[0] && setFileName(files[0].name);
                      if (files) {
                        setImage([...image, files[0]]);
                      }
                    }}
                    className={`${styles.upload_button}  ${styles.field}`}
                  />
                )}
                {item.type === "dropdown" && (
                  <FormDropDown
                    options={item.options}
                    name={item.field_name}
                    id={item.field_id}
                    selectHandler={handleSelect}
                  />
                )}
                {item.error && (
                  <p className={styles.error_message}>{item.error_message}</p>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <ActionButton
            buttonText={
              (currentParkingId === 0 ? "Add" : "Update") + " Parking Slot"
            }
            icon={AddIcon}
            onClick={formHandler}
          />
        </div>
      </form>
    </div>
  );
};

export default CarParkingForm;
