import React, { useEffect, useState } from "react";
import styles from "../../../../styles/sections/admin/header/Header.module.css";
import FullPageIcon from "../../../../assets/sections/admin/header/full_page.svg";
import CollapsePageIcon from "../../../../assets/sections/admin/header/collapse_page.svg";
import { useNavigate } from "react-router-dom";
import NotificationIcon from "../../../../assets/sections/admin/header/notification.svg";
import ProfileIcon from "../../../../assets/sections/admin/header/profile.svg";
import MyProfileIcon from "../../../../assets/sections/admin/header/my_profile.svg";
import changePassword from "../../../../assets/common/change_password.svg";
import DashboardExpand from "../../../../assets/sections/admin/sidebar/expand.svg";

import SignOutIcon from "../../../../assets/sections/admin/header/sign_out.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { updateTenantInfo } from "../../../../redux/reducers/UserSlicer";
import { USER_TYPE } from "../../../../utils/constants";
import TenantNavigationBar from "../../tenant/tenant_navigation_bar/TenantNavigationBar";
import NavigationBar from "../navigation_bar/NavigationBar";

const Header = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const [profileState, setProfileState] = useState(false);
  const tenantData = useSelector((state) => state.userInfo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function toggleFullScreen() {
    if (fullScreen) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.documentElement.requestFullscreen();
      setFullScreen(true);
    }
  }

  const getTenantDetails = async () => {
    if (localStorage.getItem("userType") === "TENANT") {
      const response = await getSingleData({
        API_NAME: "TENANT__PERSONAL__GET_DETAILS",
      });

      dispatch(
        updateTenantInfo({
          name: response.tenantName,
          tenantName: response.tenantName,
          email: response.email,
          mobile: response.mobile,
          startDate: response.startDate,
          endDate: response.endDate,
          unitName: response.unitName,
          unitAddress: response.unitAddress,
          referralDetails: response.referralDetails,
        })
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userType") === "TENANT") {
      getTenantDetails();
    }
  }, []);

  const signOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const [navigationState, setNavigationState] = useState(false);

  return (
    <>
      {navigationState && (
        <div
          className={styles.nav_background}
          onClick={() => {
            setNavigationState(false);
          }}
        >
          {/* {localStorage.getItem("userType") === USER_TYPE.TENANT ? (
            <TenantNavigationBar
              state={navigationState}
              setState={setNavigationState}
            />
          ) : (
            <NavigationBar
              state={navigationState}
              setState={setNavigationState}
            />
          )} */}
          {localStorage.getItem("userType") === USER_TYPE.TENANT ? (
            <TenantNavigationBar
              state={navigationState}
              setState={setNavigationState}
            />
          ) : localStorage.getItem("userType") === USER_TYPE.ADMIN ||
            localStorage.getItem("userType") === USER_TYPE.EMPLOYEE ? (
            <NavigationBar
              state={navigationState}
              setState={setNavigationState}
            />
          ) : (
            <></>
          )}
        </div>
      )}

      <div className={styles.admin_dashboard_header}>
        {/* <span>Hello {tenantData.tenantInfo.name || "Admin"}</span> */}
        <span className={styles.user_name}>
          <img
            src={DashboardExpand}
            alt=""
            className={styles.collapse_button}
            onClick={() => {
              setNavigationState(!navigationState);
            }}
          ></img>
          <span>
            Hello {tenantData.tenantInfo?.name?.split(" ")[0] || "Admin"}
          </span>
        </span>
        <div className={styles.admin_dashboard_header_actions}>
          <img
            src={!fullScreen ? FullPageIcon : CollapsePageIcon}
            alt="FullPageIcon"
            onClick={() => {
              toggleFullScreen();
            }}
            className={styles.full_page_icon}
          ></img>

          <img
            src={ProfileIcon}
            alt="ProfileIcon"
            onClick={() => {
              setProfileState(!profileState);
            }}
          ></img>
        </div>
        {profileState && (
          <div
            className={styles.profile}
            onMouseLeave={() => {
              setProfileState(false);
            }}
          >
            {localStorage.getItem("userType") === "TENANT" ? (
              <span
                className={styles.profile_content}
                onClick={() => {
                  navigate("/tenant/profile");
                }}
              >
                <img src={MyProfileIcon} alt=""></img> My Profile
              </span>
            ) : (
              <span
                className={styles.profile_content}
                onClick={() => {
                  navigate("/auth/changepassword");
                }}
              >
                <img src={changePassword} alt="Key" /> Password
              </span>
            )}
            <span
              className={styles.profile_content}
              onClick={() => {
                signOut();
              }}
            >
              <img src={SignOutIcon} alt=""></img> Sign Out
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
