import React, { useEffect, useState } from "react";
import styles from "./PaymentInvoiceStylePrint.module.css";
import Logo2 from "../../../../assets/common/LP-Recovered-vertical-white.png";
import { formatDate } from "../../../../utils/generalFunctions";
export const PaymentInvoice = ({ invoiceDetails }) => {
  const [totalPaymentAmount, setTotalPaymentAmount] = useState("$ 0");
  const [totalCreditBalance, setTotalCreditBalance] = useState("$ 0");
  const [totalDeposit, setTotalDeposit] = useState("$ 0");
  useEffect(() => {
    console.log("invoiceDetails", invoiceDetails);

    // setTotalCreditBalance(
    //   "$ " +
    //     invoiceDetails?.paymentData?.reduce((acc, curr) => {
    //       return acc + parseInt(curr.balance !== "" ? curr.balance : 0);
    //     }, 0)
    // );
    // setTotalPaymentAmount(
    //   "$ " +
    //     invoiceDetails?.paymentData?.reduce((acc, curr) => {
    //       return acc + parseInt(curr.charges !== "" ? curr.charges : 0);
    //     }, 0)
    // );

    console.log(
      "invoiceDetails?.paymentData?.length",
      invoiceDetails?.paymentData?.length
    );
    console.log("invoiceDetails?.paymentData", invoiceDetails?.paymentData);

    setTotalCreditBalance(
      "$ " +
        invoiceDetails?.paymentData[
          invoiceDetails?.paymentData?.length - 1
        ]?.balance?.toString() || 0
    );
    setTotalPaymentAmount(
      "$ " +
        invoiceDetails?.paymentData?.reduce((acc, curr) => {
          return acc + parseInt(curr.charges !== "" ? curr.charges : 0);
        }, 0)
    );
    setTotalDeposit(
      "$ " +
        invoiceDetails?.paymentData?.reduce((acc, curr) => {
          return acc + parseInt(curr?.deposit !== "" ? curr?.deposit : 0);
        }, 0)
    );

    // setTotalPaymentAmount("$ " + totalPaymentAmount);
    // setTotalCreditBalance("$ " + totalCreditBalance);
  }, [invoiceDetails]);

  // useEffect(() => {
  //   // print below div as invoice after page load 2 sec
  //   setTimeout(() => {
  //     Print();
  //   }, 2000);
  // });

  return (
    <div
      className={styles.tenants_invoice_warapper}
      id="tenants_invoice_warapper"
    >
      <div className={styles.tenants_invoice}>
        <div className={styles.header}>
          <div className={styles.rectangles}>
            <img src={Logo2} alt="Logo" />
            <div className={styles.rectangle + " " + styles.rectangle2}></div>
            <div className={styles.rectangle + " " + styles.rectangle3}>
              <div className={styles.heading_textwrapper}>
                <div className={styles.heading_title}>
                  LOYAL PARTNERS PTY LTD
                </div>
                <a
                  className={styles.heading_link}
                  href="http://www.loyalpartners.com.au/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  www.loyalpartners.com.au
                </a>
              </div>
            </div>
          </div>
          <div className={styles.mid_title_container}>
            <div className={styles.title1}>INVOICE</div>
            <div className={styles.title2}>ABN: 11605961215</div>
          </div>

          <div className={styles.invoice_details}>
            <div className={styles.left_details}>
              <div>Date: {formatDate(invoiceDetails?.invoiceDate)}</div>
              <div>Tenant Name: {invoiceDetails?.customerName}</div>
              <div>Address: {invoiceDetails?.customerAddress}</div>
              <div>Accommodation Type: {invoiceDetails?.accommodationType}</div>
            </div>
            <div className={styles.right_details}>
              {/* <div>ABN: 11605961215</div> */}
              <div>Invoice Number : {invoiceDetails?.invoiceNumber}</div>
              <div>Passport No : {invoiceDetails?.passportNumber}</div>
              {/* <div className={styles.text-wrapper-51">Payment Type: Cash, EFTPOS</div> */}
              <div>Arrival: {formatDate(invoiceDetails.arrivalDate)}</div>
              <div>Departure: {formatDate(invoiceDetails.departureDate)}</div>
            </div>
          </div>
        </div>

        <center>
          <div className={styles.invoice_seperator}></div>
        </center>

        <div className={styles.invoice_body}>
          <table className={styles.invoice_data_table}>
            {/* <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Payment Type</th>
                <th>Mode of Payment</th>
                <th>Charges (AUD)</th>
                <th>Balance</th>
              </tr>
            </thead> */}
            {/* <tbody> */}
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Mode of Payment</th>
              <th>Charges (AUD)</th>
              <th>Deposit (AUD)</th>
              <th>Balance (AUD)</th>
            </tr>
            {invoiceDetails?.paymentData?.map((rentDetail, index) => (
              <tr key={index}>
                <td>{formatDate(rentDetail.date)}</td>
                <td>{rentDetail.description}</td>
                <td>{rentDetail.modeOfPayment}</td>
                <td>{rentDetail.charges}</td>
                <td>{rentDetail.deposit}</td>
                <td>{rentDetail.balance}</td>
              </tr>
            ))}

            <tr className={styles.data_summary}>
              {/* Merge four cell */}
              <td colSpan="3">Summary of Payment</td>
              <td>{totalPaymentAmount}</td>
              <td>{totalDeposit}</td>
              <td>{totalCreditBalance}</td>
            </tr>
            <tr className={styles.data_summary}>
              {/* Merge four cell */}
              <td colSpan="5">Total amount paid (Including GST)</td>
              <td>{totalDeposit}</td>
            </tr>
            {/* </tbody> */}
          </table>
        </div>

        <div className={styles.invoice_footer} id="tenants_invoice_footer">
          <center>
            <div className={styles.invoice_seperator}></div>
          </center>

          <p className={styles.footer_line1}>
            Please contact us via Email: contactus@loyalpartners.com.au | Phone:
            1800 325 107
          </p>
          <p className={styles.footer_line2}>
            Address: 43/37 Campbell Street, Parramatta, NSW 2150
          </p>
          <p>{window.location.href}</p>
        </div>
      </div>
    </div>
  );
};
