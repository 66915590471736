import styles from "../../../../styles/sections/admin/tenants/AddTenant.module.css";
import { useEffect, useRef, useState } from "react";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import NavBar from "../../../common/nav_bar/NavBar";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { useDispatch } from "react-redux";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { tenantSchema } from "../../../../utils/validators";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { tableKey } from "../../../../utils/constants";
import { getTableData } from "../../../../generalApiCalls/getTableData";

const AddTenant = () => {
  // const navSections = ["Admin Form", "Tenant Form"];
  const [navLinkActive, setNavLinkActive] = useState("Admin Form");
  const tenantId = useParams().tenantId || null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminTenantForm = [
    {
      field_name: "Tenant Name",
      field_id: "tenantName",
      placeholder: "Enter Tenant Name",
      type: "text",
    },
    {
      field_name: "Email",
      field_id: "email",
      placeholder: "Enter Email",
      type: "email",
    },
    {
      field_name: "Mobile Number",
      field_id: "mobile",
      placeholder: "Enter Mobile Number",
      type: "text",
    },
    {
      field_name: "Whatsapp Number",
      field_id: "whatsapp",
      placeholder: "Enter whatsapp Number",
      type: "text",
    },
    {
      field_name: "Rent Amount (Weekly)",
      field_id: "weeklyRent",
      placeholder: "Enter Rent Amount",
      type: "number",
    },
    {
      field_name: "Deposit Amount",
      field_id: "bondAmount",
      placeholder: "Enter Deposit Amount",
      type: "number",
    },
    {
      field_name: "Increment Percentage",
      field_id: "incrementPercent",
      placeholder: "Enter Increment Percentage",
      type: "number",
    },
    {
      field_name: "Payment Mode",
      field_id: "paymentMode",
      type: "dropdown",
      options: [
        { id: "CREDIT CARD", name: "CREDIT CARD" },
        { id: "CASH", name: "CASH" },
        { id: "NET BANKING", name: "NET BANKING" },
      ],
    },

    {
      field_name: "Unit",
      field_id: "unitId",
      type: "dropdown",
      options: [
        // { id: "NoUnit", name: "NoUnit" },
      ],
    },
    {
      field_name: "Start Date",
      field_id: "startDate",
      type: "date",
    },
    {
      field_name: "End Date",
      field_id: "endDate",
      type: "date",
    },
    {
      field_name: "Tenancy Duration (Months)",
      field_id: "tentativeStayDuration",
      placeholder: "Enter Tenancy Duration",
      type: "number",
    },
    {
      field_name: "Is Active",
      field_id: "isActive",
      type: "dropdown",
      default: true,
      options: [
        { id: true, name: "Yes" },
        { id: false, name: "No" },
      ],
    },
  ];

  let disableFieldsonUpdate = [
    "isActive",
    "endDate",
    "tentativeStayDuration",
    "weeklyRent",
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return adminTenantForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
      disabled: tenantId
        ? disableFieldsonUpdate.includes(item.field_id)
        : false,
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newTenant = {};
    formFields.map((item) => {
      newTenant[item.field_id] = item.value || item?.default;
    });

    newTenant = cleanFormObject(newTenant);

    // Validate the form data
    const { error } = tenantSchema.validate(newTenant);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));

      let response = null;

      // upadte
      if (tenantId) {
        response = await postFormData({
          form_key: "ADMIN__TENANTS__UPDATE_TENANT_DETAILS",
          data: newTenant,
          urlId: tenantId,
          method: "put",
        });
      } else {
        // add
        response = await postFormData({
          form_key: "ADMIN__TENANTS__ADD_TENANT",
          data: newTenant,
        });
      }

      if (response.success) {
        if (tenantId) {
          navigate("/admin/tenants/" + tenantId);
        } else {
          navigate("/admin/tenants");
        }
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const handleGetMoreDetails = async (tenantId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__TENANTS__GET_TENANT_DETAILS",
      oneId: tenantId,
    });

    Object.keys(response).map((item) => {
      if (item === "startDate" || item === "endDate")
        response[item] = response[item].substring(0, 10);

      handleFieldChange(item, response[item], false, "");
    });

    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (tenantId) handleGetMoreDetails(tenantId);
  }, [tenantId]);

  const initialRender = useRef(true);
  const allUnits = async () => {
    if (initialRender.current) {
      dispatch(showLoader(true));
      initialRender.current = false;

      let resp = await getTableData({
        currentTableKey: "",
        page: 1,
        search: "",
        limit: 500,
        apiEndpointKey: "ADMIN__CLEANING__GET_ALL_UNITS",
      });

      let data = [];

      if (resp["data"] === undefined) {
        dispatch(showLoader(false));
        return;
      }

      resp["data"]["data"]?.map((item) => {
        data.push({ id: item._id, name: item.unitName });
      });

      setFormFields((prevFields) => {
        const updatedFormFields = [...prevFields];

        const index = updatedFormFields.findIndex(
          (item) => item.field_id === "unitId"
        );

        updatedFormFields[index] = {
          ...updatedFormFields[index],
          options: data,
        };
        return updatedFormFields;
      });

      dispatch(showLoader(false));
    }
  };

  useEffect(() => {
    allUnits();
  }, []);

  return (
    <div className={styles.add_tenant}>
      <span className={styles.section_header}>
        <img src={BackArrow} alt="" onClick={() => navigate(-1)}></img>
        Add New Tenant
      </span>
      <div className={styles.content_wrap}>
        {/* <NavBar
          state={() => {}}
          active={navLinkActive}
          navLinks={navSections}
        /> */}
        {navLinkActive === "Admin Form" && (
          <ProfileUpdateForm
            form_fields={formFields}
            handleFieldChange={handleFieldChange}
          />
        )}
        {/* {navLinkActive === "Tenant Form" && (
          <ProfileUpdateForm form_fields={tenantForm} />
        )} */}
        <div className={styles.submit_button_container}>
          <button className={styles.submit_button} onClick={formHandler}>
            {tenantId ? "Update" : "Add"} Tenant
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTenant;
