import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/units/Units.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";
import TotalUnits from "../../../../assets/sections/admin/dashboard/total_units.svg";
import VacantUnits from "../../../../assets/sections/admin/dashboard/vacant_units.svg";
import AvailableBeds from "../../../../assets/sections/admin/dashboard/available_beds.svg";
import TotalBeds from "../../../../assets/sections/admin/units/total_beds.svg";
import RentPaid from "../../../../assets/sections/admin/units/rent_paid.svg";
import TotalRevenue from "../../../../assets/sections/admin/units/total_revenue.svg";
import UtilityBills from "../../../../assets/sections/admin/units/utility_bills.svg";
import StatsBoard from "../../../common/stats_board/StatsBoard";
import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";
import IconButton from "../../../common/icon_button/IconButton";
import AddIcon from "../../../../assets/common/add_icon.svg";
import FilterIcon from "../../../../assets/common/filter.svg";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { useDispatch, useSelector } from "react-redux";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { STATS_KEYS, tableKey } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce, set } from "lodash";

const Units = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const statePageStats = useSelector((state) => state.pageStats);
  const scroll = useSelector((state) => state.pageScroll);

  const dispatch = useDispatch();

  const initialRender = useRef(true);

  const navigate = useNavigate();

  const [unitsTableData, setUnitsTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [filterData, setFilterData] = useState({});

  // Units Stats Board
  const units_icons = [
    TotalUnits,
    VacantUnits,
    AvailableBeds,
    TotalBeds,
    RentPaid,
    TotalRevenue,
    UtilityBills,
  ];
  const unit_names = [
    "Total Units",
    "Vacant Units",
    "Available Beds",
    "Total Beds",
    "Rent Paid",
    "Total Profit",
    "Planned Bills",
  ];

  const units_stats_mapping = [
    "totalUnits",
    "vacantUnits",
    "availableBeds",
    "totalBeds",
    "rentPaid",
    "totalRevenue",
    "plannedBills",
  ];

  // Units Stats Table
  const units_table_headers = [
    "Unit Name",
    "Address",
    "Vacant/Total Spaces",
    "Agency Names",
    "Rent Paid",
    "Rental Income",
    // "Profit",
    "Action",
  ];

  const units_tableFieldMapping = [
    "unit_name",
    "address",
    "vacant_total_spaces",
    "agency_names",
    "rent_per_space",
    "apartment_rent",
    // "profit",
    "action",
  ];

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData.unit_name = item.unitName;
      mappedData.address = item.unitAddress;
      mappedData.vacant_total_spaces =
        item.availablePosition + "/" + item.noOfPosition;
      mappedData.agency_names = item.agencyName;
      mappedData.rent_per_space = "$ " + item.weeklyRent;
      mappedData.apartment_rent = "$ " + item.weeklyRentFromTenant;
      // mappedData.profit =
      //   item.weeklyRentFromTenant - item.weeklyRent - item.plannedWeeklyExpense;
      mappedData.action = (
        <ActionButton
          buttonText={"More Details"}
          onClick={() => {
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            navigate("" + item._id);
          }}
        ></ActionButton>
      );

      mappedDataList.push(mappedData);
    });

    setUnitsTableData(mappedDataList);
  };

  useEffect(() => {
    console.log("I got triggered filterData", filterData);
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__UNITS__ALL_UNITS",
        scroll.pageNo,
        searchText,
        { filter: filterData }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__UNITS__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__UNITS__ALL_UNITS",
        scroll.pageNo,
        searchText,
        { filter: filterData }
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__UNITS__ALL_UNITS",
        scroll.pageNo,
        searchText,
        { filter: filterData }
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__UNITS__ALL_UNITS",
        scroll.pageNo,
        searchText,
        { filter: filterData }
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__UNITS__ALL_UNITS",
        scroll.pageNo,
        searchText,
        { filter: filterData }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__UNITS__STATS, "");
    }

    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      (filterData?.vacantUnits === true || filterData?.vacantUnits === false)
    ) {
      console.log("I got triggered 6");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__UNITS__ALL_UNITS",
        scroll.pageNo,
        searchText,
        { filter: filterData }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__UNITS__STATS, "");
    }
  }, [scroll.pageNo, searchText, filterData]);

  useEffect(() => {
    if (stateDataTableData.adminUnits?.length >= 0)
      processTableData(stateDataTableData.adminUnits);
  }, [stateDataTableData.adminUnits]);

  const FilterComponent = () => {
    return (
      <div className={styles.filter_section}>
        <img
          src={FilterIcon}
          alt="FilterIcon"
          onClick={() => {
            setFilterActive(!filterActive);
          }}
        />
        {filterActive && (
          <div
            className={styles.filter_popup_container}
            onMouseLeave={() => {
              setFilterActive(false);
            }}
          >
            <div className={styles.filters}>
              <div className={styles.filter}>
                <input
                  type="checkbox"
                  checked={filterData?.vacantUnits}
                  onChange={(e) => {
                    dispatch(
                      updatePageNumber({
                        pageNo: 1,
                      })
                    );
                    setFilterData({
                      ...filterData,
                      vacantUnits: e.target.checked,
                    });
                  }}
                />{" "}
                <span>Available spaces</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <StatsBoard
        boardName="Units"
        statIcons={units_icons}
        statNames={unit_names}
        statValuesMapping={units_stats_mapping}
        statValues={statePageStats?.adminUnitStats}
      ></StatsBoard>

      <StatsTable
        tableTitle={"All Units"}
        tableHeaders={units_table_headers}
        tableData={unitsTableData}
        tableFieldMapping={units_tableFieldMapping}
        tableActionsElement={
          <div className={styles.units_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <FilterComponent />
            <IconButton
              buttonText={"Add Unit"}
              buttonIcon={AddIcon}
              onClick={() => navigate("addunit")}
            ></IconButton>
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.adminUnitsTotalPages}
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>All Units</div>
              <div className={styles.units_stats_table_actions}>
                <FilterComponent />
                <IconButton
                  buttonText={"Add Unit"}
                  buttonIcon={AddIcon}
                  onClick={() => navigate("addunit")}
                ></IconButton>
              </div>
            </div>

            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
      ></StatsTable>
    </>
  );
};

export default Units;
