import styles from "../../../styles/common/back_drop/BackDropRulesAndReg.module.css";
import { useEffect, useState } from "react";
import { showLoader } from "../../../redux/reducers/loaderSlice";
import { getFileData } from "../../../generalApiCalls/getFileData";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { postFormData } from "../../../generalApiCalls/postFormData";

const BackDropRulesAndReg = (props) => {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const dispatch = useDispatch();

  let [consent, setConsent] = useState(false);

  const [stepName, setStepName] = useState("rules"); // rules, referral
  const [referralCode, setReferralCode] = useState("");
  const readFileFromServer = async () => {
    // dispatch(showLoader(true));
    try {
      const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
      let agreedOnDocument = JSON.parse(
        localStorage.getItem("agreedOnDocument")
      );

      let tenantId = decodedToken?._id;
      let assetName = agreedOnDocument?.assetId;
      let data = {
        tenantId: tenantId,
        assetName: assetName,
        mdm: true,
      };

      let responseBlob = await getFileData({
        API_NAME: "ASSET_DOWNLOAD",
        data: data,
      });

      responseBlob = responseBlob.fileBlob;
      setFileType(responseBlob.type.split("/")[0]);
      setFile(URL.createObjectURL(responseBlob));
    } catch {
      console.log("error in fetching file");
    }

    dispatch(showLoader(false));
  };

  const agreeOnTerms = async () => {
    try {
      if (!consent) {
        alert("Please agree on the terms and conditions to proceed");
        return;
      }
      dispatch(showLoader(true));
      let res = await postFormData({
        form_key: "TENANT_AGREE_TO_TERMS",
        data: {
          agreedOnRules: consent ? "AGREE" : "DISAGREE",
        },
        method: "put",
      });

      if (res.success) {
        localStorage.setItem("agreedOnRules", consent ? "AGREE" : "DISAGREE");
        window.location.reload();
      } else {
        alert(res.message);
      }

      dispatch(showLoader(false));
      // window.location.reload();
    } catch (err) {
      console.log("error in agreeing on terms");
    }
  };

  const addReferralDetails = async (finalReferralCode) => {
    try {
      dispatch(showLoader(true));
      let res = await postFormData({
        form_key: "TENANT_ADD_REFERRAL_DETAILS",
        data: {
          referralCode: finalReferralCode,
        },
        method: "put",
      });

      if (res.success) {
        setStepName("rules");
      } else {
        alert(res.message);
      }
      dispatch(showLoader(false));
    } catch (err) {
      console.log("error in adding referral details");
    }
  };

  useEffect(() => {
    if (localStorage?.getItem("agreedOnRules") !== "AGREE") {
      readFileFromServer();
    }
  }, []);

  return stepName === "rules" &&
    localStorage?.getItem("agreedOnRules") !== "AGREE" &&
    file ? (
    <div className={styles.backdrop}>
      <div className={styles.modal}>
        <div className={styles.body}>
          {fileType === "image" ? (
            <img
              src={file}
              alt="hih"
              className={styles.fileviewer_backdrop_img}
            />
          ) : (
            <embed
              src={file}
              title="file"
              type="application/pdf"
              width="100%"
              height="100%"
            ></embed>
          )}
        </div>
        <div className={styles.backdrop_header}>
          <span className={styles.backdrop_img}>
            <input
              type="checkbox"
              id="backdrop"
              onClick={() => {
                setConsent(!consent);
              }}
            />
            <span>
              Yes, I agree to the terms and conditions mentioned above in the
              document.
            </span>
          </span>

          <div
            className={styles.submit_button}
            onClick={() => {
              // download file
              agreeOnTerms();
            }}
          >
            Submit
          </div>
        </div>
      </div>
    </div>
  ) : (
    stepName === "referral" && (
      <div className={styles.backdrop}>
        <div className={styles.referralBottom}>
          <div
            className={styles.modalCloser}
            onClick={() => {
              addReferralDetails("NOT_REFERRED_BY_ANYONE");
            }}
          >
            X
          </div>
          <div className={styles.content}>
            <div className={styles.supportingText}>
              {/* Share below referral code code with friends */}
              Do you have a referral code?
            </div>
            <div className={styles.box}>
              <input
                className={styles.uniqueCode}
                type="text"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                placeholder="XXXXXXXXX"
              />
              <div
                className={styles.copyButton}
                onClick={() => {
                  addReferralDetails(referralCode);
                }}
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default BackDropRulesAndReg;
