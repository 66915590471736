import { Route, Routes } from "react-router-dom";

import styles from "../../../../styles/sections/admin/analytics/Analytics.module.css";
import RevenueAnalytics from "./RevenueAnalytics";
import UnitAnalytics from "./UnitAnalytics";
import TenantAnalytics from "./TenantAnalytics";
import { useSelector } from "react-redux";

const AnalyticsRoutes = () => {
  const stateData = useSelector((state) => state.currentPage);

  const activeSubsectionTitle = stateData?.activeChildPage?.includes("revenue")
    ? "Revenue & Expenses"
    : stateData?.activeChildPage?.includes("unit")
    ? "Unit Analytics"
    : stateData?.activeChildPage?.includes("tenant")
    ? "Tenant Analytics"
    : "Revenue & Expenses";

  return (
    <div className={styles.admin_analytics_content}>
      <span className={styles.admin_analytics_content_title}>
        {activeSubsectionTitle}
      </span>
      <Routes>
        <Route exact path="/revenue" element={<RevenueAnalytics />} />
        <Route exact path="/unit" element={<UnitAnalytics />} />
        <Route exact path="/tenant" element={<TenantAnalytics />} />
      </Routes>
    </div>
  );
};

export default AnalyticsRoutes;
