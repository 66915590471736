import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/broadcasts/broadcasts.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import TotalTenants from "../../../../assets/sections/admin/tenants/total_tenants.svg";
import OnNotice from "../../../../assets/sections/admin/tenants/on_notice.svg";
import UpcomingTenants from "../../../../assets/sections/admin/tenants/upcoming_tenants.svg";
import TenantsDue from "../../../../assets/sections/admin/tenants/tenants_due.svg";
import TenantsPaid from "../../../../assets/sections/admin/tenants/tenants_paid.svg";
import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";
import IconButton from "../../../common/icon_button/IconButton";
import sendIcon from "../../../../assets/sections/admin/broadcasts/send.svg";
import scheduleIcon from "../../../../assets/sections/admin/broadcasts/schedule.svg";
import FilterIcon from "../../../../assets/common/filter.svg";

import HistoryIcon from "../../../../assets/common/history__white.svg";

import { useDispatch, useSelector } from "react-redux";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { STATS_KEYS } from "../../../../utils/constants";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { formatDate } from "../../../../utils/generalFunctions";
import { useNavigate } from "react-router-dom";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";

import { debounce } from "lodash";
import {
  updateSelectedAllTenants,
  updateSelectedTenants,
} from "../../../../redux/reducers/admin/BroadcastsSlice";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";

const Broadcasts = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const statePageStats = useSelector((state) => state.pageStats);
  const scroll = useSelector((state) => state.pageScroll);
  const broadCastInfo = useSelector((state) => state.broadCastInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialRender = useRef(true);

  const [tenantsTableData, setTenantsTableData] = useState([]);
  const [rawTableData, setRawTableData] = useState([]);

  const [searchText, setSearchText] = useState("");
  // const [pageNumber, setPageNumber] = useState();
  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [filterData, setFilterData] = useState({});

  const FILTERS = ["Due", "Paid"];

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };
  // Units Stats Board
  const tenants_icons = [
    TotalTenants,
    OnNotice,
    UpcomingTenants,
    TenantsDue,
    TenantsPaid,
  ];

  // Units Stats Table
  const tenants_statsTable_headers = [
    "checkbox",
    "Tenant Name",
    "Unit",
    // "Contact",
    "WhatsApp",
    "Joining date",
    "Rent",
    "Rent status",
  ];

  const tenants_tableFieldMapping = [
    "checkbox",
    "tenant_name",
    "unit",
    // "contact",
    "whatsapp",
    "joining_date",
    "rent",
    "rent_status",
  ];
  const updateCheckboxForTenant = (tenantId) => {
    if (document.querySelector(".broadcast_checkbox_" + tenantId).checked) {
      document.querySelector(".broadcast_checkbox_" + tenantId).checked = true;
    } else {
      document.querySelector(".broadcast_checkbox_" + tenantId).checked = false;
    }
  };

  useEffect(() => {
    let allTenantIds = [];
    rawTableData?.map((item, index) => {
      allTenantIds.push(item._id);
    });

    if (selectAll) {
      dispatch(
        updateSelectedAllTenants({
          selectedTenants: allTenantIds,
        })
      );

      allTenantIds?.map((item, index) => {
        document.querySelector(".broadcast_checkbox_" + item).checked = true;
      });
    } else {
      dispatch(
        updateSelectedAllTenants({
          selectedTenants: [],
        })
      );
      allTenantIds?.map((item, index) => {
        document.querySelector(".broadcast_checkbox_" + item).checked = false;
      });
    }
  }, [selectAll]);

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData.checkbox = (
        <input
          type="checkbox"
          className={
            styles.table_checkbox + " " + "broadcast_checkbox_" + item._id
          }
          // checked={broadCastInfo.selectedTenants.includes(item._id)}
          key={item._id}
          onChange={() => {
            dispatch(
              updateSelectedTenants({
                selectedTenant: item._id,
              })
            );
            updateCheckboxForTenant(item._id);
          }}
        />
      );
      mappedData.tenant_name = item.tenantName;
      mappedData.unit = item.unitName;
      // mappedData.contact = item.mobile;
      mappedData.whatsapp = item.whatsapp;
      mappedData.joining_date = formatDate(item.startDate);
      mappedData.rent = "$ " + item.weeklyRent;
      // TODO: Change rent_status to Paid/Pending
      mappedData.rent_status = (
        <p
          className={
            item.rentStatus === "Paid"
              ? styles.rentStatusPaid
              : styles.rentStatusPending
          }
        >
          {item.rentStatus}
        </p>
      );

      mappedDataList.push(mappedData);
    });

    setRawTableData(data);
    setTenantsTableData(mappedDataList);
  };

  useEffect(() => {
    console.log("I got triggered filterData", filterData);
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: filterData?.rentStatus }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__TENANTS__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: filterData?.rentStatus }
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: filterData?.rentStatus }
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: filterData?.rentStatus }
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: filterData?.rentStatus }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__TENANTS__STATS, "");
    }

    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      !isSearchUsed &&
      FILTERS.includes(filterData?.rentStatus)
    ) {
      console.log("I got triggered 6");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: filterData?.rentStatus }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__TENANTS__STATS, "");
    }
  }, [scroll.pageNo, searchText, filterData]);

  useEffect(() => {
    if (stateDataTableData.adminTenants?.length >= 0)
      processTableData(stateDataTableData.adminTenants);
  }, [stateDataTableData.adminTenants]);

  const FilterComponent = () => {
    return (
      <div className={styles.filter_section}>
        <img
          src={FilterIcon}
          alt="FilterIcon"
          onClick={() => {
            setFilterActive(!filterActive);
          }}
        />
        {filterActive && (
          <div
            className={styles.filter_popup_container}
            onMouseLeave={() => {
              setFilterActive(false);
            }}
          >
            <div className={styles.filters}>
              <p>Rent Status : </p>
              {FILTERS.map((filter, index) => (
                <div className={styles.filter} key={index}>
                  <input
                    type="radio"
                    checked={filterData?.rentStatus === filter} // Check if the filter matches the rentStatus
                    onChange={(e) => {
                      setFilterActive(false);
                      dispatch(
                        updatePageNumber({
                          pageNo: 1,
                        })
                      );
                      console.log(
                        "e.target.checked",
                        e.target.checked,
                        e.target.value
                      );
                      setFilterData({
                        ...filterData,
                        rentStatus: filter, // Set the rentStatus to the value of the selected filter
                      });
                    }}
                    value={filter} // Set the value of the input to the filter value
                  />{" "}
                  <span>{filter}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const [openFormModal, setOpenFormModal] = useState(0);

  // const [message, setMessage] = useState("");
  // const [sendDateTime, setSendDateTime] = useState(new Date());
  const [activeMsgId, setActiveMsgId] = useState(1);

  const submitMessage = async (messageType) => {
    if (messageType === "schedule") {
      return -1;
    }

    let messageValue = document.getElementById("broadCastMessage").value;

    if (messageValue === "") {
      alert("Please enter message");
      return -1;
    }
    dispatch(showLoader(true));
    let broadCastScheduleDateTime = null;

    try {
      broadCastScheduleDateTime = document.getElementById(
        "broadCastScheduleDateTime"
      ).value;
    } catch (err) {
      broadCastScheduleDateTime = new Date();
    }

    let msgData = {
      message: messageValue,
      sendDateTime: broadCastScheduleDateTime,
      messageType: messageType,
      tenantIds: broadCastInfo.selectedTenants,
    };

    let response = null;
    if (activeMsgId === 1) {
      response = await postFormData({
        form_key: "ADMIN__MESSAGES__ADD_MESSAGE",
        data: msgData,
      });
      if (response.success === true) {
        // window.location.reload();
        alert(response.message);
        setOpenFormModal(0);
        setSelectAll(false);
      }
    } else {
      response = await postFormData({
        form_key: "ADMIN__NOTES__UPDATE_NOTE",
        data: msgData,
        urlId: activeMsgId,
        method: "put",
      });
      if (response.success === true) {
        //   // window.location.reload();
        setOpenFormModal(0);
        setSelectAll(false);
      }
    }
    console.log("Response", response);
    if (response) {
      // setActiveNoteId(response.id);
    }
    dispatch(showLoader(false));
  };

  // 0 - Close, 1 - Send Now , 2 - Schedule
  const FormComponent = () => {
    return (
      <div className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {openFormModal === 1 ? "Message Now" : "Schedule a message"}
          </div>
          <button
            onClick={() => {
              setOpenFormModal(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>

        <div className={styles.form__components}>
          <div className={styles.template_content}>
            <p className={styles.template_content_header}>Dear tenantName,</p>
            <textarea
              placeholder="Type your message here..."
              // defaultValue={"Type your message here..."}
              id="broadCastMessage"
              // onChange={(e) => {
              //   e.preventDefault();
              //   setMessage(e.target.value);
              // }}
            ></textarea>
            <p className={styles.template_content_footer}>
              Regards,
              <br />
              Loyal Partners Team
            </p>
          </div>
          {openFormModal === 2 && (
            <div className={styles.form_row}>
              <div>
                <p>DateTime</p>
              </div>
              <div>
                <input
                  type="datetime-local"
                  id="broadCastScheduleDateTime"
                  defaultValue={new Date()}
                  // onChange={(e) => {
                  //   setSendDateTime(e.target.value);
                  // }}
                />
              </div>
              {/* <div>
                <p>Time</p>
                <input type="time" />
              </div> */}
            </div>
          )}
        </div>
        <div className={styles.footer}>
          {openFormModal === 2 ? (
            <ActionButton
              buttonText={"Schedule message"}
              icon={scheduleIcon}
              onClick={() => {
                submitMessage("schedule");
              }}
              // onClick={formHandler}
            />
          ) : (
            <ActionButton
              buttonText={"Send message"}
              icon={scheduleIcon}
              onClick={() => {
                submitMessage("sendNow");
              }}
              // onClick={formHandler}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {broadCastInfo.selectedTenants.length > 0 && (
        <div className={styles.sendMsg_container_wrapper}>
          <div className={styles.sendMsg_container_left}>
            <p>{broadCastInfo.selectedTenants.length} Tenants selected</p>
          </div>
          <div className={styles.sendMsg_container_right}>
            {/* <button
              className={styles.schedule_button}
              onClick={() => {
                setOpenFormModal(2);
              }}
            >
              Schedule Message <img src={scheduleIcon} alt="scheduleIcon" />
            </button> */}
            <button
              className={styles.send_now_button}
              onClick={() => {
                setOpenFormModal(1);
              }}
            >
              Message Now <img src={sendIcon} alt="sendIcon" />
            </button>
          </div>
        </div>
      )}
      <StatsTable
        tableTitle={"Message Automation"}
        tableHeaders={tenants_statsTable_headers}
        tableData={tenantsTableData}
        tableFieldMapping={tenants_tableFieldMapping}
        tableActionsElement={
          <div className={styles.tenants_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <FilterComponent />
            <IconButton
              buttonText={"Message History"}
              buttonIcon={HistoryIcon}
              onClick={() => navigate("history")}
            ></IconButton>
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>
                Message Automation
              </div>
              <div className={styles.tenants_stats_table_actions}>
                <FilterComponent />
                <IconButton
                  buttonText={"Message History"}
                  buttonIcon={HistoryIcon}
                  onClick={() => navigate("history")}
                ></IconButton>
              </div>
            </div>

            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        totalPages={stateDataTotalPages.adminTenantsTotalPages}
        selectAll={selectAll}
        handleSelectAll={setSelectAll}
      ></StatsTable>

      {openFormModal > 0 && (
        <div className={styles.form_wrapper}>
          <FormComponent />
        </div>
      )}
    </>
  );
};

export default Broadcasts;
