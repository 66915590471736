import React, { useEffect, useState } from "react";
import styles from "./PaymentInvoiceStyle.module.css";
import Logo2 from "../../../../assets/common/LP-Recovered-vertical-white.png";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import editLogo from "../../../../assets/common/edit.svg";
import downloadLogo from "../../../../assets/common/download.svg";
import {
  formatDate,
  printCurrentPage,
} from "../../../../utils/generalFunctions";
import ActionButton from "../../../common/action_button/ActionButton";
import { useNavigate, useParams } from "react-router-dom";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { PaymentInvoice } from "./PaymentInvoice";
import { set } from "lodash";
import { createPortal } from "react-dom";
export const CreateInvoiceForm = ({ rentDetails, tenantDetailsData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invoiceId = useParams().invoiceId || null;

  const [invoiceDetails, setInvoiceDetails] = useState();

  const [disableEditMode, setDisableEditMode] = useState(false);

  const [totalPaymentAmount, setTotalPaymentAmount] = useState("$ 0");
  const [totalCreditBalance, setTotalCreditBalance] = useState("$ 0");
  const [totalDeposit, setTotalDeposit] = useState("$ 0");

  const [rowGeneration, setRowGeneration] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [description, setDescription] = useState();
  const [modeOfPayment, setModeOfPayment] = useState();
  const [charges, setCharges] = useState(0);
  const [balance, setBalance] = useState(0);

  const [paymnetDataForm, setPaymnetDataForm] = useState([
    // {
    //   date: "",
    //   description: "",
    //   modeOfPayment: "",
    //   charges: "",
    //   balance: "",
    // },
  ]);

  const [invoiceDate, setInvoiceDate] = useState(formatDate(new Date()));
  const [tenantName, setTenantName] = useState("");
  const [address, setAddress] = useState("");
  const [accommodationType, setAccommodationType] = useState(
    "Room sharing (Rental amount included all utility bills)"
  );

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [arrivalDate, setArrivalDate] = useState();
  const [departureDate, setDepartureDate] = useState();

  const saveInvoice = async () => {
    console.log("saveInvoice");
    const invoiceData = {
      invoiceDate,
      customerName: tenantName,
      customerAddress: address,
      accommodationType: accommodationType,
      invoiceNumber,
      passportNumber,
      arrivalDate,
      departureDate,
      paymentData: [],
    };
    paymnetDataForm.forEach((payment) => {
      invoiceData.paymentData.push({
        date: payment.date,
        description: payment.description,
        modeOfPayment: payment.modeOfPayment,
        charges: payment.charges,
        deposit: payment?.deposit,
        balance: payment.balance,
      });
    });

    let response = null;

    dispatch(showLoader(true));

    if (invoiceId) {
      // update invoice
      response = await postFormData({
        form_key: "ADMIN__INVOICES__UPDATE_INVOICE_DETAILS",
        data: invoiceData,
        urlId: invoiceId,
        method: "put",
      });
    } else {
      // create invoice
      response = await postFormData({
        form_key: "ADMIN__INVOICES__ADD_INVOICE",
        data: invoiceData,
      });

      console.log("response", response);
    }

    if (response.success) {
      navigate("/admin/invoices");
    } else {
      alert(response.message);
    }

    dispatch(showLoader(false));
  };

  const getInvoiceDetails = async () => {
    // get invoice details
    const response = await getSingleData({
      API_NAME: "ADMIN__INVOICES__GET_INVOICE_DETAILS",
      oneId: invoiceId,
    });

    setInvoiceDetails(response);

    const invoiceData = response;

    console.log("invoiceData", invoiceData);

    setInvoiceDate(invoiceData.invoiceDate);
    setTenantName(invoiceData.customerName);
    setAddress(invoiceData.customerAddress);
    setAccommodationType(invoiceData.accommodationType);
    setInvoiceNumber(invoiceData.invoiceNumber);
    setPassportNumber(invoiceData.passportNumber);
    setArrivalDate(invoiceData.arrivalDate);
    setDepartureDate(invoiceData.departureDate);

    const paymentData = invoiceData.paymentData;
    const paymentDataForm = [];
    paymentData.forEach((payment) => {
      paymentDataForm.push({
        date: payment.date,
        description: payment.description,
        modeOfPayment: payment.modeOfPayment,
        charges: payment.charges,
        deposit: payment?.deposit || 0,
        balance: payment.balance,
      });
    });
    setPaymnetDataForm(paymentDataForm);
  };

  useEffect(() => {
    if (invoiceId) {
      setDisableEditMode(true);
      getInvoiceDetails();
      setRowGeneration(false);
    }
  }, [invoiceId]);
  const handlePaymentChange = (index, field, value) => {
    setPaymnetDataForm((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });
    fixFormValues();
  };

  const fixFormValues = () => {
    setPaymnetDataForm((prevData) => {
      let previousCharges = 0;
      let previousDeposit = 0;
      let previousBalance = 0;

      const newPaymentData = prevData.map((payment) => {
        // Update charges
        previousCharges = parseInt(payment.charges) + previousCharges;

        // Update deposit
        previousDeposit = parseInt(payment.deposit) + previousDeposit;

        // Update balance
        const newBalance =
          -1 * parseInt(payment.charges) +
          previousBalance +
          parseInt(payment.deposit);

        previousBalance = newBalance;

        return { ...payment, balance: newBalance.toString() };
      });

      return newPaymentData;
    });
  };

  const FixValues = () => {
    setTotalCreditBalance(
      "$ " + paymnetDataForm[paymnetDataForm.length - 1]?.balance?.toString() ||
        0
    );
    setTotalPaymentAmount(
      "$ " +
        paymnetDataForm.reduce((acc, curr) => {
          return acc + parseInt(curr.charges !== "" ? curr.charges : 0);
        }, 0)
    );
    setTotalDeposit(
      "$ " +
        paymnetDataForm.reduce((acc, curr) => {
          return acc + parseInt(curr?.deposit !== "" ? curr?.deposit : 0);
        }, 0)
    );
  };

  useEffect(() => {
    FixValues();
    // FixValues();
  }, [paymnetDataForm]);

  const [printStart, setPrintStart] = useState(false);

  const addSeriesRecord = () => {
    if (!rowGeneration) return;
    // Validate if the startDate and endDate are provided
    if (!startDate || !endDate) {
      // Handle validation error (e.g., show an alert)
      alert("Please provide both start date and end date.");
      return;
    }

    // Parse the start and end dates to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Validate if the end date is after the start date
    if (end < start) {
      // Handle validation error (e.g., show an alert)
      alert("End date must be equal to or after start date.");
      return;
    }

    function formatDateStandard(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    // Loop through each date and add a record for each
    const newRecords = paymnetDataForm.slice(); // Create a copy of the existing records
    for (
      let currentDate = new Date(start);
      currentDate <= end;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const newRecord = {
        date: formatDateStandard(currentDate),
        description: description,
        modeOfPayment: modeOfPayment,
        charges: charges,
        deposit: 0,
        balance: balance,
      };

      newRecords.push(newRecord);
    }

    // Update the state with the new records
    setPaymnetDataForm(newRecords);

    fixFormValues();

    setRowGeneration(false);
  };
  return (
    <div
      className={
        styles.tenants_invoice_warapper +
        " " +
        (disableEditMode === true ? styles.tenants_invoice_warapper_fixed : " ")
      }
      id="tenants_invoice_warapper"
    >
      <div className={styles.page_action}>
        <img
          src={BackArrow}
          alt="BackArrow"
          onClick={() => {
            navigate(-1);
          }}
        />
        <div>{!invoiceId ? "New Invoice" : "Invoice Details"}</div>
        <ActionButton
          buttonText={invoiceId ? "Update Invoice" : "Save Invoice"}
          onClick={() => {
            saveInvoice();
          }}
        ></ActionButton>
      </div>

      <div className={styles.tenants_invoice}>
        <div className={styles.header}>
          <div className={styles.invoice_details}>
            <div className={styles.left_details}>
              <div>
                <span> Date:</span>
                <input
                  type="date"
                  onChange={(e) => {
                    setInvoiceDate(e.target.value);
                  }}
                  value={invoiceDate?.substring(0, 10)}
                  disabled={disableEditMode}
                />
              </div>
              <div>
                <span>Tenant Name:</span>
                <input
                  type="text"
                  placeholder="Enter customer Name"
                  onChange={(e) => {
                    setTenantName(e.target.value);
                  }}
                  value={tenantName}
                  disabled={disableEditMode}
                />
              </div>
              <div>
                <span> Address:</span>
                <input
                  type="text"
                  placeholder="Enter Address"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  value={address}
                  disabled={disableEditMode}
                />
              </div>
              <div>
                <span> Accommodation Type : </span>
                <input
                  type="text"
                  value={accommodationType}
                  onChange={(e) => {
                    setAccommodationType(e.target.value);
                  }}
                  disabled={disableEditMode}
                />
              </div>
            </div>
            <div className={styles.right_details}>
              <div>
                <span> Invoice Number:</span>
                <input
                  type="text"
                  placeholder="Enter Invoice Number"
                  onChange={(e) => {
                    setInvoiceNumber(e.target.value);
                  }}
                  value={invoiceNumber}
                  disabled={disableEditMode}
                />
              </div>
              <div>
                <span>Passport No :</span>
                <input
                  type="text"
                  placeholder="Enter Passport Number"
                  onChange={(e) => {
                    setPassportNumber(e.target.value);
                  }}
                  value={passportNumber}
                  disabled={disableEditMode}
                />
              </div>
              <div>
                <span> Arrival:</span>
                <input
                  type="date"
                  onChange={(e) => {
                    setArrivalDate(e.target.value);
                  }}
                  value={arrivalDate?.substring(0, 10)}
                  disabled={disableEditMode}
                />
              </div>
              <div>
                <span> Departure:</span>
                <input
                  type="date"
                  onChange={(e) => {
                    setDepartureDate(e.target.value);
                  }}
                  value={departureDate?.substring(0, 10)}
                  disabled={disableEditMode}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.row_action}>
          {invoiceId && (
            <>
              <div
                onClick={() => {
                  printCurrentPage(
                    setPrintStart,
                    "Invoice " + invoiceDetails?.invoiceNumber
                  );
                }}
              >
                <span>Download Invoice</span>
                <img src={downloadLogo} alt="downloadLogo" />
              </div>

              <div
                onClick={() => {
                  setDisableEditMode(!disableEditMode);
                }}
              >
                <span>Edit</span>
                <img src={editLogo} alt="editLogo" />
              </div>
            </>
          )}

          {(!invoiceId || (invoiceId && !disableEditMode)) && (
            <ActionButton
              buttonText={"Add New Row"}
              onClick={() => {
                setRowGeneration(true); // Add this line to prevent unnecessary re-renders

                if (disableEditMode) return;
                setPaymnetDataForm([
                  ...paymnetDataForm,
                  {
                    date: "",
                    description: "",
                    modeOfPayment: "",
                    charges: 0,
                    deposit: 0,
                    balance: 0,
                  },
                ]);
              }}
            ></ActionButton>
          )}
        </div>

        {rowGeneration && (
          <div>
            <table className={styles.invoice_data_table}>
              <tr>
                <th>Start Date</th>
                <th>Ende Date</th>
                <th>Description</th>
                <th>Mode of Payment</th>
                <th>Charges (AUD)</th>
                <th>Action</th>
              </tr>
              <tr>
                <td>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    disabled={disableEditMode}
                  />
                </td>

                <td>
                  <input
                    type="text"
                    value={modeOfPayment}
                    onChange={(e) => {
                      setModeOfPayment(e.target.value);
                    }}
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={charges}
                    onChange={(e) => {
                      setCharges(e.target.value);
                    }}
                    disabled={disableEditMode}
                  />
                </td>
                {/* <td>
                  <input
                    type="number"
                    value={0}
                    onChange={(e) => {
                      setCharges(e.target.value);
                    }}
                    disabled={disableEditMode}
                  />
                </td> */}

                <td>
                  <ActionButton
                    buttonText={"Add Records"}
                    onClick={() => {
                      addSeriesRecord();
                      // fixFormValues();
                    }}
                  ></ActionButton>
                </td>
              </tr>
            </table>
          </div>
        )}

        <div className={styles.invoice_body}>
          <table className={styles.invoice_data_table}>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Mode of Payment</th>
              <th>Charges (AUD)</th>
              <th>Deposit (AUD)</th>
              <th>Balance (AUD)</th>
              {!disableEditMode && <th>Action</th>}
            </tr>

            {paymnetDataForm?.map((rentDetail, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="date"
                    value={rentDetail.date}
                    onChange={(e) =>
                      handlePaymentChange(index, "date", e.target.value)
                    }
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rentDetail.description}
                    onChange={(e) =>
                      handlePaymentChange(index, "description", e.target.value)
                    }
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rentDetail.modeOfPayment}
                    onChange={(e) =>
                      handlePaymentChange(
                        index,
                        "modeOfPayment",
                        e.target.value
                      )
                    }
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rentDetail.charges}
                    onChange={(e) => {
                      handlePaymentChange(index, "charges", e.target.value);
                    }}
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rentDetail.deposit}
                    onChange={(e) => {
                      handlePaymentChange(index, "deposit", e.target.value);
                      // fixFormValues();
                    }}
                    disabled={disableEditMode}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rentDetail.balance}
                    onChange={(e) =>
                      handlePaymentChange(index, "balance", e.target.value)
                    }
                    disabled={disableEditMode}
                  />
                </td>
                {!disableEditMode && (
                  <td className={styles.child_centre}>
                    <ActionButton
                      buttonText={"Delete"}
                      onClick={() => {
                        if (disableEditMode) return;
                        setPaymnetDataForm(
                          paymnetDataForm.filter((_, i) => i !== index)
                        );
                        fixFormValues();
                      }}
                    ></ActionButton>
                  </td>
                )}

                {/* Repeat for other input fields */}
              </tr>
            ))}

            <tr className={styles.data_summary}>
              {/* Merge four cell */}
              <td colSpan="3">Summary of Payment</td>
              <td>{totalPaymentAmount}</td>
              <td>{totalDeposit}</td>
              <td>{totalCreditBalance}</td>
            </tr>
            <tr className={styles.data_summary}>
              {/* Merge four cell */}
              <td colSpan="5">Total amount paid (Including GST)</td>
              <td>{totalPaymentAmount}</td>
            </tr>
            {/* </tbody> */}
          </table>
        </div>
      </div>
      {/* {printStart && <PaymentInvoice invoiceDetails={invoiceDetails} />} */}
      {printStart &&
        createPortal(
          <PaymentInvoice invoiceDetails={invoiceDetails} />,
          document.body
        )}
    </div>
  );
};
