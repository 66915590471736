import styles from "../../../styles/common/image_verification/ImageVerification.module.css";

const ImageVerification = (props) => {
  return (
    <div className={styles.image_verification}>
      <p className={styles.verification_header}>{props.header}</p>
      <div className={styles.img_wrap}>
        <p>Img123456.jpeg</p>
        <div className={styles.button_wrap}>
          <span onClick={()=>props.state(true)}>Preview</span>
          <span>Download</span>
        </div>
      </div>
    </div>
  );
};

export default ImageVerification;
