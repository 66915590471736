import { Route, Routes, useNavigate } from "react-router-dom";
import styles from "../../../../styles/sections/admin/cleaning/Cleaning.module.css";
import Cleaning from "./Cleaning";
import CleaningDetails from "./CleaningDetails";

const CleaningRoutes = () => {
  return (
    <div className={styles.admin_cleaning_content}>
      <Routes>
        <Route exact path="/" element={<Cleaning />} />
        <Route path="/:unitId" element={<CleaningDetails />} />
      </Routes>
    </div>
  );
};

export default CleaningRoutes;
