import { createSlice } from "@reduxjs/toolkit";
import { STATS_KEYS, tableKey } from "../../../utils/constants";

const initialState = {
  adminDashboardStats: {
    units: {
      totalUnits: 0,
      vacantUnits: 0,
      availableBeds: 0,
    },
    tenants: {
      totalTenants: 0,
      onNotice: 0,
      upcomingTenants: 0,
    },
  },
  adminUnitStats: {
    totalUnits: 0,
    vacantUnits: 0,
    availableBeds: 0,
    totalBeds: 0,
    rentPaid: 0,
    totalRevenue: 0,
    plannedBills: 0,
  },
  adminTenantStats: {
    totalTenants: 0,
    onNotice: 0,
    upcomingTenants: 0,
    tenantsDue: 0,
    tenantsPaid: 0,
  },
  adminApprovalStats: {
    totalApprovals: 0,
    paymentApprovalsPending: 0,
    profileApprovalsPending: 0,
  },
  adminAmenityStats: {
    totalAmenities: 0,
    totalInventory: 0,
    totalInventoryValue: 0,
  },
  adminCleaningStats: {
    unitsDueThisWeek: 0,
    unitsCleanedThisWeek: 0,
    averageCleaningFee: 0,
  },
  adminCarParkingStats: {
    totalSlotsAvailable: 0,
    totalSlotsAllocated: 0,
    allocatedSlotsLp: 0,
    availableSlotsLp: 0,
    allocatedSlotsParkhound: 0,
    availableSlotsParkhound: 0,
    occupancyPercentage: 0,
    parkingRevenue: 0,
  },
  adminInvoiceStats: {
    totalDocuments: 0,
    totalCharges: 0,
  },
  adminUnitStatsInternal: {
    availableBeds: 0,
    totalBeds: 0,
    rentPaid: 0,
    totalRevenue: 0,
    plannedBills: 0,
  },
  adminTenantStatsInternal: {
    rentStatus: "NA",
    latestPaymentDate: "NA",
    nextDueDate: "NA",
    stayDuration: "NA",
    endDate: "NA",
  },
  adminEmployeeStats: {
    activeEmployees: 0,
    totalEmployeeSalary: 0,
  },
  adminIssueStats: {
    inProgressIssues: 0,
    onHoldIssues: 0,
    openIssues: 0,
    resolvedIssues: 0,
  },
};

const pageStatsSlice = createSlice({
  name: "pageStats",
  initialState,
  reducers: {
    updatePageStats: (state, action) => {
      const { statsKey, statsData } = action.payload;

      switch (statsKey) {
        case STATS_KEYS.ADMIN_STATS_DASHBOARD:
          state.adminDashboardStats = statsData;
          break;
        case STATS_KEYS.ADMIN__UNITS__STATS:
          state.adminUnitStats = statsData;
          break;
        case STATS_KEYS.ADMIN__TENANTS__STATS:
          state.adminTenantStats = statsData;
          break;
        case STATS_KEYS.ADMIN__APPROVALS__STATS:
          state.adminApprovalStats = statsData;
          break;
        case STATS_KEYS.ADMIN__AMENITIES__STATS:
          state.adminAmenityStats = statsData;
          break;
        case STATS_KEYS.ADMIN__CLEANING__STATS:
          state.adminCleaningStats = statsData;
          break;
        case STATS_KEYS.ADMIN__INVOICE__STATS:
          state.adminInvoiceStats = statsData;
          break;
        case STATS_KEYS.ADMIN__EMPLOYEE__STATS:
          state.adminEmployeeStats = statsData;
          break;
        case STATS_KEYS.ADMIN__CAR_PARKING__STATS:
          state.adminCarParkingStats = statsData;
          break;
        case STATS_KEYS.ADMIN__UNITS__STATS_INTERNAL:
          state.adminUnitStatsInternal = statsData;
          break;
        case STATS_KEYS.ADMIN__TENANTS__STATS_INTERNAL:
          state.adminTenantStatsInternal = statsData;
          break;
        case STATS_KEYS.ADMIN__ISSUES__STATS:
          state.adminIssueStats = statsData;
          break;
        default:
          // Handle the default case if needed
          break;
      }
    },
  },
});

export const { updatePageStats } = pageStatsSlice.actions;
export default pageStatsSlice.reducer;
