import React, { useEffect, useRef } from "react";
import NavigationBar from "./navigation_bar/NavigationBar";
import styles from "../../../styles/sections/admin/Admin.module.css";
import { Route, Routes } from "react-router-dom";
import Header from "./header/Header";
import Dashboard from "../../sections/admin/dashboard/Dashboard";
import CarParking from "./car_parking/CarParking";
import Notes from "./notes/Notes";
import Approvals from "./approvals/Approvals";
import UnitsRoutes from "./units/UnitsRoutes";
import TenantsRoutes from "./tenants/TenantsRoutes";
import CleaningRoutes from "./cleaning/CleaningRoutes";
import Loader from "../../common/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import InvoiceRoutes from "./invoices/InvoiceRoutes";
import BroadcastRoutes from "./broadcasts/BroadcastRoutes";
import { updatePageNumber } from "../../../redux/reducers/PageScrollSlice";
import EmployeeRoutes from "./employees/EmployeeRoutes";
import Amenities from "./amenities/Amenities";
import AnalyticsRoutes from "./analytics/AnalyticsRoutes";
import AdminIssues from "./IssueManagement/AdminIssues";

function Admin() {
  const stateData = useSelector((state) => state.loader);

  useEffect(() => {
    // document.title = "Receipt";
  }, []);

  const adminFeatures = [
    {
      url: "/dashboard",
      component: <Dashboard />,
    },
    {
      url: "/units/*",
      component: <UnitsRoutes />,
    },
    {
      url: "/tenants/*",
      component: <TenantsRoutes />,
    },
    {
      url: "/approvals",
      component: <Approvals />,
    },
    {
      url: "/notes",
      component: <Notes />,
    },
    {
      url: "/cleaning/*",
      component: <CleaningRoutes />,
    },
    {
      url: "/car_parking",
      component: <CarParking />,
    },
    {
      url: "/invoices/*",
      component: <InvoiceRoutes />,
    },
    {
      url: "/broadcasts/*",
      component: <BroadcastRoutes />,
    },
    {
      url: "/employees/*",
      component: <EmployeeRoutes />,
    },
    {
      url: "/amenities/*",
      component: <Amenities />,
    },
    {
      url: "/analytics/*",
      component: <AnalyticsRoutes />,
    },
    {
      sectionName: "Issues",
      url: "/issues",
      component: <AdminIssues />,
    },
  ];

  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const scroll = useSelector((state) => state.pageScroll);
  const stateDataTotalPages = useSelector((state) => state.totalPages);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      if (
        // if less than 2px then scrolled to the bottom
        container.scrollTop + container.clientHeight + 2 >=
        container.scrollHeight
      ) {
        // User has scrolled to the bottom
        // console.log("I reached last, calling the next API");
        console.log(
          "I reached last",
          scroll.pageNo,
          stateDataTotalPages[stateDataTotalPages.activeTableKey]
        );

        // You can call your next API here
        if (
          scroll.pageNo <
          stateDataTotalPages[stateDataTotalPages.activeTableKey]
        ) {
          console.log("I am calling the next API");
          dispatch(
            updatePageNumber({
              pageNo: scroll.pageNo + 1,
            })
          );
        }
      }
    }
  };

  return (
    <div
      className={styles.admin_dashboard}
      onScroll={handleScroll}
      ref={containerRef}
    >
      {stateData.isLoading && <Loader />}

      <NavigationBar></NavigationBar>
      <div className={styles.header_mainContent}>
        <Header></Header>
        <Routes>
          {adminFeatures.map((feature, index) => {
            return (
              <Route
                key={index}
                path={feature.url}
                element={feature.component}
              />
            );
          })}
        </Routes>
      </div>
    </div>
  );
}

export default Admin;
