import styles from "./App.module.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Admin from "./components/sections/admin/Admin";
import Login from "./components/authentication/Login";
import Tenant from "./components/sections/tenant/Tenant";
import SetPassword from "./components/authentication/SetPassword";
import ChangePassword from "./components/authentication/ChangePassword";
import { PaymentInvoice } from "./components/sections/admin/tenants/PaymentInvoice/PaymentInvoice";
import Loader from "./components/common/loader/Loader";
import ForgotPassword from "./components/authentication/ForgotPassword";
// import { PaymentInvoice } from "./components/sections/admin/tenants/PaymentInvoice/PaymentInvoice";

const App = () => {
  const isSuperAdmin = () => {
    let token = localStorage.getItem("accessToken");
    let userType = localStorage.getItem("userType");

    if (token !== null && token !== undefined && token !== "") {
      return userType === "ADMIN" || userType === "EMPLOYEE";
    } else {
      return false;
    }
  };

  const isTenant = () => {
    let token = localStorage.getItem("accessToken");
    let userType = localStorage.getItem("userType");

    if (token !== null && token !== undefined && token !== "") {
      return userType === "TENANT";
    } else {
      return false;
    }
  };

  // Protected route component for admin section
  const ProtectedRoutes = ({ element }) => {
    if (isSuperAdmin()) {
      return element;
    } else if (isTenant()) {
      return element;
    } else {
      alert("You are not authorized to access this page...");
      return <Navigate to="/" />;
    }
  };

  const RediredtToLogin = () => {
    return <Navigate to="/login" />;
  };

  return (
    <div className={styles.App}>
      {<Loader />}

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RediredtToLogin />} />
          <Route path="/paymentInvoice" element={<PaymentInvoice />} />
          <Route
            path="/login/setPassword/:tempToken?"
            element={<SetPassword />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/admin/*"
            element={<ProtectedRoutes element={<Admin />} />}
          />
          <Route path="/auth/changePassword" element={<ChangePassword />} />
          <Route
            path="/tenant/*"
            element={<ProtectedRoutes element={<Tenant />} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
