import React, { useEffect, useState } from "react";
import styles from "../../../../styles/sections/admin/navigation_bar/NavigationBar.module.css";

import LoyalPartnersLogo from "../../../../assets/common/loyal_partners_logo.svg";
import LoyalPartnersLogoMobile from "../../../../assets/common/loyal_partners_logo_mobile.svg";
import DashboardCollapse from "../../../../assets/sections/admin/sidebar/collapse.svg";
import DashboardExpand from "../../../../assets/sections/admin/sidebar/expand.svg";
import DashboardIcon from "../../../../assets/sections/admin/sidebar/dashboard.svg";
import Unit from "../../../../assets/sections/admin/sidebar/unit.svg";
import Tenant from "../../../../assets/sections/admin/sidebar/tenant.svg";
import Approvals from "../../../../assets/sections/admin/sidebar/approvals.svg";
import Notes from "../../../../assets/sections/admin/sidebar/notes.svg";
import Cleaning from "../../../../assets/sections/admin/sidebar/cleaning.svg";
import CarParking from "../../../../assets/sections/admin/sidebar/car_parking.svg";
import InvoiceLogo from "../../../../assets/sections/admin/sidebar/invoice.svg";
import BroadcastLogo from "../../../../assets/sections/admin/sidebar/broadcast.svg";
import EmployeesLogo from "../../../../assets/sections/admin/sidebar/employees.svg";
import AnalyticsLogo from "../../../../assets/sections/admin/sidebar/analytics.svg";
import AmenitiesLogo from "../../../../assets/sections/admin/sidebar/amenities.svg";
import CollapseIcon from "../../../../assets/common/collapse_icon.svg";
import ExpandedIcon from "../../../../assets/common/expanded_icon.svg";
import AnalyticsRevenue from "../../../../assets/sections/admin/sidebar/analytics_revenue.svg";
import AnalyticsUnit from "../../../../assets/sections/admin/sidebar/unit.svg";
import AnalyticsTenant from "../../../../assets/sections/admin/sidebar/tenant.svg";
import IssuesIcon from "../../../../assets/sections/tenant/sidebar/issues_logo.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCurrentChildPage,
  updateCurrentPage,
} from "../../../../redux/reducers/CurrentPageSlice";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { USER_TYPE } from "../../../../utils/constants";

const NavigationBar = ({ state, setState }) => {
  const location = useLocation();
  const stateData = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let userType = localStorage.getItem("userType");
  let featureAccess = JSON.parse(localStorage.getItem("featureAccess"));

  const dashboardItems = [
    {
      sectionName: "Dashboard",
      url: "/admin/dashboard",
      sectionIcon: DashboardIcon,
      hasSubSections: false,
    },
    {
      sectionName: "Units",
      url: "/admin/units",
      sectionIcon: Unit,
      hasSubSections: false,
    },
    {
      sectionName: "Tenants",
      url: "/admin/tenants",
      sectionIcon: Tenant,
      hasSubSections: false,
    },
    {
      sectionName: "Approvals",
      url: "/admin/approvals",
      sectionIcon: Approvals,
      hasSubSections: false,
    },
    {
      sectionName: "Notes",
      url: "/admin/notes",
      sectionIcon: Notes,
      hasSubSections: false,
    },
    {
      sectionName: "Cleaning",
      url: "/admin/cleaning",
      sectionIcon: Cleaning,
      hasSubSections: false,
    },
    {
      sectionName: "Parking",
      url: "/admin/car_parking",
      sectionIcon: CarParking,
      hasSubSections: false,
    },
    {
      sectionName: "Invoices",
      url: "/admin/invoices",
      sectionIcon: InvoiceLogo,
      hasSubSections: false,
    },
    {
      sectionName: "Broadcast",
      url: "/admin/broadcasts",
      sectionIcon: BroadcastLogo,
      hasSubSections: false,
    },
    {
      sectionName: "Employees",
      url: "/admin/employees",
      sectionIcon: EmployeesLogo,
      onlyAdmin: true,
      hasSubSections: false,
    },
    // {
    //   sectionName: "Analytics",
    //   url: "/admin/analytics",
    //   sectionIcon: AnalyticsLogo,
    //   hasSubSections: true,
    //   subsections: [
    //     {
    //       sectionName: "Revenue",
    //       url: "/admin/analytics/revenue",
    //       sectionIcon: AnalyticsRevenue,
    //     },
    //     {
    //       sectionName: "Unit",
    //       url: "/admin/analytics/unit",
    //       sectionIcon: AnalyticsUnit,
    //     },
    //     {
    //       sectionName: "Tenant",
    //       url: "/admin/analytics/tenant",
    //       sectionIcon: AnalyticsTenant,
    //     },
    //   ],
    // },
    {
      sectionName: "Amenities",
      url: "/admin/amenities",
      sectionIcon: AmenitiesLogo,
    },
    {
      sectionName: "Issues",
      url: "/admin/issues",
      sectionIcon: IssuesIcon,
    },
  ];

  const [collapsed, setCollapsed] = useState(true);

  const [isSubSectionExpanded, setIsSubSectionExpanded] = useState(
    dashboardItems.map(() => false)
  );

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    dispatch(updateCurrentPage(location.pathname));
    dispatch(updateCurrentChildPage(location.pathname));
  }, [location]);

  return (
    <div
      className={`${
        collapsed
          ? styles.admin_home_sidebar_collpased
          : styles.admin_home_sidebar
      } ${state ? styles.admin_home_sidebar_mobile : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {collapsed && !state ? (
        <img
          className={styles.loyal_partners_logo_icon}
          src={LoyalPartnersLogoMobile}
          alt="LoyalPartnersLogoMobile"
        ></img>
      ) : (
        <img
          className={styles.loyal_partners_logo}
          src={LoyalPartnersLogo}
          alt="LoyalPartnersLogo"
        ></img>
      )}

      {!collapsed || state ? (
        <div className={styles.admin_dashboard_wrapper}>
          {dashboardItems.map((item, index) =>
            userType === USER_TYPE.EMPLOYEE &&
            !featureAccess.includes(item.sectionName) ? (
              <></>
            ) : (
              <>
                <div
                  className={
                    styles.dashboard_section +
                    " " +
                    (stateData.activePage?.includes(item.url)
                      ? styles.dashboard_section_active
                      : "")
                  }
                  key={index}
                  onClick={() => {
                    if (item.hasSubSections) {
                      let newArr = isSubSectionExpanded;
                      newArr[index] = !newArr[index];
                      setIsSubSectionExpanded(newArr);
                      dispatch(
                        updateCurrentChildPage(item?.subsections[0]?.url)
                      );
                    }
                    dispatch(
                      updatePageNumber({
                        pageNo: 1,
                      })
                    );
                    navigate(
                      item.hasSubSections ? item?.subsections[0]?.url : item.url
                    );
                    dispatch(updateCurrentPage(item.url));
                  }}
                >
                  <img src={item.sectionIcon} alt={item.sectionIcon}></img>
                  <span>{item.sectionName}</span>
                  {item &&
                    item.hasSubSections &&
                    (isSubSectionExpanded[index] ? (
                      <img
                        className={styles.admin_navbar_expand_collapse_icon}
                        src={CollapseIcon}
                        alt="ExpandCollapseIcon"
                      />
                    ) : (
                      <img
                        className={styles.admin_navbar_expand_collapse_icon}
                        src={ExpandedIcon}
                        alt="ExpandCollapseIcon"
                      />
                    ))}
                </div>
                {item.hasSubSections && isSubSectionExpanded[index] ? (
                  item.subsections.map((subsection, subsecIndex) => {
                    return (
                      <div
                        className={
                          styles.dashboard_section +
                          " " +
                          styles.dashboard_section_subsection +
                          " " +
                          (stateData.activePage.includes(item.url) &&
                          stateData.activeChildPage?.includes(subsection.url)
                            ? styles.dashboard_section_active
                            : "")
                        }
                        key={subsecIndex}
                        onClick={() => {
                          navigate(subsection.url);
                          dispatch(updateCurrentChildPage(subsection.url));
                        }}
                      >
                        <img
                          src={subsection.sectionIcon}
                          alt={subsection.sectionIcon}
                        ></img>
                        <span>{subsection.sectionName}</span>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </>
            )
          )}
          <div
            className={styles.dashboard_section}
            onClick={() => {
              setCollapsed(!collapsed);
              state && setState(!state);
            }}
          >
            <img src={DashboardCollapse} alt={"collapseIcon"}></img>
            <span>Collapse Sidebar</span>
          </div>
        </div>
      ) : (
        <div className={styles.admin_dashboard_wrapper_collapsed}>
          {dashboardItems.map((item, index) =>
            userType === USER_TYPE.EMPLOYEE &&
            !featureAccess.includes(item.sectionName) ? (
              <></>
            ) : (
              <div
                className={
                  styles.dashboard_section +
                  " " +
                  (stateData.activePage?.includes(item.url)
                    ? styles.dashboard_section_active
                    : "")
                }
                key={index}
                onClick={(event) => {
                  event.preventDefault();
                  if (item.hasSubSections) {
                    let newArr = isSubSectionExpanded;
                    newArr[index] = !newArr[index];
                    setIsSubSectionExpanded(newArr);
                    dispatch(updateCurrentChildPage(item?.subsections[0]?.url));
                  }
                  dispatch(
                    updatePageNumber({
                      pageNo: 1,
                    })
                  );
                  navigate(
                    item.hasSubSections ? item?.subsections[0]?.url : item.url
                  );
                  dispatch(updateCurrentPage(item.url));
                }}
              >
                <img src={item.sectionIcon} alt={item.sectionIcon}></img>
              </div>
            )
          )}
          <div
            className={
              styles.dashboard_section + " " + styles.navbar_collapse_button
            }
            onClick={toggleSidebar}
          >
            <img src={DashboardExpand} alt={"expandIcon"}></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationBar;
