// This file contains general functions that are used in multiple places in the app

// This function is used to clean the form object before sending it to the backend
const cleanFormObject = (formObject) => {
  Object.keys(formObject).forEach((key) => {
    if (formObject[key] == null || formObject[key] === "") {
      delete formObject[key];
    } else if (typeof formObject[key].name === "string") {
    } else if (typeof formObject[key] === "object") {
      Object.keys(formObject[key]).forEach((internalKey) => {
        if (typeof formObject[key][internalKey] === "object") {
          Object.keys(formObject[key][internalKey]).forEach((internalKey2) => {
            if (
              formObject[key][internalKey][internalKey2] == null ||
              formObject[key][internalKey][internalKey2] === ""
            ) {
              delete formObject[key][internalKey][internalKey2];
            }
          });
        }
        if (
          Object.keys(formObject[key][internalKey]).length === 0 &&
          formObject[key][internalKey].constructor === Object
        ) {
          delete formObject[key][internalKey];
        }
      });
    }
    if (
      typeof formObject[key] === "object" &&
      Object.keys(formObject[key]).length === 0 &&
      typeof formObject[key].name !== "string"
    ) {
      delete formObject[key];
    }
  });

  return formObject;
};

const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  if (inputDate === null || inputDate === undefined || inputDate === "") {
    return "";
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  let result = `${day}/${month}/${year}`;

  return result;
};

const numberWithCommas = (number) => {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (err) {
    return number;
  }
};

const printCurrentPage = (setPrintStart, pageTitle) => {
  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const PrintDesktop = () => {
    setPrintStart(true);

    setTimeout(() => {
      document.getElementById("root").style.display = "none";
      document.title = pageTitle;
      let prevTitle = document.title;
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        alert("Please use Chrome or Firefox for better experience");
        // document.body.classList.add('safari');
        document.documentElement.style.height = "100%";
        document.body.style.height = "100%";
      }
      // title = tenant name + Rental Ledger
      window.print();
      document.getElementById("root").style.display = "block";
      document.title = prevTitle;
      setPrintStart(false);
    }, 500);
    // alert(window.mobileCheck());
  };

  const PrintMobile = () => {
    setPrintStart(true);
    setTimeout(() => {
      // Trigger the print
      document.getElementById("root").style.display = "none";
      let prevTitle = document.title;
      if (pageTitle) document.title = pageTitle;
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        alert("Please use Chrome or Firefox for better experience");
        document.documentElement.style.height = "100%";
        document.body.style.height = "100%";

        window.print();
        const handleAfterPrint = () => {
          document.getElementById("root").style.display = "block";
          document.title = prevTitle;
          setPrintStart(false);
          window.removeEventListener("afterprint", handleAfterPrint);
        };
        window.addEventListener("afterprint", handleAfterPrint);
      } else {
        window.print();
        const handleFocus = () => {
          document.getElementById("root").style.display = "block";
          document.title = prevTitle;
          setPrintStart(false);
          // Remove the focus event listener once the print process is completed
          window.removeEventListener("focus", handleFocus);
        };

        window.addEventListener("focus", handleFocus);
      }
    }, 500);
  };

  if (window.mobileCheck()) {
    PrintMobile();
  } else {
    PrintDesktop();
  }
};

export { cleanFormObject, formatDate, numberWithCommas, printCurrentPage };
