import styles from "../../../../styles/sections/admin/employees/AddEmployee.module.css";
import { useEffect, useState } from "react";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { useDispatch } from "react-redux";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { employeeSchema } from "../../../../utils/validators";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";

const AddEmployee = () => {
  const [navLinkActive, setNavLinkActive] = useState("Admin Form");
  const employeeId = useParams().employeeId || null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminEmployeesForm = [
    {
      field_name: "Employee Name",
      field_id: "name",
      placeholder: "Enter Employee Name",
      type: "text",
    },
    {
      field_name: "Email",
      field_id: "email",
      placeholder: "Enter Email",
      type: "email",
    },
    {
      field_name: "Mobile Number",
      field_id: "mobile",
      placeholder: "Enter Mobile Number",
      type: "number",
    },
    {
      field_name: "WhatsApp Number",
      field_id: "whatsapp",
      placeholder: "Enter WhatsApp Number",
      type: "number",
    },
    {
      field_name: "Designation",
      field_id: "designation",
      placeholder: "Enter Designation",
      type: "text",
    },
    {
      field_name: "Salary",
      field_id: "salary",
      placeholder: "Enter Salary",
      type: "number",
    },
    {
      field_name: "Joining Date",
      field_id: "startDate",
      type: "date",
    },
    {
      field_name: "Is Active",
      field_id: "isActive",
      type: "dropdown",
      default: true,
      options: [
        { id: true, name: "Yes" },
        { id: false, name: "No" },
      ],
    },
    // {
    //   field_name: "End Date",
    //   field_id: "endDate",
    //   type: "date",
    // },
    {
      field_name: "Feature Access",
      field_id: "featureAccess",
      type: "dropdown",
      // default: "Yes",
      multiSelect: true,
      options: [
        { id: "Dashboard", name: "Dashboard" },
        { id: "Units", name: "Units" },
        { id: "Tenants", name: "Tenants" },
        { id: "Approvals", name: "Approvals" },
        { id: "Notes", name: "Notes" },
        { id: "Cleaning", name: "Cleaning" },
        { id: "Parking", name: "Parking" },
        { id: "Invoices", name: "Invoices" },
        { id: "Broadcast", name: "Broadcast" },
        { id: "Employees", name: "Employees" },
        { id: "Analytics", name: "Analytics" },
        { id: "Issues", name: "Issues" },
        { id: "Amenities", name: "Amenities" },
      ],
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return adminEmployeesForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    // console.log(fieldId, value, error, errorMessage);

    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newEmployee = {};
    formFields.map((item) => {
      newEmployee[item.field_id] = item.value || item?.default;
    });

    newEmployee = cleanFormObject(newEmployee);

    // Validate the form data
    const { error } = employeeSchema.validate(newEmployee);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));

      let response = null;

      // upadte
      if (employeeId) {
        response = await postFormData({
          form_key: "ADMIN__EMPLOYEE__UPDATE_EMPLOYEE_DETAILS",
          data: newEmployee,
          urlId: employeeId,
          method: "put",
        });
      } else {
        // add
        response = await postFormData({
          form_key: "ADMIN__EMPLOYEE__ADD_EMPLOYEE",
          data: newEmployee,
        });
      }

      if (response.success) {
        if (employeeId) {
          navigate("/admin/employees/" + employeeId);
        } else {
          navigate("/admin/employees");
        }
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const handleGetMoreDetails = async (employeeId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__EMPLOYEE__GET_EMPLOYEE_DETAILS",
      oneId: employeeId,
    });

    Object.keys(response).map((item) => {
      if (item === "startDate" || item === "endDate")
        response[item] = response[item].substring(0, 10);

      handleFieldChange(item, response[item], false, "");
    });

    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (employeeId) handleGetMoreDetails(employeeId);
  }, [employeeId]);

  return (
    <div className={styles.add_employee}>
      <span className={styles.section_header}>
        <img src={BackArrow} alt="" onClick={() => navigate(-1)}></img>
        {employeeId ? "Update Employee Details" : "Add New Employee"}
      </span>
      <div className={styles.content_wrap}>
        {navLinkActive === "Admin Form" && (
          <ProfileUpdateForm
            form_fields={formFields}
            handleFieldChange={handleFieldChange}
          />
        )}
        <div className={styles.submit_button_container}>
          <button className={styles.submit_button} onClick={formHandler}>
            {employeeId ? "Update" : "Add"} Employee
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
