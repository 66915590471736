import React, { useRef } from "react";
import styles from "../../../styles/common/stats_table/StatsTable.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updatePageNumber } from "../../../redux/reducers/PageScrollSlice";

const StatsTable = ({
  tableTitle,
  tableHeaders,
  tableData,
  tableFieldMapping,
  tableActionsElement,
  tableActionsElementMobile,
  tableNavBar,
  tableIcon,
  state,
  totalPages = 0,
  selectAll = false,
  handleSelectAll = () => {},
  removePadding = false,
  addScroll = true,
}) => {
  const handleClick = (status) => {
    if (state) {
      state(status);
    }
  };

  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const scroll = useSelector((state) => state.pageScroll);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      if (
        // if less than 2px then scrolled to the bottom
        container.scrollTop + container.clientHeight + 2 >=
        container.scrollHeight
      ) {
        // User has scrolled to the bottom
        console.log("I reached last", scroll.pageNo, totalPages);
        // You can call your next API here
        if (scroll.pageNo < totalPages) {
          console.log("I am calling the next API");
          dispatch(
            updatePageNumber({
              pageNo: scroll.pageNo + 1,
            })
          );
        }
      }
    }
  };

  return (
    <div
      className={
        styles.stats_table_container +
        " " +
        (removePadding && styles.remove_outer_padding) +
        " " +
        (addScroll && styles.stats_table_container_scroll)
      }
      onScroll={handleScroll}
      ref={containerRef}
    >
      {tableActionsElement && (
        <div
          className={
            styles.stats_title_actions_container +
            " " +
            (addScroll && styles.stats_title_actions_container_sticky) +
            " " +
            (tableActionsElementMobile
              ? styles.stats_title_actions_container_hide
              : "")
          }
        >
          <div
            className={
              styles.stats_table_title +
              " " +
              (addScroll && styles.stats_table_title_sticky)
            }
          >
            {tableIcon && (
              <img
                src={tableIcon}
                alt=""
                onClick={() => {
                  state();
                }}
              ></img>
            )}
            {tableTitle}
          </div>
          {tableActionsElement}
        </div>
      )}
      {tableActionsElementMobile && <>{tableActionsElementMobile} </>}
      {tableNavBar && <div className={styles.table_nav_bar}>{tableNavBar}</div>}
      <div
        className={
          styles.table_header + " " + (addScroll && styles.table_header_sticky)
        }
      >
        {tableHeaders?.map((header, index) =>
          header === "checkbox" ? (
            <div key={index}>
              <input
                type="checkbox"
                className={styles.table_checkbox}
                checked={selectAll}
                onChange={(e) => {
                  handleSelectAll(e.target.checked);
                }}
              />
            </div>
          ) : (
            <div key={index}>{header}</div>
          )
        )}
      </div>

      {tableData?.map((item, index) => (
        <div className={styles.row} key={index}>
          {tableHeaders?.map((header, dataIndex) => (
            <div className={styles.cell} key={dataIndex}>
              {item[tableFieldMapping[dataIndex]]}
              {/* <div>
                <span>{tableFieldMapping[dataIndex]}</span>
                <span>{item[tableFieldMapping[dataIndex]]}</span>
              </div> */}
            </div>
          ))}
        </div>
      ))}
      {tableData?.map((item, index) => (
        <div
          className={`${styles.row_wrap} ${
            item.status === "Declined" && styles.declined
          } ${item.status === "Waiting" && styles.waiting} ${
            item.status === "Approved" && styles.approved
          }`}
          key={index}
          onClick={() => {
            handleClick(item.status);
          }}
        >
          <div className={styles.row_mobile}>
            {tableFieldMapping?.map((header, dataIndex) => (
              <div className={styles.cell} key={dataIndex}>
                {/* {item[header]} */}

                <div
                  className={
                    styles.cell_wrap_mobile +
                    " " +
                    (tableHeaders[dataIndex] === "Action" &&
                      styles.action_cell_mobile)
                  }
                >
                  {tableHeaders[dataIndex] !== "Action" && (
                    <div>{tableHeaders[dataIndex]}</div>
                  )}
                  <div>{item[header]}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatsTable;
