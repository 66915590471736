import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePage: "/admin/dashboard",
  activeChildPage: "/admin/analytics/revenue",
};

const currentPageSlice = createSlice({
  name: "currentPage",
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.activePage = action.payload;
    },
    updateCurrentChildPage: (state, action) => {
      state.activeChildPage = action.payload;
    },
  },
});

export const { updateCurrentPage, updateCurrentChildPage } =
  currentPageSlice.actions;
export default currentPageSlice.reducer;
