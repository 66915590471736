import styles from "../../../styles/sections/tenant/tenant.module.css";
import Header from "../admin/header/Header";
import Home from "./home/Home";
import TenantNavigationBar from "./tenant_navigation_bar/TenantNavigationBar";
import { Route, Routes } from "react-router-dom";
import PersoalDetails from "./personal_details/PersonalDetails";
import Payments from "./payments/Payment";
import Notification from "../../common/notification/Notification";
import { useSelector } from "react-redux";
import Loader from "../../common/loader/Loader";
import { useEffect, useState } from "react";
import Profile from "./Profile/Profile";
import FileViewer from "../../common/image_verification/FileViewer";
import BackDropRulesAndReg from "../../common/back_drop/BackDropRulesAndReg";
import RequestAndApprovals from "./RequestAndApprovals/RequestAndApprovals";
import TenantIssues from "./IssueManagement/TenantIssues";
import TenantReferral from "./Referral/TenantReferral";

// import {useState} from "react";

const Tenant = () => {
  const stateData = useSelector((state) => state.loader);

  useEffect(() => {
    document.title = "Tenant Dashboard";
  }, []);

  const tenantFeatures = [
    {
      sectionName: "Home",
      url: "/home",
      component: <Home />,
    },
    {
      sectionName: "Personal Details",
      url: "/personal",
      component: <PersoalDetails />,
    },
    {
      sectionName: "Payment",
      url: "/payment",
      component: <Payments />,
    },
    {
      sectionName: "Request/Approvals",
      url: "/requestApprovals",
      component: <RequestAndApprovals />,
    },
    {
      sectionName: "Issues",
      url: "/issues",
      component: <TenantIssues />,
    },
    {
      sectionName: "Referral",
      url: "/referral",
      component: <TenantReferral />,
    },
    {
      sectionName: "Profile",
      url: "/profile",
      component: <Profile />,
    },
    {
      sectionName: "Notification",
      url: "/notification",
      component: <Notification />,
    },
  ];

  return (
    <div className={styles.tenant}>
      {stateData.isLoading && <Loader />}

      <TenantNavigationBar />
      <div className={styles.content}>
        <Header />
        <Routes>
          {tenantFeatures.map((feature, index) => {
            return (
              <Route
                key={index}
                path={feature.url}
                element={feature.component}
              />
            );
          })}
        </Routes>
      </div>

      {localStorage?.getItem("agreedOnRules") !== "AGREE" && (
        <BackDropRulesAndReg />
      )}
    </div>
  );
};

export default Tenant;
