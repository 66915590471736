import styles from "../../../../styles/sections/admin/units/AddUnit.module.css";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import Expenses from "../../../common/expenses/Expenses";
import { unitSchema } from "../../../../utils/validators";
import { useState } from "react";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { useEffect } from "react";

const AddUnit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const unitId = useParams().unitId || null;

  const addUnitDetailsForm = [
    {
      field_name: "Unit Name",
      field_id: "unitName",
      placeholder: "Enter Unit Name",
      type: "text",
    },
    {
      field_name: "Unit Address",
      field_id: "unitAddress",
      placeholder: "Enter Unit Address",
      type: "address",
    },
    {
      field_name: "Lease Taken On",
      field_id: "leaseTakenOn",
      placeholder: "Enter Lease Taken On",
      type: "date",
    },
    {
      field_name: "Agency Name",
      field_id: "agencyName",
      placeholder: "Enter Agency Name",
      type: "text",
    },
    {
      field_name: "Weekly rent",
      field_id: "weeklyRent",
      placeholder: "Enter Weekly rent",
      type: "number",
    },
    {
      field_name: "Agent Name",
      field_id: "agentName",
      placeholder: "Enter Agent Name",
      type: "text",
    },
    {
      field_name: "Bond Amount",
      field_id: "bondAmount",
      placeholder: "Enter Bond Amount",
      type: "number",
    },
    {
      field_name: "Contact Email",
      field_id: "contactEmail",
      placeholder: "Enter Contact Email",
      type: "email",
    },
    {
      field_name: "Electricity Agency Name",
      field_id: "electricityAgencyName",
      placeholder: "Enter Electricity Agency Name",
      type: "text",
    },
    {
      field_name: "Contact Landline",
      field_id: "contactLandline",
      placeholder: "Enter Contact Landline",
      type: "text",
    },
    {
      field_name: "Gas Agency Name",
      field_id: "gasAgencyName",
      placeholder: "Enter Gas Agency Name",
      type: "text",
    },
    {
      field_name: "Number Of Position",
      field_id: "noOfPosition",
      placeholder: "Enter Number Of Position",
      type: "number",
    },
    {
      field_name: "Internet Provider Name",
      field_id: "internetProvider",
      placeholder: "Enter Internet Provider Name",
      type: "text",
    },
    {
      field_name: "Unit Type",
      field_id: "unitType",
      type: "dropdown",
      // options: ["Male", "Female", "Family", "All"],
      options: [
        { id: "Male", name: "Male" },
        { id: "Female", name: "Female" },
        { id: "Family", name: "Family" },
        { id: "All", name: "All" },
      ],
    },
    {
      field_name: "Next Cleaning Date",
      field_id: "nextCleaningDate",
      placeholder: "Enter Next Cleaning Date",
      type: "date",
    },
    {
      field_name: "Cleaning Frequency (Weeks)",
      field_id: "cleaningFrequency",
      placeholder: "Enter Cleaning Frequency",
      type: "number",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return addUnitDetailsForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const expenses = [
    {
      type: "Electricity Agency",
      type_id: "electricityAgency",
    },
    {
      type: "Gas Agency",
      type_id: "gasAgency",
    },
    {
      type: "Internet Agency",
      type_id: "internetProvider",
    },
    {
      type: "Cleaner",
      type_id: "cleaner",
    },
    {
      type: "Miscellaneous",
      type_id: "miscellaneous",
    },
    {
      type: "Employee Salary",
      type_id: "employee",
    },
    // {
    //   type: "Total Expenses",
    //   type_id: "totalExpenses",
    // },
    // {
    //   type: "Profit To LP",
    //   type_id: "profitToLP",
    // },
  ];

  const initializeExpenseFormState = () => {
    return expenses.map((item) => ({
      ...item,
      expensesCategory: [
        {
          field_name: "Weekly Expenses",
          field_id: "budgetAllocatedWeekly",
          placeholder: "Enter Weekly Expenses",
          value: null,
          type: "number",
          error: false,
          error_message: "",
        },
        {
          field_name: "Monthly Expenses",
          field_id: "budgetAllocatedMonthly",
          placeholder: "Enter Monthly Expenses",
          type: "number",
          value: null,
          error: false,
          error_message: "",
        },
      ],
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      if (index !== -1) {
        updatedFormFields[index] = {
          ...updatedFormFields[index],
          value,
          error,
          error_message: errorMessage,
        };
      } else {
        // Field not found, you may want to handle this scenario
        console.error(`Field with fieldId "${fieldId}" not found.`);
      }

      return updatedFormFields;
    });
  };

  const [expenseFields, setExpenseFields] = useState(
    initializeExpenseFormState()
  );

  // Define a function to handle state update for an item
  const handleExpenseFieldChange = (
    typeId,
    fieldId,
    value,
    error,
    errorMessage
  ) => {
    setExpenseFields((prevFields) => {
      const updatedExpenseFields = [...prevFields];
      const typeIndex = updatedExpenseFields.findIndex(
        (item) => item.type_id === typeId
      );

      if (typeIndex !== -1) {
        const expenseIndex = updatedExpenseFields[
          typeIndex
        ].expensesCategory.findIndex((item) => item.field_id === fieldId);

        if (expenseIndex !== -1) {
          updatedExpenseFields[typeIndex].expensesCategory[expenseIndex] = {
            ...updatedExpenseFields[typeIndex].expensesCategory[expenseIndex],
            value,
            error,
            error_message: errorMessage,
          };
        }
      }

      return updatedExpenseFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newUnit = {};
    formFields.map((item) => {
      newUnit[item.field_id] = item.value;
    });

    let plannedExpenses = {};

    expenseFields.map((item) => {
      plannedExpenses[item.type_id] = {};
      item.expensesCategory.map((expenseItem) => {
        plannedExpenses[item.type_id][expenseItem.field_id] = expenseItem.value;
        return null;
      });
      return null;
    });

    newUnit["plannedExpenses"] = plannedExpenses;

    newUnit = cleanFormObject(newUnit);

    // Validate the form data
    const { error } = unitSchema.validate(newUnit);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        if (item.path[0] === "plannedExpenses") {
          let typeId = item.path[1];
          let errorFieldName = item.path[2];

          // replace error msg whatever in "****" with null
          errorMessage = errorMessage.replace(/"[^"]*"/g, "");

          handleExpenseFieldChange(
            typeId,
            errorFieldName,
            "",
            true,
            errorMessage
          );
        } else {
          let errorFieldName = item.path[0];

          let fieldName = formFields.find(
            (item) => item.field_id === errorFieldName
          ).field_name;

          errorMessage = errorMessage
            .replace(/"/g, "")
            .replace(errorFieldName, fieldName);
          handleFieldChange(errorFieldName, "", true, errorMessage);
        }
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // upadte
      if (unitId) {
        response = await postFormData({
          form_key: "ADMIN__UNITS__UPDATE_UNIT_DETAILS",
          data: newUnit,
          urlId: unitId,
          method: "put",
        });
      } else {
        // add

        newUnit["availablePosition"] = newUnit["noOfPosition"];
        response = await postFormData({
          form_key: "ADMIN__UNITS__ADD_UNIT",
          data: newUnit,
        });
      }

      if (response.success) {
        navigate("/admin/units");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const handleGetMoreDetails = async (unitId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__UNITS__GET_UNIT_DETAILS",
      oneId: unitId,
    });

    Object.keys(response).map((item) => {
      if (item === "plannedExpenses") {
        Object.keys(response[item]).map((expenseItem) => {
          Object.keys(response[item][expenseItem]).map((expenseItem2) => {
            if (expenseItem !== "totalExpense") {
              handleExpenseFieldChange(
                expenseItem,
                expenseItem2,
                response[item][expenseItem][expenseItem2],
                false,
                ""
              );
            }
          });
        });
      } else {
        if (item === "leaseTakenOn")
          response[item] = response[item].substring(0, 10);

        handleFieldChange(item, response[item], false, "");
      }
    });

    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (unitId) handleGetMoreDetails(unitId);
  }, [unitId]);

  return (
    <div className={styles.add_unit}>
      <div className={styles.title}>
        <img
          src={BackArrow}
          alt=""
          onClick={() => navigate("/admin/units")}
        ></img>
        {unitId ? "Update Unit Details" : "Add New Unit"}
      </div>
      <ProfileUpdateForm
        form_fields={formFields}
        handleFieldChange={handleFieldChange}
      />
      <Expenses
        expenseFields={expenseFields}
        handleExpenseFieldChange={handleExpenseFieldChange}
      />
      <div className={styles.submit_button_container}>
        <button className={styles.submit_button} onClick={formHandler}>
          Add Unit
        </button>
      </div>
    </div>
  );
};

export default AddUnit;
