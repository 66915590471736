import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/tenant/referral/TenantReferral.module.css";
import { useDispatch, useSelector } from "react-redux";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";

import referralAsset from "../../../../assets/sections/tenant/referral/referral_asset.svg";

const TenantReferral = () => {
  const tenantData = useSelector((state) => state.userInfo);

  return (
    <div className={styles.referral_content}>
      <div className={styles.referralCard}>
        <div className={styles.top}>
          <div className={styles.title}>Referral</div>
          <div className={styles.topBox}>
            <div className={styles.leftBox}>
              <div className={styles.total}>Total Reward Earned: </div>
              <div className={styles.totalCount}>
                ${tenantData.tenantInfo?.referralDetails?.totalRewardAmount}/-
              </div>
            </div>
            <div className={styles.rightBox}>
              <img src={referralAsset} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.middle}>
          <div className={styles.leftBox}>
            <div className={styles.text1}>Refer friends and Earn</div>
            <div className={styles.text2}>
              Introduce a friend about us and get rewards
            </div>
          </div>
          <div className={styles.rightBox}>
            <div
              className={styles.parellelogram + " " + styles.parellelogram_back}
            ></div>
            <div
              className={styles.parellelogram + " " + styles.parellelogramfront}
            ></div>
            <div className={styles.parellelogram_content}>
              <div className={styles.rewardAmount}>$10</div>
              <div className={styles.rewardSupportingTest}>
                <div className={styles.boldContent}>Per</div>
                <div className={styles.softContent}>Refer</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.content}>
            <div className={styles.supportingText}>
              Share below referral code code with friends
            </div>
            <div className={styles.box}>
              <div className={styles.uniqueCode}>
                {tenantData.tenantInfo?.referralDetails?.ownReferralCode}
              </div>
              <div
                className={styles.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(
                    tenantData.tenantInfo?.referralDetails?.ownReferralCode
                  );
                  alert("Code Copied");
                }}
              >
                Copy Code
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantReferral;
