import Axios from "../axios";
import { updateDashboardPlot } from "../redux/reducers/admin/PlotsSlice";
import { showLoader } from "../redux/reducers/loaderSlice";
import { apiEndpointname } from "../utils/constants";

const getPlotDataPoints = async () => {
  let apiPath = apiEndpointname.ADMIN_DASHBOARD_PLOTS;

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "api/v1" + apiPath,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  try {
    const response = await Axios.request(config);

    if (
      (response.status === 200 || response.status === 201) &&
      response.data.success === true
    ) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
    }
    return [];
  }
};

const fetchPlotDataPoints = async (dispatch, plotKey) => {
  try {
    dispatch(showLoader(true));

    let response = "";

    response = await getPlotDataPoints();

    const responseData = response?.data?.data;

    dispatch(
      updateDashboardPlot({
        // expense: responseData.expense,
        earning: responseData.earning,
        // profit: responseData.profit,
      })
    );

    dispatch(showLoader(false));
  } catch (err) {
    console.log("Error - fetchPlotDataPoints", err);
    dispatch(showLoader(false));
  }
};

export { fetchPlotDataPoints };
