import styles from "../../../styles/common/form_dropdown/FormDropDown.module.css";
import { useEffect, useState } from "react";
import DropDown from "../../../assets/common/dropdown_logo.svg";

const FormDropDown = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [state, setState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (props.value) {
      if (props.multiSelect) {
        setSelectedOptions(props.value);
      } else {
        const selected = props.options.find(
          (option) => option.id === props.value
        );
        if (selected) {
          setSelectedOption(selected.name);
        }
      }
    }
  }, [props.value, props.options, props.multiSelect]);

  const handleSelect = ({ option, add = false }) => {
    let updatedOptions;
    if (props.multiSelect) {
      if (add) {
        updatedOptions = [...selectedOptions, option.name || option];
      } else {
        updatedOptions = selectedOptions.filter(
          (selected) => selected !== (option.name || option)
        );
      }
      setSelectedOptions(updatedOptions);
      props.selectHandler(props.id, updatedOptions);
    } else {
      setSelectedOption(option.name || option);
      setState(!state);
      props.selectHandler(props.id, option.id || option);
    }
  };

  const filteredOptions = props.options.filter((option) => {
    const name =
      typeof option === "object"
        ? option.name.toLowerCase()
        : option.toLowerCase();
    return name.includes(searchTerm.toLowerCase());
  });

  const [openedMultiSelect, setOpenedMultiSelect] = useState(false);

  return (
    <div
      className={`${state ? styles.select_active : styles.select_inactive} ${
        styles.select
      }`}
      onClick={() => {
        if (props?.disabled) return;
        // console.log("clicked");
        if (!openedMultiSelect) {
          setOpenedMultiSelect(true);
          setState(!state);
        }
      }}
    >
      <div className={styles.main_secion}>
        {state ? (
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
        ) : (
          <span>
            {!props.multiSelect &&
              (selectedOption || (
                <span className={styles.placeholder}>
                  Please Select an option
                </span>
              ))}
            {props.multiSelect &&
              (selectedOptions.length > 0 ? (
                <span className={styles.multiSelect}>
                  {selectedOptions.length} Selected
                </span>
              ) : (
                <span className={styles.placeholder}>
                  Please Select an option
                </span>
              ))}
          </span>
        )}
        <img
          src={DropDown}
          alt=""
          className={!state && styles.drop_down_icon_active}
          onClick={() => {
            if (props?.disabled) return;
            setState(!state);
            setOpenedMultiSelect(!openedMultiSelect);
          }}
        />
      </div>
      {state && filteredOptions.length > 0 && (
        <div className={styles.option_wrap}>
          <div className={styles.option_wrap_inner}>
            {filteredOptions.map((option, index) => (
              <span
                key={index}
                onClick={() => handleSelect({ option })}
                className={styles.choices}
              >
                {props.multiSelect && (
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option.name || option)}
                    onChange={(e) => {
                      // e.stopPropagation();
                      handleSelect({
                        option,
                        add: e.target.checked,
                      });
                    }}
                  />
                )}
                {option.name || option}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDropDown;
