import React from "react";

import styles from "../../styles/authentication/Login.module.css";
import LoginPage_BG from "../../assets/sections/admin/authentication/LoginPage_BG.png";

import LP_logo from "../../assets/sections/admin/authentication/LP_logo.svg";
import LoginForm from "./LoginForm";

function Login() {
  return (
    <div className={styles.login}>
      <img
        src={LoginPage_BG}
        className={styles.loginPage_BG}
        alt="LoginPage_BG"
      />
      <div className={styles.form_container}>
        <div className={styles.logo}>
          <img src={LP_logo} alt="LP_logo" />
        </div>
        <div className={styles.form_section}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

export default Login;
