import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useEffect, useState } from "react";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { adminPaymentValidationSchema } from "../../../../utils/validators";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { useDispatch } from "react-redux";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";

const AddPaymentRecordForm = ({
  setAddPaymentRecordForm,
  tenantId,
  selectedPaymentId,
  // setSelectedPaymentId,
}) => {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState([]);
  const [initializing, setInitializing] = useState(true);

  let form_fields = [
    {
      field_name: "Payment Category",
      field_id: "paymentCategory",
      placeholder: "Enter Payment Category",
      type: "dropdown",
      options: [
        { id: "Rent", name: "Rent" },
        { id: "Deposit", name: "Deposit" },
        { id: "Other", name: "Other" },
      ],
    },
    {
      field_name: "Payment Mode",
      field_id: "paymentMode",
      placeholder: "Enter Payment Mode",
      type: "dropdown",
      options: [
        { id: "CREDIT CARD", name: "CREDIT CARD" },
        { id: "CASH", name: "CASH" },
        { id: "NET BANKING", name: "NET BANKING" },
      ],
    },
    {
      field_name: "Payment Date",
      field_id: "actualPaymentDate",
      placeholder: "Enter Payment Date",
      type: "date",
    },
    {
      field_name: "Payment Amount",
      field_id: "paymentAmount",
      placeholder: "Enter Payment Amount",
      type: "number",
    },
    {
      field_name: "Note",
      field_id: "description",
      placeholder: "Enter Description",
      type: "text",
    },
  ];

  useEffect(() => {
    initializeFormState();
  }, [selectedPaymentId]);

  const initializeFormState = async () => {
    try {
      dispatch(showLoader(true));
      let newFormFields = [...form_fields];
      if (selectedPaymentId && selectedPaymentId !== 0) {
        const paymentData = await preFetchPaymentData(selectedPaymentId);
        newFormFields = newFormFields.map((item) => {
          let value = paymentData[item.field_id] || "";

          if (item.field_id === "actualPaymentDate") {
            value = new Date(paymentData[item.field_id])
              .toISOString()
              .split("T")[0];
          }
          return {
            ...item,
            value,
            error: false,
            error_message: "",
          };
        });
      } else {
        newFormFields = newFormFields.map((item) => ({
          ...item,
          value: "",
          error: false,
          error_message: "",
        }));
      }
      setFormFields(newFormFields);
      setInitializing(false);
    } catch (error) {
      console.error("Error initializing form:", error);
    } finally {
      dispatch(showLoader(false));
    }
  };

  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = prevFields.map((item) => {
        if (item.field_id === fieldId) {
          return {
            ...item,
            value,
            error,
            error_message: errorMessage,
          };
        }
        return item;
      });
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newPayment = {};
    formFields.forEach((item) => {
      newPayment[item.field_id] = item.value;
    });

    newPayment = cleanFormObject(newPayment);

    // Validate the form data
    const { error } = adminPaymentValidationSchema.validate(newPayment);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.forEach((item) => {
        let errorMessage = item.message;
        let errorFieldName = item.path[0];
        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;
        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      try {
        dispatch(showLoader(true));
        // If validation passes, call the api to save the data
        let response = null;
        let urlId = tenantId;
        if (selectedPaymentId && selectedPaymentId !== 0) {
          urlId = urlId + "/" + selectedPaymentId;
        }
        // add
        response = await postFormData({
          form_key: "ADMIN__TENANTS__ADD_PAYMNET_RECORD",
          data: newPayment,
          urlId: urlId,
        });

        if (response.success) {
          window.location.reload();
        } else {
          alert(response.message);
        }
      } catch (error) {
        console.error("Error handling form submission:", error);
        alert("An error occurred while processing the form submission.");
      } finally {
        dispatch(showLoader(false));
      }
    }
  };

  const preFetchPaymentData = async (selectedPaymentId) => {
    let data = {
      requestId: selectedPaymentId,
      approvalType: "payment",
    };
    let paymentData = await getSingleData({
      API_NAME: "ADMIN__APPROVALS__GET_APPROVAL_DETAILS",
      additionData: data,
      // urlId: requestId,
    });

    return paymentData;
  };

  if (initializing) {
    return <></>;
  }

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {selectedPaymentId && selectedPaymentId !== 0 ? "Update " : "Add "}
            Payment Record
          </div>
          <button
            onClick={() => {
              setAddPaymentRecordForm(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <ProfileUpdateForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          submit={
            selectedPaymentId && selectedPaymentId !== 0
              ? "Update Payment"
              : "Add Payment"
          }
          formHandler={formHandler}
        />
      </form>
    </div>
  );
};

export default AddPaymentRecordForm;
