import styles from "../../../styles/common/notification/Notifiction.module.css";
import BackArrow from "../../../assets/common/back_arrow.svg";
import { useNavigate } from "react-router-dom";
import BackDrop from "../back_drop/BackDrop";
import { useState } from "react";

const Notification=()=>{

    const navigate = useNavigate();
    const [backdropState, setbackdropState]= useState(false);

    const clickHandler=()=>setbackdropState(!backdropState);

    const notifications=[
        {
            title:"Sign Contract",
            content:"-Sign and Re-upload  the signed contract",
            button_group: ["Download", "Upload"]
        },
        {
            title:"Payment Update",
            content:"-Re-upload the Drivers License Image",
            button_group: ["Dismiss"]
        },
        {
            title:"Profile Update",
            content:"-Re-upload the Drivers License Image",
            button_group: ["Dismiss"]
        },
        {
            title:"Payment Update",
            content:"-Re-upload the Drivers License Image",
            button_group:["Dismiss"]
        }, 
    ]

    return(
        <div className={styles.notifications}>
            <span className={styles.header}><img src={BackArrow} alt="" className={styles.back_arrow} onClick={()=>navigate(-1)}></img>Notification</span>
            {notifications.map((item,index)=>{
                return(
                    <div key={index} className={styles.notification_wrap} onClick={clickHandler}>
                        <div className={styles.content_wrap}>
                            <span className={styles.title}>{item.title}</span>
                            <span className={styles.content}>{item.content}</span>
                        </div>
                        <div className={styles.button_wrap}>
                            {item.button_group.map((button,index)=>{
                                return <button key={index}>{button}</button>
                            })}
                        </div>
                    </div>
                    
                )
            })}
            {backdropState && <BackDrop  state={clickHandler}/>}
        </div>
    )
}

export default Notification;