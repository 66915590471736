import React, { useEffect, useState } from "react";
import styles from "../../../../styles/sections/tenant/profile/Profile.module.css";
import IconButton from "../../../common/icon_button/IconButton";
import EditIcon from "../../../../assets/common/edit.svg";
import backArrow from "../../../../assets/common/back_arrow.svg";
import KeyIcon from "../../../../assets/common/key.svg";
import ProfileImageMockUp from "../../../../assets/common/profileImage__mockup.jpeg";

import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import { useDispatch, useSelector } from "react-redux";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { useNavigate } from "react-router-dom";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { tenantUpdateProfileSchema } from "../../../../utils/validators";
import ProfileDetailsForm from "./ProfileDetailsForm";

function Profile() {
  const tenantData = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openFormModal, setOpenFormModal] = useState(false);

  const [tenantDetails, setTenantDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "tenantName",
    },

    {
      data_type: "Email",
      data_value_type: "email",
      value: "",
      id: "email",
    },
    {
      data_type: "Mobile",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
  ]);

  let tenantForm = [
    {
      field_name: "Tenant Name",
      field_id: "tenantName",
      placeholder: "Enter Your Name",
      type: "text",
    },
    {
      field_name: "Email",
      field_id: "email",
      placeholder: "Enter Email",
      type: "email",
    },
    {
      field_name: "Mobile Number",
      field_id: "mobile",
      placeholder: "Enter Mobile Number",
      type: "text",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return tenantForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());
  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newCarParking = {};
    formFields.map((item) => {
      newCarParking[item.field_id] = item.value;
    });

    newCarParking = cleanFormObject(newCarParking);

    // Validate the form data
    const { error } = tenantUpdateProfileSchema.validate(newCarParking);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // upadte

      // add
      response = await postFormData({
        form_key: "TENANT__PERSONAL__UPDATE",
        data: newCarParking,
      });
      if (response.success === true) {
        window.location.reload();
      }

      if (response.success) {
        setOpenFormModal(false);
        navigate("/admin/car_parking");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const getProfileData = async () => {
    const response = await getSingleData({
      API_NAME: "TENANT__PERSONAL__GET_DETAILS",
    });

    // setTenantId(response._id);

    setTenantDetails((prev) => {
      return prev.map((item) => {
        if (item.id in response) {
          return { ...item, value: response[item.id] };
        } else {
          return item;
        }
      });
    });

    setFormFields((prev) => {
      return prev.map((item) => {
        if (item.field_id in response) {
          return { ...item, value: response[item.field_id] };
        } else {
          return item;
        }
      });
    });
  };

  useEffect(() => {
    getProfileData();
  }, [tenantData]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <img
          src={backArrow}
          alt=""
          className={styles.back_arrow}
          onClick={() => {
            // props.state();
            navigate(-1);
          }}
        ></img>
        <span className={styles.title}>My Profile</span>
        {/* <IconButton
          buttonText={""}
          buttonIcon={EditIcon}
          onClick={() => {
            // setCurrentParkingId(item._id);
            // setOpenFormModal(true);
          }}
          whiteBg={true}
        ></IconButton> */}
      </div>
      {/* <div className={styles.body}> */}
      {/* <div className={styles.profileImageContainer}>
          <img
            src={ProfileImageMockUp}
            className={styles.profileImage}
            alt=""
          />
        </div> */}
      {/* <div className={styles.details}>
          <CustomTableV2 data={tenantDetails} />
        </div> */}
      {/* </div> */}
      <div className={styles.details}>
        <CustomTableV2 data={tenantDetails} />
      </div>

      <div className={styles.footer}>
        <button
          className={styles.changePassword}
          onClick={() => {
            navigate("/auth/changePassword");
          }}
        >
          Change Password <img src={KeyIcon} alt="Key" />
        </button>
      </div>
      {openFormModal && (
        <ProfileDetailsForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          setOpenFormModal={setOpenFormModal}
          formHandler={formHandler}
          // currentParkingId={currentParkingId}
        />
      )}
    </div>
  );
}

export default Profile;
