import styles from "../../../styles/common/expenses/Expenses.module.css";

const Expenses = ({
  expenseFields,
  handleExpenseFieldChange,
  valueShow = false,
}) => {
  const headers = [
    "Planned Expenses",
    "Budget Allocated /Weekly",
    "Budget Allocated /Monthly",
  ];
  return (
    <div className={styles.expenses}>
      <div className={styles.expenses_header}>
        {headers.map((header, index) => {
          return <div key={index}>{header}</div>;
        })}
      </div>
      {expenseFields.map((mainItem, index) => {
        return (
          <div className={styles.expenses_content} key={index}>
            <div>{mainItem.type}</div>
            {expenseFields[index].expensesCategory.map((item, index) => {
              return !valueShow ? (
                <div className={styles.input_label} key={index}>
                  <input
                    key={index}
                    type={item.type}
                    placeholder={item.placeholder}
                    value={item.value}
                    onChange={(event) => {
                      handleExpenseFieldChange(
                        mainItem.type_id,
                        item.field_id,
                        event.target.value,
                        false,
                        ""
                      );
                    }}
                  />
                  {item.error && (
                    <p className={styles.error_message}>{item.error_message}</p>
                  )}
                </div>
              ) : (
                <span key={index}>{item.value}</span>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Expenses;
