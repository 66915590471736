import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTenants: [],
};
const broadcastsSlice = createSlice({
  name: "broadCasts",
  initialState,
  reducers: {
    updateSelectedTenants: (state, action) => {
      console.log("updateSelectedTenants", action.payload);
      const { selectedTenant } = action.payload;

      // Use spread operator to create a new array
      const updatedSelectedTenants = state.selectedTenants.includes(
        selectedTenant
      )
        ? state.selectedTenants.filter((tenant) => tenant !== selectedTenant)
        : [...state.selectedTenants, selectedTenant];

      // Update state with the new array
      return {
        ...state,
        selectedTenants: updatedSelectedTenants,
      };
    },
    updateSelectedAllTenants: (state, action) => {
      // direct set all tenants array to selected tenants array first remove all tenants from selected tenants array then add all tenants to selected tenants array
      const { selectedTenants } = action.payload;
      return {
        ...state,
        selectedTenants: selectedTenants,
      };
    },
  },
});

export const { updateSelectedAllTenants, updateSelectedTenants } =
  broadcastsSlice.actions;
export default broadcastsSlice.reducer;
