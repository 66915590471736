import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/tenants/Tenants.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { formatDate } from "../../../../utils/generalFunctions";
import SearchField from "../../../common/search_field/SearchField";
import { debounce } from "lodash";
import TotalTenants from "../../../../assets/sections/admin/tenants/total_tenants.svg";
import OnNotice from "../../../../assets/sections/admin/tenants/on_notice.svg";
import UpcomingTenants from "../../../../assets/sections/admin/tenants/upcoming_tenants.svg";
import TenantsDue from "../../../../assets/sections/admin/tenants/tenants_due.svg";
import TenantsPaid from "../../../../assets/sections/admin/tenants/tenants_paid.svg";
import StatsBoard from "../../../common/stats_board/StatsBoard";
import UpdateIssueForm from "./UpdateIssueForm";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import RequestForm from "./AdminNewIssueForm";
import AddIcon from "../../../../assets/common/add_icon.svg";

const AdminIssues = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const scroll = useSelector((state) => state.pageScroll);
  const statePageStats = useSelector((state) => state.pageStats);

  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  // Units Stats Board
  const tenants_icons = [TotalTenants, UpcomingTenants, OnNotice, OnNotice];

  const tenant_details = [
    "Resolved Issues",
    "Open Issues",
    "Inprogress Issues",
    "On-hold Issues",
  ];

  const tenants_stats_mapping = [
    "resolvedIssues",
    "openIssues",
    "inProgressIssues",
    "onHoldIssues",
  ];
  // Parking Stats Table
  const approval_table_headers = [
    // "Sr No.",
    "Date",
    "Tenant Name",
    "Unit",
    "Issue",
    "Status",
    "Action",
  ];

  const approval_tableFieldMapping = [
    // "sr_no",
    "date",
    "tenantName",
    "unit",
    "issue",
    "status",
    "action",
  ];

  const [openFormModal, setOpenFormModal] = useState(false);
  const [openFormModalNewIssue, setOpenFormModalNewIssue] = useState(false);

  const [approvalTableData, setApprovalTableData] = useState([]);

  const [activeIssue, setActiveIssue] = useState("");

  let status_colors = {
    open: "#FF5733",
    in_progress: "#4169E1",
    on_hold: "#808080",
    resolved: "#32CD32",
  };

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      console.log("Item: ", item);
      mappedData = {};

      mappedData._id = item._id;
      mappedData.sr_no = index + 1;
      mappedData.date = formatDate(item.createdAt);
      mappedData.tenantName = item.tenantName || "Raised By ADMIN";
      mappedData.unit = item.unitName;
      mappedData.issue = item.content;
      mappedData.status = (
        <p
          style={{
            color: status_colors[item.status?.toLowerCase()],
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {item.status?.toUpperCase()}
        </p>
      );
      mappedData.action = (
        <ActionButton
          buttonText="Update the Status"
          onClick={() => {
            setActiveIssue(item._id);
            setOpenFormModal(true);
          }}
        />
      );

      mappedDataList.push(mappedData);
    });

    setApprovalTableData(mappedDataList);
    console.log("Mapped Data List: ", mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__ISSUES__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__ISSUES__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminIssues?.length >= 0)
      processTableData(stateDataTableData.adminIssues);
  }, [stateDataTableData.adminIssues]);

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };

  return (
    <div className={styles.admin_tenants_content}>
      <StatsBoard
        boardName="Issues"
        statIcons={tenants_icons}
        statNames={tenant_details}
        statValuesMapping={tenants_stats_mapping}
        statValues={statePageStats?.adminIssueStats}
      ></StatsBoard>

      <StatsTable
        tableTitle={"Issues"}
        tableHeaders={approval_table_headers}
        tableData={approvalTableData}
        tableFieldMapping={approval_tableFieldMapping}
        tableActionsElement={
          <div className={styles.tenants_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <ActionButton
              buttonText="Raise an Issue"
              icon={AddIcon}
              onClick={() => {
                setOpenFormModalNewIssue(true);
              }}
            />
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>Issues</div>
              <div className={styles.units_stats_table_actions}>
                <ActionButton
                  buttonText="Request Issue"
                  icon={AddIcon}
                  onClick={() => {
                    setOpenFormModalNewIssue(true);
                  }}
                />
              </div>
            </div>
            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.adminIssuesTotalPages}
      ></StatsTable>
      {openFormModal && (
        <UpdateIssueForm
          setOpenFormModal={setOpenFormModal}
          activeIssue={activeIssue}
        />
      )}
      {openFormModalNewIssue && (
        <RequestForm setOpenFormModal={setOpenFormModalNewIssue} />
      )}
    </div>
  );
};

export default AdminIssues;
