import { createSlice } from "@reduxjs/toolkit";
import { tableKey } from "../../../utils/constants";

const initialState = {
  adminUnitsTotalPages: 1,
  adminTenantsTotalPages: 1,
  adminApprovalsTotalPages: 1,
  adminAmnitiesTotalPages: 1,
  adminNotesTotalPages: 1,
  adminMessagesTotalPages: 1,
  adminInvoiceTotalPages: 1,
  adminCleaningTotalPages: 1,
  adminCleanerTotalPages: 1,
  adminCarParkingTotalPages: 1,
  adminTenantPaymentHistoryTotalPages: 1,
  tenantPaymentHistoryTotalPages: 1,
  adminIssuesTotalPages: 1,
  tenantIssuesTotalPages: 1,
  tenantRequestTotalPages: 1,
  activeTableKey: "",
};

const totalPagesSlice = createSlice({
  name: "totalPages",
  initialState,
  reducers: {
    updateTotalPages: (state, action) => {
      const { currentTableKey, currentTotalPages } = action.payload;

      switch (currentTableKey) {
        case tableKey.ADMIN__UNITS__ALL_UNITS:
          state.adminUnitsTotalPages = currentTotalPages;
          state.activeTableKey = "adminUnitsTotalPages";
          break;
        case tableKey.ADMIN__TENANTS__TENANTS:
          state.adminTenantsTotalPages = currentTotalPages;
          state.activeTableKey = "adminTenantsTotalPages";
          break;
        case tableKey.ADMIN__APPROVALS__APPROVALS:
          state.adminApprovalsTotalPages = currentTotalPages;
          state.activeTableKey = "adminApprovalsTotalPages";
          break;
        case tableKey.ADMIN__AMENITIES__AMENITIES:
        case tableKey.ADMIN__AMENITIES__APPROVALS:
          state.adminAmnitiesTotalPages = currentTotalPages;
          state.activeTableKey = "adminAmnitiesTotalPages";
          break;
        case tableKey.ADMIN__NOTES__NOTES:
          state.adminNotesTotalPages = currentTotalPages;
          state.activeTableKey = "adminNotesTotalPages";
          break;
        case tableKey.ADMIN__MESSAGES__MESSAGES:
          state.adminMessagesTotalPages = currentTotalPages;
          state.activeTableKey = "adminMessagesTotalPages";
          break;
        case tableKey.ADMIN__INVOICES__INVOICES:
          state.adminInvoiceTotalPages = currentTotalPages;
          state.activeTableKey = "adminInvoiceTotalPages";
          break;
        case tableKey.ADMIN__CLEANING__CLEANING:
          state.adminCleaningTotalPages = currentTotalPages;
          state.activeTableKey = "adminCleaningTotalPages";
          break;
        case tableKey.ADMIN__CLEANING__CLEANER:
          state.adminCleaningTotalPages = currentTotalPages;
          state.activeTableKey = "adminCleaningTotalPages";
          break;
        case tableKey.ADMIN__CAR_PARKING__CAR_PARKING:
          state.adminCarParkingTotalPages = currentTotalPages;
          state.activeTableKey = "adminCarParkingTotalPages";
          break;
        case tableKey.ADMIN__ISSUES__ISSUES:
          state.adminIssuesTotalPages = currentTotalPages;
          state.activeTableKey = "adminIssuesTotalPages";
          break;
        case tableKey.TENANT__ISSUES__ISSUES:
          state.tenantIssuesTotalPages = currentTotalPages;
          state.activeTableKey = "tenantIssuesTotalPages";
          break;
        case tableKey.TENANT__REQUEST__REQUESTS:
          state.tenantRequestTotalPages = currentTotalPages;
          state.activeTableKey = "tenantRequestTotalPages";
          break;
        case tableKey.TENANT__PAYMENT_HISTORY:
          state.tenantPaymentHistoryTotalPages = currentTotalPages;
          state.activeTableKey = "tenantPaymentHistoryTotalPages";
          break;
        default:
          // Handle the default case if needed
          break;
      }
    },
  },
});

export const { updateTotalPages } = totalPagesSlice.actions;
export default totalPagesSlice.reducer;
