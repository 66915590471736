import React from "react";
import { Route, Routes } from "react-router-dom";
import Broadcasts from "./Broadcasts";
import BroadcastMessages from "./BroadcastMessages";
import styles from "../../../../styles/sections/admin/broadcasts/broadcasts.module.css";

const BroadcastRoutes = () => {
  return (
    <div className={styles.admin_tenants_content}>
      <Routes>
        <Route exact path="/" element={<Broadcasts />} />
        {/* <Route path="/:unitId" element={<CleaningDetails />} /> */}
        <Route path="/history/:history_type?" element={<BroadcastMessages />} />
      </Routes>
    </div>
  );
};

export default BroadcastRoutes;
