import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/cleaning/Cleaning.module.css";

import UnitsDue from "../../../../assets/sections/admin/cleaning/units_due.svg";
import UnitsCleaned from "../../../../assets/sections/admin/cleaning/units_cleaned.svg";
import AverageCleaningFee from "../../../../assets/sections/admin/cleaning/avg_cost.svg";

import StatsBoard from "../../../common/stats_board/StatsBoard";
import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils/generalFunctions";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { useDispatch, useSelector } from "react-redux";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce } from "lodash";

const Cleaning = () => {
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const statePageStats = useSelector((state) => state.pageStats);
  const scroll = useSelector((state) => state.pageScroll);
  const [cleaningTableData, setCleaningTableData] = useState([]);
  const stateDataTableData = useSelector((state) => state.getTableData);
  const initialRender = useRef(true);
  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };

  // cleaning Stats Board
  const stats_icons = [UnitsDue, UnitsCleaned, AverageCleaningFee];
  const stats_names = [
    "Units Due This Week",
    "Units Cleaned This Week",
    "Average Cleaning Fee",
  ];

  const cleaning_stats_mapping = [
    "unitsDueThisWeek",
    "unitsCleanedThisWeek",
    "averageCleaningFee",
  ];

  // cleaning Stats Table
  const cleaning_table_headers = [
    "Unit Name",
    "Unit Address",
    "Next Cleaning Date",
    "Action",
  ];

  const cleaning_tableFieldMapping = [
    "unitName",
    "unitAddress",
    "nextCleaningDate",
    "action",
  ];

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData.unitName = item.unitName;
      mappedData.unitAddress = item.unitAddress;
      mappedData.nextCleaningDate = formatDate(item.nextCleaningDate);
      mappedData.action = (
        <ActionButton
          buttonText={"More Details"}
          onClick={() => {
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            navigate(item._id + "______" + item.unitName);
          }}
        ></ActionButton>
      );

      mappedDataList.push(mappedData);
    });

    setCleaningTableData(mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANING",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__CLEANING__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANING",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANING",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANING",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANING",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__CLEANING__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminCleaning?.length >= 0)
      processTableData(stateDataTableData.adminCleaning);
  }, [stateDataTableData.adminCleaning]);

  return (
    <>
      <StatsBoard
        boardName="Cleaning"
        statIcons={stats_icons}
        statNames={stats_names}
        statValuesMapping={cleaning_stats_mapping}
        statValues={statePageStats?.adminCleaningStats}
      ></StatsBoard>

      <StatsTable
        tableTitle={"Cleaning"}
        tableHeaders={cleaning_table_headers}
        tableData={cleaningTableData}
        tableFieldMapping={cleaning_tableFieldMapping}
        tableActionsElement={
          <div className={styles.cleaning_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.adminCleaningTotalPages}
      ></StatsTable>
    </>
  );
};

export default Cleaning;
