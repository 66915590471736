import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useEffect, useRef, useState } from "react";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { adminAddIssue } from "../../../../utils/validators";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { getTableData } from "../../../../generalApiCalls/getTableData";

const TenantNewIssueForm = ({ setOpenFormModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newRequestForm = [
    {
      field_name: "User",
      field_id: "createBy",
      placeholder: "Created By ADMIN",
      // type: "dropdown",
      // options: [],
      type: "text",
      disabled: true,
    },
    {
      field_name: "Unit",
      field_id: "unitId",
      placeholder: "Select a Unit",
      type: "dropdown",
      options: [],
    },
    {
      field_name:
        "Write down your issue below and we will get back to you soon.",
      field_id: "content",
      placeholder:
        "Write down your issue below and we will get back to you soon.",
      type: "textarea",
    },
  ];

  const initialRender = useRef(true);
  const allAmenitiesAndUnits = async () => {
    if (initialRender.current) {
      dispatch(showLoader(true));
      initialRender.current = false;

      let allUnitsData = await getTableData({
        currentTableKey: "",
        page: 1,
        search: "",
        limit: 5000,
        apiEndpointKey: "ADMIN__CLEANING__GET_ALL_UNITS",
      });
      let finalAllUnitsData = [];

      if (allUnitsData["data"] === undefined) {
        dispatch(showLoader(false));
        return;
      }

      allUnitsData["data"]["data"]?.map((item) => {
        finalAllUnitsData.push({ id: item._id, name: item.unitName });
      });

      setFormFields((prevFields) => {
        const updatedFormFields = [...prevFields];

        const index2 = updatedFormFields.findIndex(
          (item) => item.field_id === "unitId"
        );

        updatedFormFields[index2] = {
          ...updatedFormFields[index2],
          options: finalAllUnitsData,
        };

        return updatedFormFields;
      });

      dispatch(showLoader(false));
    }
  };

  useEffect(() => {
    allAmenitiesAndUnits();
  }, []);
  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return newRequestForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newRequestData = {};
    formFields.map((item) => {
      newRequestData[item.field_id] = item.value;
    });
    delete newRequestData["createBy"];

    newRequestData = cleanFormObject(newRequestData);

    // Validate the form data
    const { error } = adminAddIssue.validate(newRequestData);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: "ADMIN__ISSUES__ADD_ISSUE",
        data: newRequestData,
      });
      if (response.success === true) {
        window.location.reload();
      }

      if (response.success) {
        setOpenFormModal(false);
        navigate("/admin/issues");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>Raise an issue</div>
          <button
            onClick={() => {
              setOpenFormModal(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <ProfileUpdateForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          formClass={styles.form_with_no_margin}
          formHandler={formHandler}
          submit={"Raise Issue"}
        />
      </form>
    </div>
  );
};

export default TenantNewIssueForm;
