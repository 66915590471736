import React, { useEffect, useState } from "react";
import styles from "./BIllInvoiceStyle.module.css";
import Logo2 from "../../../../../assets/common/LP-Recovered-vertical-white.png";
import { formatDate } from "../../../../../utils/generalFunctions";
export const BillInvoice = ({ rentDetails, tenantDetailsData }) => {
  const [totalBillAmount, setTotalBillAmount] = useState("$ 0");
  const [totalPaidAmount, setTotalPaidAmount] = useState("$ 0");
  const [totalCreditBalance, setTotalCreditBalance] = useState("$ 0");
  const [tenantDetails, setTenantDetails] = useState({});
  useEffect(() => {
    let totalBillAmount = 0;
    let totalPaidAmount = 0;
    let temTotalCreditBalance = 0;
    rentDetails?.map((rentDetail) => {
      if (rentDetail?.bill_amount) {
        totalBillAmount += parseFloat(rentDetail?.bill_amount);
      }
      if (rentDetail?.collected_amount) {
        totalPaidAmount += parseFloat(rentDetail?.collected_amount);
      }
      if (rentDetail?.balance_amount) {
        temTotalCreditBalance = parseFloat(rentDetail?.balance_amount);
      }
    });
    setTotalBillAmount("$ " + totalBillAmount);
    setTotalPaidAmount("$ " + totalPaidAmount);
    setTotalCreditBalance("$ " + temTotalCreditBalance);

    let currentTenantObj = {};
    tenantDetailsData?.map((tenantDetail) => {
      currentTenantObj[tenantDetail?.id] = tenantDetail?.value;
    });

    setTenantDetails(currentTenantObj);
  }, [rentDetails]);

  // useEffect(() => {
  //   // print below div as invoice after page load 2 sec
  //   setTimeout(() => {
  //     Print();
  //   }, 2000);
  // });

  return (
    <div
      className={styles.tenants_invoice_warapper}
      id="tenants_invoice_warapper"
    >
      <div className={styles.tenants_invoice}>
        <div className={styles.header}>
          {/* <img
            className={styles.rectangle"
            alt="Rectangle"
            src="https://c.animaapp.com/245SKHjh/img/rectangle-34625722.svg"
          />
          <img
            className={styles.img"
            alt="Rectangle"
            src="https://c.animaapp.com/245SKHjh/img/rectangle-34625723.svg"
          /> */}

          <div className={styles.rectangles}>
            <img src={Logo2} alt="Logo" />
            <div className={styles.rectangle + " " + styles.rectangle2}></div>
            <div className={styles.rectangle + " " + styles.rectangle3}>
              <div className={styles.heading_textwrapper}>
                <div className={styles.heading_title}>
                  LOYAL PARTNERS PTY LTD
                </div>
                <a
                  className={styles.heading_link}
                  href="http://www.loyalpartners.com.au/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  www.loyalpartners.com.au
                </a>
              </div>
            </div>
          </div>
          <div className={styles.mid_title_container}>
            <div className={styles.title1}>RENTAL LEDGER</div>
            {/* <div className={styles.title2}>ABN: 11605961215</div> */}
          </div>

          <div className={styles.invoice_details}>
            <div className={styles.left_details}>
              <div>Date: {formatDate(Date.now())}</div>
              <div>Tenant Name: {tenantDetails?.tenantName}</div>
              <div>
                Address:{" "}
                {tenantDetails?.unitName + ", " + tenantDetails?.unitAddress}
              </div>
              <div>
                Accommodation Type: Room sharing (Rental amount included all
                utility bills)
              </div>
            </div>
            <div className={styles.right_details}>
              <div>ABN: 11605961215</div>
              {/* <div>Pass Port No.B3456</div> */}
              {/* <div className={styles.text-wrapper-51">Payment Type: Cash, EFTPOS</div> */}
              <div>Arrival: {formatDate(tenantDetails.startDate)}</div>
              {/* <div>Departure: {formatDate(tenantDetails.endDate)}</div> */}
            </div>
          </div>
        </div>

        <center>
          <div className={styles.invoice_seperator}></div>
        </center>

        <div className={styles.invoice_body}>
          <table className={styles.billing_data_table}>
            {/* <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Payment Type</th>
                <th>Mode of Payment</th>
                <th>Charges (AUD)</th>
                <th>Balance</th>
              </tr>
            </thead> */}
            {/* <tbody> */}
            <tr>
              <th>Date</th>
              <th>Payment Type</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>
                Bill Amount <br /> (AUD)
              </th>
              <th>
                Paid Amount <br /> (AUD)
              </th>
              <th>
                Balance <br /> (AUD)
              </th>

              {/* <th>Balance</th> */}
            </tr>
            {rentDetails?.map((rentDetail, index) => (
              <tr key={index}>
                <td>{rentDetail.billing_date}</td>
                <td>{rentDetail.payment_category}</td>
                <td>{rentDetail.start_date}</td>
                <td>{rentDetail.end_date}</td>
                <td>{rentDetail.bill_amount}</td>
                <td>{rentDetail.collected_amount}</td>
                <td>{rentDetail.balance_amount}</td>
                {/* <td>{rentDetail.balance_amount}</td> */}
              </tr>
            ))}

            <tr className={styles.data_summary}>
              {/* Merge four cell */}
              <td colSpan="4">Summary of Payment</td>
              <td>{totalBillAmount}</td>
              <td>{totalPaidAmount}</td>
              <td>{totalCreditBalance}</td>
              {/* <td>{totalCreditBalance}</td> */}
            </tr>
            <tr className={styles.data_summary}>
              {/* Merge four cell */}
              <td colSpan="6">Total amount paid (Including GST)</td>
              <td>{totalPaidAmount}</td>
            </tr>
            <tr className={styles.data_summary}>
              {/* Merge four cell */}
              <td colSpan="6">Total Balance (Including GST)</td>
              <td>{totalCreditBalance}</td>
            </tr>

            {/* </tbody> */}
          </table>
        </div>

        <div className={styles.invoice_footer} id="tenants_invoice_footer">
          <center>
            <div className={styles.invoice_seperator}></div>
          </center>

          <p className={styles.footer_line1}>
            Please contact us via Email: contactus@loyalpartners.com.au | Phone:
            1800 325 107
          </p>
          <p className={styles.footer_line2}>
            Address: 43/37 Campbell Street, Parramatta, NSW 2150
          </p>
        </div>
      </div>
    </div>
  );
};
