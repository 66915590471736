import React, { useEffect, useRef, useState } from "react";
import StatsTable from "../../../common/stats_table/StatsTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";

import styles from "../../../../styles/sections/admin/employees/Employees.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";
import SearchField from "../../../common/search_field/SearchField";
import ActionButton from "../../../common/action_button/ActionButton";
import AddIcon from "../../../../assets/common/add_icon.svg";
import IconButton from "../../../common/icon_button/IconButton";
import StatsBoard from "../../../common/stats_board/StatsBoard";

import TotalEmployees from "../../../../assets/sections/admin/employees/total_employees.svg";
import SalaryPaidEmployees from "../../../../assets/sections/admin/employees/salary_paid.svg";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import { formatDate } from "../../../../utils/generalFunctions";

const Employees = () => {
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const statePageStats = useSelector((state) => state.pageStats);
  const scroll = useSelector((state) => state.pageScroll);
  const [employeesTableData, setEmployeesTableData] = useState([]);
  const stateDataTableData = useSelector((state) => state.getTableData);
  const initialRender = useRef(true);
  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };

  // Employees Stats Board
  const employees_icons = [TotalEmployees, SalaryPaidEmployees];

  const employees_details = ["Active Employees", "Total Employee Salary"];

  const employees_stats_mapping = ["activeEmployees", "totalEmployeeSalary"];

  const employees_table_headers = [
    "Sr. No.",
    "Employee Name",
    "Designation",
    "Contact Number",
    "Joining Date",
    "Salary",
    "View Profile",
  ];

  const employees_tableFieldMapping = [
    "sr_no",
    "employee_name",
    "employee_designation",
    "employee_contact",
    "joining_date",
    "employee_salary",
    "action",
  ];

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData.sr_no = index + 1;
      mappedData.employee_name = item.name;
      mappedData.employee_email = item.email;
      mappedData.employee_contact = item.mobile;
      mappedData.joining_date = formatDate(item.startDate);
      mappedData.employee_designation = item.designation;
      mappedData.employee_salary = "$ " + item.salary;
      mappedData.action = (
        <ActionButton
          buttonText={"More Details"}
          onClick={() => {
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            navigate("" + item._id);
          }}
        ></ActionButton>
      );

      mappedDataList.push(mappedData);
    });

    setEmployeesTableData(mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__EMPLOYEES__EMPLOYEES",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__EMPLOYEE__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__EMPLOYEES__EMPLOYEES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__EMPLOYEES__EMPLOYEES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__EMPLOYEES__EMPLOYEES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__EMPLOYEES__EMPLOYEES",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__EMPLOYEE__STATS, "");
    }

    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false
    ) {
      console.log("I got triggered 6");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__EMPLOYEES__EMPLOYEES",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__EMPLOYEE__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminEmployees?.length >= 0)
      processTableData(stateDataTableData.adminEmployees);
  }, [stateDataTableData.adminEmployees]);

  return (
    <>
      <StatsBoard
        boardName="Employees"
        statIcons={employees_icons}
        statNames={employees_details}
        statValuesMapping={employees_stats_mapping}
        statValues={statePageStats?.adminEmployeeStats}
      ></StatsBoard>
      <StatsTable
        tableTitle={"Employees"}
        tableHeaders={employees_table_headers}
        tableData={employeesTableData}
        tableFieldMapping={employees_tableFieldMapping}
        tableActionsElement={
          <div className={styles.employees_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <IconButton
              buttonText={"Add Employee"}
              buttonIcon={AddIcon}
              onClick={() => navigate("/admin/employees/addEmployee")}
            ></IconButton>
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>Employees</div>
              <div className={statsStyles.units_stats_table_actions}>
                <IconButton
                  buttonText={"Add Employee"}
                  buttonIcon={AddIcon}
                  onClick={() => navigate("/admin/employees/addEmployee")}
                ></IconButton>
              </div>
            </div>

            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.adminCleaningTotalPages}
      ></StatsTable>
    </>
  );
};

export default Employees;
