import React, { useState } from "react";
import styles from "../../../styles/common/search_field/SearchField.module.css";
import SearchIcon from "../../../assets/common/search.svg";

const SearchField = ({ searchText, onSearch, extraStyle }) => {
  // State to store the input value
  const [inputValue, setInputValue] = useState(searchText);

  // Implementing debouncing
  const debounce = (fn, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  };

  // Debounced version of the onSearch function
  const debouncedOnSearch = debounce(onSearch, 500); // Adjust the delay as needed

  // Handle input change with debouncing
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // Update the local state with the latest input value
    // Call the debounced function after the specified delay
    debouncedOnSearch(value);
  };

  return (
    <div className={styles.search_field + " " + extraStyle}>
      <input
        type="text"
        className={styles.search_input}
        placeholder="Search"
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className={styles.search_icon}>
        <img src={SearchIcon} alt="SearchIcon" />
      </div>
    </div>
  );
};

export default SearchField;
