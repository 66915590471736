import React, { useEffect, useState } from "react";
import styles from "../../../../styles/sections/admin/navigation_bar/NavigationBar.module.css";

import LoyalPartnersLogo from "../../../../assets/common/loyal_partners_logo.svg";
import LoyalPartnersLogoMobile from "../../../../assets/common/loyal_partners_logo_mobile.svg";
import DashboardCollapse from "../../../../assets/sections/admin/sidebar/collapse.svg";
import DashboardExpand from "../../../../assets/sections/admin/sidebar/expand.svg";

import HomeIcon from "../../../../assets/sections/tenant/sidebar/home_logo.svg";
import PaymentIcon from "../../../../assets/sections/tenant/sidebar/payments_logo.svg";
import PersonalIcon from "../../../../assets/sections/tenant/sidebar/Personal_logo.png";
import RequestAndApprovalsIcon from "../../../../assets/sections/tenant/sidebar/requestAndApprovals.svg";
import ReferralIcon from "../../../../assets/sections/tenant/sidebar/referral_logo.svg";
import IssuesIcon from "../../../../assets/sections/tenant/sidebar/issues_logo.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { updateCurrentPage } from "../../../../redux/reducers/CurrentPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";

const TenantNavigationBar = ({ state, setState }) => {
  const location = useLocation();
  const stateData = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dashboardItems = [
    { sectionName: "Home", url: "/tenant/home", sectionIcon: HomeIcon },
    {
      sectionName: "Personal Details",
      url: "/tenant/personal",
      sectionIcon: PersonalIcon,
    },
    {
      sectionName: "Payment",
      url: "/tenant/payment",
      sectionIcon: PaymentIcon,
    },
    {
      sectionName: "Request/Approvals",
      url: "/tenant/requestApprovals",
      sectionIcon: RequestAndApprovalsIcon,
    },
    {
      sectionName: "Raise an issue",
      url: "/tenant/issues",
      sectionIcon: IssuesIcon,
    },
    // {
    //   sectionName: "Referral",
    //   url: "/tenant/referral",
    //   sectionIcon: ReferralIcon,
    // },
  ];

  const [collapsed, setCollapsed] = useState(true);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    dispatch(updateCurrentPage(location.pathname));
  }, []);

  return (
    <div
      className={`${
        collapsed
          ? styles.admin_home_sidebar_collpased
          : styles.admin_home_sidebar
      } ${state ? styles.admin_home_sidebar_mobile : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {collapsed && !state ? (
        <img
          className={styles.loyal_partners_logo_icon}
          src={LoyalPartnersLogoMobile}
          alt="LoyalPartnersLogoMobile"
        ></img>
      ) : (
        <img
          className={styles.loyal_partners_logo}
          src={LoyalPartnersLogo}
          alt="LoyalPartnersLogo"
        ></img>
      )}

      {!collapsed || state ? (
        <div className={styles.admin_dashboard_wrapper}>
          {dashboardItems.map((item, index) => (
            <div
              className={
                styles.dashboard_section +
                " " +
                (stateData.activePage?.includes(item.url)
                  ? styles.dashboard_section_active
                  : "")
              }
              key={index}
              onClick={() => {
                dispatch(
                  updatePageNumber({
                    pageNo: 1,
                  })
                );
                navigate(item.url);
                dispatch(updateCurrentPage(item.url));
              }}
            >
              <img src={item.sectionIcon} alt={item.sectionIcon}></img>
              <span>{item.sectionName}</span>
            </div>
          ))}
          <div
            className={styles.dashboard_section}
            onClick={() => {
              setCollapsed(!collapsed);
              state && setState(!state);
            }}
          >
            <img src={DashboardCollapse} alt={"collapseIcon"}></img>
            <span>Collapse Sidebar</span>
          </div>
        </div>
      ) : (
        <div className={styles.admin_dashboard_wrapper_collapsed}>
          {dashboardItems.map((item, index) => (
            <div
              className={
                styles.dashboard_section +
                " " +
                (stateData.activePage?.includes(item.url)
                  ? styles.dashboard_section_active
                  : "")
              }
              key={index}
              onClick={(event) => {
                event.preventDefault();
                dispatch(
                  updatePageNumber({
                    pageNo: 1,
                  })
                );
                navigate(item.url);
                dispatch(updateCurrentPage(item.url));
              }}
            >
              <img src={item.sectionIcon} alt={item.sectionIcon}></img>
            </div>
          ))}
          <div
            className={
              styles.dashboard_section + " " + styles.navbar_collapse_button
            }
            onClick={toggleSidebar}
          >
            <img src={DashboardExpand} alt={"expandIcon"}></img>
          </div>
        </div>
      )}
    </div>
  );
};

export default TenantNavigationBar;
