import styles from "../../../../styles/sections/tenant/home/Home.module.css";
import RentStatus from "../../../../assets/sections/admin/tenant_details/rent_status.svg";
import LatestPaymentDate from "../../../../assets/sections/admin/tenant_details/latest_payment_date.svg";
import NextDueDate from "../../../../assets/sections/admin/tenant_details/next_due_date.svg";
import StayDuration from "../../../../assets/sections/admin/tenant_details/stay_duration.svg";
import EndDate from "../../../../assets/sections/admin/tenant_details/end_date.svg";
import StatsBoard from "../../../common/stats_board/StatsBoard";
import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import { useEffect, useState } from "react";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { updateTenantInfo } from "../../../../redux/reducers/UserSlicer";
import { useDispatch, useSelector } from "react-redux";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";

const Home = () => {
  const statePageStats = useSelector((state) => state.pageStats);

  const dispatch = useDispatch();

  const tenant_icons = [
    RentStatus,
    LatestPaymentDate,
    NextDueDate,
    StayDuration,
    EndDate,
  ];

  const tenant_details = [
    "Rent Status",
    "Latest Payment Date",
    "Due Date",
    "Stay Duration",
    "End Date",
  ];

  const tenants_stats_mapping = [
    "rentStatus",
    "latestPaymentDate",
    "nextDueDate",
    "stayDuration",
    "endDate",
  ];

  const [unit_details, setUnitDetails] = useState([
    {
      data_type: "Unit Name",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Unit Address",
      data_value_type: "text",
      value: "",
      id: "unitAddress",
    },
    {
      data_type: "Staying From",
      data_value_type: "date",
      value: "",
      id: "startDate",
    },
    {
      data_type: "End Date",
      data_value_type: "date",
      value: "",
      id: "endDate",
    },
  ]);

  const [co_tenants, set_co_tenants] = useState([]);

  const getCoTenantsAndUnitDetails = async () => {
    dispatch(showLoader(true));
    const response = await getSingleData({
      API_NAME: "TENANT__HOME__COTENANTS",
    });

    if (typeof response === "object") {
      set_co_tenants([]);
      response.cotenant?.map((item) => {
        set_co_tenants((prev) => {
          return [
            ...prev,
            {
              data_type: item.tenantName,
              data_value_type: "text",
              value: item.mobile,
            },
          ];
        });
      });
    }

    if (typeof response === "object") {
      unit_details.map((item) => {
        setUnitDetails((prev) => {
          return prev.map((prevItem) => {
            if (prevItem.id === item.id) {
              return {
                ...prevItem,
                value: response[item.id],
              };
            } else {
              return prevItem;
            }
          });
        });
      });
    }

    dispatch(showLoader(false));
  };

  useEffect(() => {
    // console.log("useEffect");
    getCoTenantsAndUnitDetails();
    fetchPageStats(dispatch, STATS_KEYS.ADMIN__TENANTS__STATS_INTERNAL, "");
  }, []);

  return (
    <div className={styles.home}>
      <StatsBoard
        boardName=""
        statIcons={tenant_icons}
        statNames={tenant_details}
        statValues={statePageStats?.adminTenantStatsInternal}
        statValuesMapping={tenants_stats_mapping}
      ></StatsBoard>
      <div className={styles.table}>
        <CustomTableV2 data={unit_details} header="Unit Details" />
        <CustomTableV2 data={co_tenants} header="Co-Tenants" />
      </div>
    </div>
  );
};

export default Home;
