import React, { useEffect, useRef, useState } from "react";

import styles from "../../../../styles/sections/admin/dashboard/Dashboard.module.css";
import StatsBoard from "../../../common/stats_board/StatsBoard";

import TotalUnits from "../../../../assets/sections/admin/dashboard/total_units.svg";
import VacantUnits from "../../../../assets/sections/admin/dashboard/vacant_units.svg";
import AvailableBeds from "../../../../assets/sections/admin/dashboard/available_beds.svg";
import TotalTenants from "../../../../assets/sections/admin/dashboard/total_tenants.svg";
import OnNotice from "../../../../assets/sections/admin/dashboard/on_notice.svg";
import UpcomingTenants from "../../../../assets/sections/admin/dashboard/upcoming_tenants.svg";
import StatsTable from "../../../common/stats_table/StatsTable";
import EarningGraph from "./EarningGraph";
import { useDispatch, useSelector } from "react-redux";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { formatDate } from "../../../../utils/generalFunctions";
import { useNavigate } from "react-router-dom";
import { updateCurrentPage } from "../../../../redux/reducers/CurrentPageSlice";

const Dashboard = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const statePageStats = useSelector((state) => state.pageStats);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const [tenantsTableData, setTenantsTableData] = useState([]);
  const scroll = useSelector((state) => state.pageScroll);
  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialRender = useRef(true);

  // Units Stats Board
  const units_icons = [TotalUnits, VacantUnits, AvailableBeds];
  const units_names = ["Total Units", "Vacant Units", "Available Beds"];
  const dashboard_units_stats_mapping = [
    "totalUnits",
    "vacantUnits",
    "availableBeds",
  ];

  // Tenants Stats Board
  const tenants_icons = [TotalTenants, OnNotice, UpcomingTenants];
  const tenants_names = ["Active Tenants", "On Notice", "Upcoming Tenants"];
  const dashboard_tenants_stats_mapping = [
    "totalTenants",
    "onNotice",
    "upcomingTenants",
  ];

  // Stats Table
  const tenants_statsTable_headers = [
    "Tenant Name",
    "Unit",
    "Contact",
    "Joining date",
    "Rent",
    "Rent status",
  ];

  const tenants_tableFieldMapping = [
    "tenant_name",
    "unit",
    "contact",
    "joining_date",
    "rent",
    "rent_status",
  ];

  // Sort Options
  const sortOptions = ["Joining Date", "Rent"];

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData.tenant_name = item.tenantName;
      mappedData.unit = item.unitName;
      mappedData.contact = item.mobile;
      mappedData.joining_date = formatDate(item.startDate);
      mappedData.rent = "$ " + item.weeklyRent;
      // TODO: Change rent_status to Paid/Pending
      mappedData.rent_status = (
        <p
          className={
            item.rentStatus === "Paid"
              ? styles.rentStatusPaid
              : styles.rentStatusPending
          }
        >
          {item.rentStatus}
        </p>
      );

      mappedDataList.push(mappedData);
    });

    setTenantsTableData(mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: "Due" }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN_STATS_DASHBOARD, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: "Due" }
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: "Due" }
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText,
        { rentStatus: "Due" }
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__TENANTS__TENANTS",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__TENANTS__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminTenants?.length >= 0)
      processTableData(stateDataTableData.adminTenants);
  }, [stateDataTableData.adminTenants]);

  return (
    <div className={styles.admin_dashboard_content}>
      <div className={styles.admin_dashboard_content_row}>
        <EarningGraph />
        <div className={styles.admin_dashboard_content_stats_column}>
          <StatsBoard
            boardName="Units"
            statIcons={units_icons}
            statNames={units_names}
            statValuesMapping={dashboard_units_stats_mapping}
            statValues={statePageStats?.adminDashboardStats?.units}
            onClickRedirectPath="/admin/units"
          ></StatsBoard>
          <StatsBoard
            boardName="Tenants"
            statIcons={tenants_icons}
            statNames={tenants_names}
            statValuesMapping={dashboard_tenants_stats_mapping}
            statValues={statePageStats?.adminDashboardStats?.tenants}
            onClickRedirectPath="/admin/tenants"
          ></StatsBoard>
        </div>
      </div>
      <StatsTable
        tableTitle={"Upcoming Dues"}
        tableHeaders={tenants_statsTable_headers}
        tableData={tenantsTableData}
        tableFieldMapping={tenants_tableFieldMapping}
        tableActionsElement={
          <div className={styles.stats_table_header}>
            {/* <Dropdown
              label="Sort"
              options={sortOptions}
              onChange={handleFilterChange}
            /> */}
            <span
              onClick={() => {
                dispatch(resetTableData());
                dispatch(
                  updatePageNumber({
                    pageNo: 1,
                  })
                );
                dispatch(updateCurrentPage("/admin/tenants"));
                navigate("/admin/tenants");
              }}
            >
              View All
            </span>
          </div>
        }
        totalPages={stateDataTotalPages.adminTenantsTotalPages}
      ></StatsTable>
    </div>
  );
};

export default Dashboard;
