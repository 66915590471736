import React from "react";
import styles from "../../../styles/common/action_button/ActionButton.module.css";

const ActionButton = ({ buttonText, onClick, extraClass = "" }) => {
  return (
    <div className={styles.action_button + " " + extraClass} onClick={onClick}>
      {buttonText}
    </div>
  );
};

export default ActionButton;
