import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useEffect, useState } from "react";
import FormDropDown from "../../../common/form_dropdown/FormDropDown";
import ActionButton from "../../../common/action_button/ActionButton";
import AddIcon from "../../../../assets/common/add_icon.svg";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { addInventorySchema } from "../../../../utils/validators";
import { useNavigate } from "react-router-dom";

const AddInventoryForm = ({
  //   form_fields,
  currentInventoryId = 0,
  currentInventoryData = {},
  setOpenFormModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addInventoryrecord = [
    {
      field_name: "Name",
      field_id: "name",
      placeholder: "Enter Name",
      type: "text",
    },
    {
      field_name: "Quantity",
      field_id: "quantity",
      placeholder: "Enter Quantity",
      type: "number",
    },
    // {
    //   field_name: "Rent Amount (Weekly)",
    //   field_id: "rentAmount",
    //   placeholder: "Enter Rent Amount",
    //   type: "number",
    // },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return addInventoryrecord.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };
  const [formFields, setFormFields] = useState(initializeFormState());
  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newCarParking = {};
    formFields.map((item) => {
      newCarParking[item.field_id] = item.value;
    });

    newCarParking = cleanFormObject(newCarParking);

    // Validate the form data
    const { error } = addInventorySchema.validate(newCarParking);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // upadte
      if (currentInventoryId !== 0) {
        response = await postFormData({
          form_key: "ADMIN__AMENITIES__UPDATE_AMENITY",
          data: newCarParking,
          urlId: currentInventoryId,
          method: "put",
        });
        if (response.success === true) {
          window.location.reload();
        }
      } else {
        // add
        response = await postFormData({
          form_key: "ADMIN__AMENITIES__ADD_AMENITY",
          data: newCarParking,
        });
        if (response.success === true) {
          window.location.reload();
        }
      }

      if (response.success) {
        setOpenFormModal(false);
        navigate("/admin/amenities");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const [options, setOptions] = useState([]);
  const [image, setImage] = useState([]);

  const handleSelect = (id, option) => {
    handleFieldChange(id, option, false, "");
    setOptions([...options, { [`${id}`]: option }]);
  };

  useEffect(() => {
    if (currentInventoryId === 0) {
      setFormFields(initializeFormState());
    } else {
      Object.keys(currentInventoryData).map((item) => {
        handleFieldChange(item, currentInventoryData[item], false, "");
      });
    }
  }, [currentInventoryId]);

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>
            {currentInventoryId === 0 ? "Add" : "Edit"} Inventory
          </div>
          <button
            onClick={() => {
              setOpenFormModal(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <div className={styles.form__components}>
          {formFields.map((item, index) => {
            return (
              <div className={styles.input_wrap} key={index}>
                <label className={styles.input_name}>{item.field_name}</label>
                {(item.type === "text" ||
                  item.type === "number" ||
                  item.type === "date" ||
                  item.type === "email" ||
                  item.type === "address") && (
                  <input
                    className={
                      `${styles.input_field}  ${styles.field}` +
                      " " +
                      (item.error ? styles.field_error : "")
                    }
                    type={item.type}
                    // onChange={(event) => {
                    //   item.value = event.target.value;
                    // }}
                    onChange={(event) => {
                      handleFieldChange(
                        item.field_id,
                        event.target.value,
                        false,
                        ""
                      );
                    }}
                    placeholder={item.placeholder}
                    value={item.value}
                  />
                )}
                {item.type === "button" && (
                  <input
                    type="file"
                    onChange={({ target: { files } }) => {
                      // files[0] && setFileName(files[0].name);
                      if (files) {
                        setImage([...image, files[0]]);
                      }
                    }}
                    className={`${styles.upload_button}  ${styles.field}`}
                  />
                )}
                {item.type === "dropdown" && (
                  <FormDropDown
                    options={item.options}
                    name={item.field_name}
                    id={item.field_id}
                    selectHandler={handleSelect}
                  />
                )}
                {item.error && (
                  <p className={styles.error_message}>{item.error_message}</p>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <ActionButton
            buttonText={
              (currentInventoryId === 0 ? "Add" : "Update") + " Inventory"
            }
            icon={AddIcon}
            onClick={formHandler}
          />
        </div>
      </form>
    </div>
  );
};

export default AddInventoryForm;
