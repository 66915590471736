import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useState } from "react";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { updateIssue } from "../../../../utils/validators";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";

const UpdateIssueForm = ({ setOpenFormModal, activeIssue }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateRequestForm = [
    {
      field_name: "Please select the correct issue status",
      field_id: "status",
      placeholder: "",
      type: "dropdown",
      options: [
        { id: "open", name: "Open" },
        { id: "in_progress", name: "In Progress" },
        { id: "on_hold", name: "On Hold" },
        { id: "resolved", name: "Resolved" },
      ],
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return updateRequestForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newRequestData = {};
    formFields.map((item) => {
      newRequestData[item.field_id] = item.value;
    });

    newRequestData = cleanFormObject(newRequestData);

    // Validate the form data
    const { error } = updateIssue.validate(newRequestData);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: "ADMIN__ISSUES__UPDATE_ISSUE",
        data: newRequestData,
        method: "PUT",
        urlId: activeIssue,
      });
      if (response.success === true) {
        window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>Update the Issue</div>
          <button
            onClick={() => {
              setOpenFormModal(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <ProfileUpdateForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          formClass={styles.centre_form}
          formHandler={formHandler}
          submit={"Update Issue"}
        />
      </form>
    </div>
  );
};

export default UpdateIssueForm;
