import styles from "../../../../styles/sections/tenant/payments/Payments.module.css";
import { useEffect, useRef, useState } from "react";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import StatsTable from "../../../common/stats_table/StatsTable";
import NavBar from "../../../common/nav_bar/NavBar";
import {
  cleanFormObject,
  formatDate,
  printCurrentPage,
} from "../../../../utils/generalFunctions";
import { paymentValidationSchema } from "../../../../utils/validators";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import ActionButton from "../../../common/action_button/ActionButton";
import { PaymentInvoice } from "../../admin/tenants/PaymentInvoice/PaymentInvoice";
import { createPortal } from "react-dom";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { BillInvoice } from "../../admin/tenants/PaymentInvoice/BillInvoice";

const Payments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const tenantData = useSelector((state) => state.userInfo.tenantInfo);
  const [tenantDetailsData, setTenantDetailsData] = useState([]);

  useEffect(() => {
    let tenantDetails = [];
    // console.log("tenantData", tenantData);
    Object.keys(tenantData)?.map((item) => {
      tenantDetails.push({
        id: item,
        value: tenantData[item],
      });
    });
    // console.log(tenantDetails);
    setTenantDetailsData(tenantDetails);
  }, [tenantData]);

  const [section, setSection] = useState("Payment History");

  const payment_statsTable_headers = [
    "Sr No.",
    "Payment Date",
    "Payment Category",
    "Payment Mode",
    "Paid Amount",
    // "Balance Amount",
    "Description",
  ];

  const payment_tableFieldMapping = [
    "sr_no",
    "payment_date",
    "payment_category",
    "payment_mode",
    "paid_amount",
    // "balance_amount",
    "description",
  ];

  const billing_statsTable_headers = [
    "Sr No.",
    "Payment Category",
    "Billing Date",
    "Start Date",
    "End Date",
    "Bill Amount",
    "Paid Amount",
    "Balance Amount",
    // "Payment Mode",
    // "Description",
  ];

  const billing_tableFieldMapping = [
    "sr_no",
    "payment_category",
    "billing_date",
    "start_date",
    "end_date",
    "bill_amount",
    "collected_amount",
    "balance_amount",
  ];

  let sharePaymentForm = [
    {
      field_name: "Payment Category",
      field_id: "paymentCategory",
      placeholder: "Enter Payment Category",
      type: "dropdown",
      options: [
        { id: "Rent", name: "Rent" },
        { id: "Deposit", name: "Deposit" },
        { id: "Other", name: "Other" },
      ],
    },
    {
      field_name: "Payment Mode",
      field_id: "paymentMode",
      placeholder: "Enter Payment Mode",
      type: "dropdown",
      // options: ["CREDIT CARD", "CASH", "NET BANKING"],
      options: [
        { id: "CREDIT CARD", name: "CREDIT CARD" },
        { id: "CASH", name: "CASH" },
        { id: "NET BANKING", name: "NET BANKING" },
      ],
    },
    {
      field_name: "Payment Amount",
      field_id: "paymentAmount",
      placeholder: "Enter Payment Amount",
      type: "number",
    },
    {
      field_name: "Payment Date",
      field_id: "actualPaymentDate",
      placeholder: "Enter Payment Date",
      type: "date",
    },
    {
      field_name: "Note",
      field_id: "description",
      placeholder: "Enter Description",
      type: "text",
    },
    {
      field_name: "Receipt Copy",
      field_id: "asset",
      placeholder: "Upload Receipt Copy",
      type: "file",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return sharePaymentForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newPayment = {};
    formFields.map((item) => {
      newPayment[item.field_id] = item.value;
    });

    newPayment = cleanFormObject(newPayment);

    // Validate the form data
    const { error } = paymentValidationSchema.validate(newPayment);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: "TENANT_SHARE_PAYMENT_DETAILS",
        data: newPayment,
        isFile: true,
      });

      if (response.success) {
        // navigate("/tenant/payment");
        // alert(response?.message);
        alert(
          "Payment Details Added Successfully, Please wait for admin approval"
        );
        window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const navSections = [
    "Payment History",
    "Billing History",
    "Share Payment Details",
  ];

  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [billingHistoryData, setBillingHistoryData] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const initialRender = useRef(true);
  const stateDataTableData = useSelector((state) => state.getTableData);

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      // mappedData.unitName = item.unitName;
      // mappedData.unitAddress = item.unitAddress;
      // mappedData.nextCleaningDate = formatDate(item.nextCleaningDate);

      mappedData.sr_no = index + 1;
      mappedData.payment_date = formatDate(item.actualPaymentDate);
      mappedData.payment_category = item.paymentCategory;
      mappedData.payment_mode = item.paymentMode;
      mappedData.paid_amount = item.paymentAmount;
      // mappedData.balance_amount = item.balance;
      mappedData.description = item.description;

      mappedDataList.push(mappedData);
    });

    setPaymentHistoryData(mappedDataList);

    const responseBilling = await getSingleData({
      API_NAME: "TENANT__GET_TENANT_BILLING_HISTORY",
      additionData: {
        limit: 5000,
      },
    });

    setBillingHistoryData(
      responseBilling.map((item, index) => {
        return {
          sr_no: index + 1,
          payment_category: item.paymentCategory,
          billing_date: formatDate(item.billingDate),
          start_date: formatDate(item.periodStartDate),
          end_date: formatDate(item.periodEndDate),
          bill_amount: item.billAmount,
          collected_amount: item.collectedAmount,
          balance_amount: item.balanceAmount,
        };
      })
    );
  };

  useEffect(() => {
    // if seachText is not same as previous searchText then reset the pageNumber
    if (searchText !== "") {
      setPageNumber(1);
    }

    if (initialRender.current) {
      initialRender.current = false;
      dispatch(resetTableData());
    }
    fetchTableData(
      dispatch,
      "TENANT__PAYMENT_HISTORY",
      pageNumber,
      searchText,
      {
        limit: 5000,
      }
    );
  }, [pageNumber, searchText]);

  useEffect(() => {
    if (stateDataTableData.tenantPaymentHistory?.length > 0)
      processTableData(stateDataTableData.tenantPaymentHistory);
  }, [stateDataTableData.tenantPaymentHistory]);

  // useEffect(() => {
  //   handleGetMoreDetails();
  // }, []);

  const [printStart, setPrintStart] = useState(false);

  return (
    <div className={styles.payments}>
      <div className={styles.payment_content}>
        <NavBar state={setSection} active={section} navLinks={navSections} />
        {section === "Share Payment Details" && (
          <ProfileUpdateForm
            form_fields={formFields}
            handleFieldChange={handleFieldChange}
            submit="Save Details"
            formHandler={formHandler}
            formClass={styles.centre_form}
          />
        )}

        {section === "Payment History" && (
          <>
            <div className={styles.paymnetTableAction}>
              <p className={styles.note}>
                Note : The payments made by you will be reflected here only
                after the admin approves it.
              </p>
              <ActionButton
                buttonText={"Download Ledger"}
                onClick={() => {
                  // print
                  printCurrentPage(
                    setPrintStart,
                    tenantDetailsData[0]?.value + " Rental Ledger"
                  );
                }}
              ></ActionButton>
            </div>
            <StatsTable
              tableTitle={""}
              tableHeaders={payment_statsTable_headers}
              tableData={paymentHistoryData}
              tableFieldMapping={payment_tableFieldMapping}
              totalPages={stateDataTotalPages.tenantPaymentHistoryTotalPages}
              removePadding={true}
            ></StatsTable>
          </>
        )}
        {section === "Billing History" && (
          <>
            <div className={styles.paymnetTableAction}>
              <p className={styles.note}>
                Note : The payments made by you will be reflected here only
                after the admin approves it.
              </p>
              <ActionButton
                buttonText={"Download Billing Ledger"}
                onClick={() => {
                  // print
                  printCurrentPage(
                    setPrintStart,
                    tenantDetailsData[0]?.value + " Rental Ledger"
                  );
                }}
              ></ActionButton>
            </div>
            <StatsTable
              tableTitle={""}
              tableHeaders={billing_statsTable_headers}
              tableData={billingHistoryData}
              tableFieldMapping={billing_tableFieldMapping}
              totalPages={stateDataTotalPages.tenantBillingHistoryTotalPages}
              removePadding={true}
            ></StatsTable>
          </>
        )}
        {/* {printStart && (
          <PaymentInvoice
            rentDetails={paymentHistoryData}
            tenantDetailsData={tenantDetailsData}
          />
        )} */}
        {printStart &&
          createPortal(
            <>
              {" "}
              {section === "Payment History" ? (
                <PaymentInvoice
                  rentDetails={paymentHistoryData}
                  tenantDetailsData={tenantDetailsData}
                />
              ) : (
                <BillInvoice
                  rentDetails={billingHistoryData}
                  tenantDetailsData={tenantDetailsData}
                />
              )}
            </>,
            document.body
          )}
      </div>
    </div>
  );
};

export default Payments;
