import styles from "../../../../styles/sections/admin/tenants/TenantDetails.module.css";
import { useEffect, useState } from "react";

import RentStatus from "../../../../assets/sections/admin/tenant_details/rent_status.svg";
import LatestPaymentDate from "../../../../assets/sections/admin/tenant_details/latest_payment_date.svg";
import NextDueDate from "../../../../assets/sections/admin/tenant_details/next_due_date.svg";
import StayDuration from "../../../../assets/sections/admin/tenant_details/stay_duration.svg";
import EndDate from "../../../../assets/sections/admin/tenant_details/end_date.svg";
import StatsBoard from "../../../common/stats_board/StatsBoard";
import NavBar from "../../../common/nav_bar/NavBar";
import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import StatsTable from "../../../common/stats_table/StatsTable";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import EditLogo from "../../../../assets/common/edit.svg";
import DeleteLogo from "../../../../assets/common/delete_red.svg";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { useDispatch, useSelector } from "react-redux";
import {
  printCurrentPage,
  formatDate,
} from "../../../../utils/generalFunctions";
import FileViewer from "../../../common/image_verification/FileViewer";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS, USER_TYPE } from "../../../../utils/constants";
import ActionButton from "../../../common/action_button/ActionButton";
import SendContractForm from "./SendContractForm";
import AddPaymentRecordForm from "./AddPaymentRecordForm";
import { PaymentInvoice } from "./PaymentInvoice/PaymentInvoice";
import { createPortal } from "react-dom";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import ActionForm from "./ActionForm";

import { BillInvoice } from "./PaymentInvoice/BillInvoice";

const TenantDetails = () => {
  const statePageStats = useSelector((state) => state.pageStats);
  const stateDataTotalPages = useSelector((state) => state.totalPages);

  const navSections = [
    "Tenant Details",
    "Rent Revise History",
    "Unit Stay History",
    "Payment History",
    "Billing History",
    // "Txn History",
  ];
  const [sendContractForm, setSendContractForm] = useState(false);
  const [addPaymentRecordForm, setAddPaymentRecordForm] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(0);

  const navigate = useNavigate();
  const tenantId = useParams().tenantId;

  const [section, setSection] = useState("Tenant Details");
  const dispatch = useDispatch();
  // const [onboarded, setOnboarded] = useState(true);

  //stats board data

  const tenant_icons = [
    RentStatus,
    LatestPaymentDate,
    NextDueDate,
    StayDuration,
    EndDate,
  ];

  const tenant_details = [
    "Rent Status",
    "Latest Payment Date",
    "Due Date",
    "Stay Duration",
    "End Date",
  ];

  const tenants_stats_mapping = [
    "rentStatus",
    "latestPaymentDate",
    "nextDueDate",
    "stayDuration",
    "endDate",
  ];

  const [tenantDetails, setTenantDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "tenantName",
    },
    {
      data_type: "Is Active",
      data_value_type: "boolean",
      value: "",
      id: "isActive",
    },
    {
      data_type: "Unit Name",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Unit Address",
      data_value_type: "text",
      value: "",
      id: "unitAddress",
    },
    {
      data_type: "Email",
      data_value_type: "email",
      value: "",
      id: "email",
    },
    {
      data_type: "Mobile",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
    {
      data_type: "Whatsapp",
      data_value_type: "number",
      value: "",
      id: "whatsapp",
    },
    {
      data_type: "Emergency Contact",
      data_value_type: "number",
      value: "",
      id: "emergencyContact",
    },
    {
      data_type: "India Contact Number",
      data_value_type: "number",
      value: "",
      id: "indianContactNumber",
    },
    {
      data_type: "Rent Amount (Weekly)",
      data_value_type: "currency",
      value: "",
      id: "weeklyRent",
    },
    {
      data_type: "Deposit Amount",
      data_value_type: "currency",
      value: "",
      id: "bondAmount",
    },
    {
      data_type: "Increment Percentage",
      data_value_type: "text",
      value: "",
      id: "incrementPercent",
    },
    {
      data_type: "Start Date",
      data_value_type: "date",
      value: "",
      id: "startDate",
    },
    {
      data_type: "End Date",
      data_value_type: "date",
      value: "",
      id: "endDate",
    },
    {
      data_type: "Tentative Duration (Months)",
      data_value_type: "text",
      value: "",
      id: "tentativeStayDuration",
    },

    {
      data_type: "Payment Mode",
      data_value_type: "text",
      value: "",
      id: "paymentMode",
    },

    {
      data_type: "Company",
      data_value_type: "text",
      value: "",
      id: "companyName",
    },
    {
      data_type: "Country",
      data_value_type: "text",
      value: "",
      id: "country",
    },
    {
      data_type: "State",
      data_value_type: "text",
      value: "",
      id: "state",
    },

    {
      data_type: "Visa Type",
      data_value_type: "text",
      value: "",
      id: "visaType",
    },
    {
      data_type: "Visa Validity (Months)",
      data_value_type: "text",
      value: "",
      id: "visaValidity",
    },
    {
      data_type: "Bank Name",
      data_value_type: "text",
      value: "",
      id: "bankName",
    },

    {
      data_type: "Bank Account No.",
      data_value_type: "text",
      value: "",
      id: "bankAccountNo",
    },
    {
      data_type: "BSB Code",
      data_value_type: "text",
      value: "",
      id: "ifscCode",
    },
    {
      data_type: "Permanent Address",
      data_value_type: "text",
      value: "",
      id: "permanentAddress",
    },
  ]);

  //payment history

  const payments_statsTable_headers = [
    "Sr No.",
    "Payment Category",
    "Payment Date",
    "Paid Amount",
    // "Balance Amount",
    "Payment Mode",
    "Description",
    "Added By",
    "Action",
  ];

  const payments_tableFieldMapping = [
    "sr_no",
    "payment_category",
    "payment_date",
    "paid_amount",
    // "balance_amount",
    "payment_mode",
    "description",
    "added_by",
    "action",
  ];

  const billing_statsTable_headers = [
    "Sr No.",
    "Payment Category",
    "Billing Date",
    "Start Date",
    "End Date",
    "Bill Amount",
    "Paid Amount",
    "Balance Amount",
    // "Payment Mode",
    // "Description",
  ];

  const billing_tableFieldMapping = [
    "sr_no",
    "payment_category",
    "billing_date",
    "start_date",
    "end_date",
    "bill_amount",
    "collected_amount",
    "balance_amount",
  ];

  // const txnHistory_statsTable_headers = [
  //   "Sr No.",
  //   "Date",
  //   "Txn Type",
  //   "Start Date",
  //   "End Date",
  //   "Payment Mode",
  //   "Amount",
  //   "Balance",
  // ];

  // const txnHistory_tableFieldMapping = [
  //   "sr_no",
  //   "date",
  //   "type",
  //   "billStartDate",
  //   "billEndDate",
  //   "paymentMode",
  //   "amount",
  //   "balance",
  // ];

  const [unitStayHistory, setUnitStayHistory] = useState([]);
  const unitStayHistoryStatsTable_headers = [
    "Sr No.",
    "Unit Name",
    "Start Date",
    "End Date",
    "Stay Duration",
    // "Action",
  ];
  const unitStayHistoryTableFieldMapping = [
    "sr_no",
    "unitName",
    "startDate",
    "endDate",
    "stayDuration",
    // "action",
  ];

  const [rentRevisionHistory, setRentRevisionHistory] = useState([]);
  const rentRevisionHistoryStatsTable_headers = [
    "Sr No.",
    "Effective Date",
    "Rent Amount",
    "Description",
    // "Action",
  ];
  const rentRevisionHistoryTableFieldMapping = [
    "sr_no",
    "effectiveDate",
    "rentPerWeek",
    "description",
    // "action",
  ];

  const [futureRentRevisionHistory, setFutureRentRevisionHistory] = useState(
    []
  );
  const futureRentRevisionHistoryStatsTable_headers = [
    "Sr No.",
    "Effective Date",
    "Rent Amount",
    "Description",
    "Action",
  ];
  const futureRentRevisionHistoryTableFieldMapping = [
    "sr_no",
    "effectiveDate",
    "rentPerWeek",
    "description",
    "action",
  ];

  const [futureUnitStayHistory, setFutureUnitStayHistory] = useState([]);
  const futureUnitStayHistoryStatsTable_headers = [
    "Sr No.",
    "Unit Name",
    "Effective Date",
    "Action",
  ];
  const futureUnitStayHistoryTableFieldMapping = [
    "sr_no",
    "unitName",
    "effectiveDate",
    "action",
  ];

  const [documents, setDocuments] = useState([
    {
      field_name: "Passport Photo",
      field_id: "passportUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
    {
      field_name: "Drivers Liscence",
      field_id: "identificationDocUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
    {
      field_name: "Original Contract",
      field_id: "contractUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
    {
      field_name: "Signed Contract",
      field_id: "signedContractUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
  ]);

  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [billingHistoryData, setBillingHistoryData] = useState([]);

  const deletePaymentRecord = async (paymentId) => {
    dispatch(showLoader(true));

    // confirm from user before deleting
    if (!window.confirm("Are you sure you want to delete this record?")) {
      dispatch(showLoader(false));
      return;
    }

    const response = await postFormData({
      form_key: "ADMIN__TENANTS__DELETE_PAYMNET_RECORD",
      urlId: paymentId,
      data: {},
      method: "delete",
    });
    if (response.success) {
      window.location.reload();
    } else {
      alert(response.message);
    }
    dispatch(showLoader(false));
  };

  const deleteUnitStayRecord = async (data) => {
    //  let { tenantId } = req.params;
    // let { unitId, startDate, endDate, futureRecord, recordId } = req.body;

    try {
      dispatch(showLoader(true));
      const response = await postFormData({
        form_key: "ADMIN__TENANTS__DELETE__ACTIVATE_DEACTIVATE",
        urlId: tenantId,
        data: data,
        method: "delete",
      });

      if (response.success) {
        window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    } catch (err) {
      dispatch(showLoader(false));
      console.log(err);
    }
  };

  const deleteRentRevisionRecord = async (data) => {
    //  let { tenantId } = req.params;
    //   let { date, rentPerWeek } = req.body;
    try {
      dispatch(showLoader(true));
      const response = await postFormData({
        form_key: "ADMIN__TENANTS__DELETE_REVISED_RENT",
        urlId: tenantId,
        data: data,
        method: "delete",
      });

      if (response.success) {
        window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    } catch (err) {
      dispatch(showLoader(false));
      console.log(err);
    }
  };

  const handleGetMoreDetails = async (tenantId) => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "ADMIN__TENANTS__GET_TENANT_DETAILS",
      oneId: tenantId,
    });

    // setOnboarded(response?.onboarded);

    setTenantDetails((prev) => {
      return prev?.map((item) => {
        if (item.id in response) {
          if (item.data_value_type === "boolean") {
            return { ...item, value: response[item.id] ? "Yes" : "No" };
          }
          return { ...item, value: response[item.id] };
        } else {
          return item;
        }
      });
    });

    // fetching documents details
    documents?.map(async (item) => {
      if (item.field_id in response) {
        setDocuments((prev) => {
          return prev?.map((item) => {
            if (item.field_id in response) {
              return {
                ...item,
                fileName: response[item.field_id].originalName,
                fileId: response[item.field_id].assetId,
              };
            } else {
              return item;
            }
          });
        });
      }
    });

    const responsePayment = await getSingleData({
      API_NAME: "ADMIN__TENANTS__GET_TENANT_PAYMENT_HISTORY",
      oneId: tenantId,
      additionData: {
        limit: 5000,
      },
    });

    const responseBilling = await getSingleData({
      API_NAME: "ADMIN__TENANTS__GET_TENANT_BILLING_HISTORY",
      oneId: tenantId,
      additionData: {
        limit: 5000,
      },
    });

    setPaymentHistoryData(
      responsePayment?.map((item, index) => {
        return {
          sr_no: index + 1,
          payment_category: item.paymentCategory,
          payment_date: formatDate(item.actualPaymentDate),
          paid_amount: item.paymentAmount,
          balance_amount: item.creditBalance,
          added_by: item?.isDirectEntry ? "Admin" : "Tenant",
          payment_mode: item.paymentMode,
          description: item.description,
          action: (
            <div className={styles.action_buttons}>
              <img
                src={EditLogo}
                alt="Edit"
                onClick={() => {
                  setAddPaymentRecordForm(true);
                  setSelectedPaymentId(item._id);
                }}
              ></img>
              <img
                src={DeleteLogo}
                alt="Delete"
                onClick={() => {
                  deletePaymentRecord(item._id);
                }}
              ></img>
            </div>
          ),
        };
      })
    );

    setBillingHistoryData(
      responseBilling?.map((item, index) => {
        return {
          sr_no: index + 1,
          payment_category: item.paymentCategory,
          billing_date: formatDate(item.billingDate),
          start_date: formatDate(item.periodStartDate),
          end_date: formatDate(item.periodEndDate),
          bill_amount: item.billAmount,
          collected_amount: item.collectedAmount,
          balance_amount: item.balanceAmount,
        };
      })
    );

    const responseUnitStayHistory = await response?.unitStayedIn;
    setUnitStayHistory(
      responseUnitStayHistory?.map((item, index) => {
        const startDate = new Date(item.date.startDate);
        const endDate = item?.date?.endDate
          ? new Date(item.date.endDate)
          : new Date();
        const stayDuration =
          Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24 * 7)) +
          " Weeks";

        return {
          sr_no: index + 1,
          unitId: item?.unit,
          unitName: item.unitName,
          startDate: formatDate(item.date.startDate),
          endDate: formatDate(item.date?.endDate) || "Present",
          // calculate stay duration in weeks, if endDate is not present, then it is present
          stayDuration: stayDuration,
          // action: (
          //   <div className={styles.action_buttons}>
          //     <img
          //       src={DeleteLogo}
          //       alt="Delete"
          //       onClick={() => {
          //         deleteUnitStayRecord({
          //           futureRecord: false,
          //           unitId: item?.unit,
          //           startDate: item?.date?.startDate,
          //           endDate: item?.date?.endDate,
          //         });
          //       }}
          //     ></img>
          //   </div>
          // ),
        };
      })
    );

    const responseFutureUnitStayHistory = await response?.futureStayedInHistory;
    setFutureUnitStayHistory(
      responseFutureUnitStayHistory?.map((item, index) => {
        return {
          sr_no: index + 1,
          unitId: item.unit,
          unitName: item.unitName,
          effectiveDate: formatDate(item?.date),
          action: (
            <div className={styles.action_buttons}>
              <img
                src={DeleteLogo}
                alt="Delete"
                onClick={() => {
                  deleteUnitStayRecord({
                    futureRecord: true,
                    recordId: item._id,
                  });
                }}
              ></img>
            </div>
          ),
        };
      })
    );

    const responseRentRevisionHistory = await response?.rentalHistory;
    setRentRevisionHistory(
      responseRentRevisionHistory?.map((item, index) => {
        return {
          sr_no: index + 1,
          effectiveDate: formatDate(item.date),
          rentPerWeek: item.rentPerWeek,
          description: item.description,
          // action: (
          //   <div className={styles.action_buttons}>
          //     <img
          //       src={DeleteLogo}
          //       alt="Delete"
          //       onClick={() => {
          //         deleteRentRevisionRecord({
          //           date: item.date,
          //           rentPerWeek: item.rentPerWeek,
          //         });
          //       }}
          //     ></img>
          //   </div>
          // ),
        };
      })
    );

    const responseFutureRentRevisionHistory =
      await response?.futureRentRevisions;
    setFutureRentRevisionHistory(
      responseFutureRentRevisionHistory?.map((item, index) => {
        return {
          sr_no: index + 1,
          effectiveDate: formatDate(item.date),
          rentPerWeek: item.rentPerWeek,
          description: item.description,
          action: (
            <div className={styles.action_buttons}>
              <img
                src={DeleteLogo}
                alt="Delete"
                onClick={() => {
                  deleteRentRevisionRecord({
                    date: item.date,
                    rentPerWeek: item.rentPerWeek,
                  });
                }}
              ></img>
            </div>
          ),
        };
      })
    );

    dispatch(showLoader(false));
  };

  useEffect(() => {
    handleGetMoreDetails(tenantId);
    fetchPageStats(
      dispatch,
      STATS_KEYS.ADMIN__TENANTS__STATS_INTERNAL,
      tenantId
    );
  }, [tenantId]);

  const [printStart, setPrintStart] = useState(false);
  const resendOnBoardingEmail = async () => {
    dispatch(showLoader(true));

    let data = {
      userId: tenantId,
      userType: USER_TYPE.TENANT,
    };

    const response = await postFormData({
      form_key: "ADMIN__TENANTS__RESEND_ONBOARDING_EMAIL",
      data: data,
    });

    if (response?.success === true) {
      alert("Email sent successfully");
    }

    dispatch(showLoader(false));
  };

  const [openFormModal, setOpenFormModal] = useState(false);
  const [formTitle, setFormTitle] = useState("");
  const [actionButtonText, setActionButtonText] = useState("");

  return (
    <>
      <StatsBoard
        boardName="Tenants"
        statIcons={tenant_icons}
        statNames={tenant_details}
        statValuesMapping={tenants_stats_mapping}
        statValues={statePageStats?.adminTenantStatsInternal}
      ></StatsBoard>
      <div className={styles.tenant_details}>
        <div className={styles.details_header}>
          <div className={styles.detailed_section}>
            <img src={BackArrow} alt="" onClick={() => navigate(-1)}></img>{" "}
            Details
          </div>
          {/* <button className={styles.send_contract_button}>Send Contract</button> */}

          {section === "Payment History" && (
            <div className={styles.paymentButtonGroup}>
              <ActionButton
                buttonText={"Add Payment Record"}
                onClick={() => {
                  setSelectedPaymentId(0);
                  setAddPaymentRecordForm(true);
                }}
              ></ActionButton>
              <ActionButton
                buttonText={"Download Ledger"}
                onClick={() => {
                  // print
                  printCurrentPage(
                    setPrintStart,
                    tenantDetails[0].value + " Rental Ledger"
                  );
                }}
              ></ActionButton>
            </div>
          )}
          {section === "Billing History" && (
            <div className={styles.paymentButtonGroup}>
              <ActionButton
                buttonText={"Download Billing Ledger"}
                onClick={() => {
                  // print
                  printCurrentPage(
                    setPrintStart,
                    tenantDetails[0].value + " Rental Ledger"
                  );
                }}
              ></ActionButton>
            </div>
          )}
          {section === "Tenant Details" && (
            <div className={styles.paymentButtonGroup}>
              <ActionButton
                buttonText={"Send Contract"}
                onClick={() => {
                  setSendContractForm(true);
                }}
              ></ActionButton>
              <ActionButton
                buttonText={"Resend Email"}
                onClick={() => {
                  resendOnBoardingEmail();
                }}
              ></ActionButton>
            </div>
          )}
        </div>
        <NavBar state={setSection} active={section} navLinks={navSections} />
        {section === "Tenant Details" && (
          <>
            <CustomTableV2
              data={tenantDetails}
              header={
                <div className={styles.table_header}>
                  Edit Tenant Details
                  <img
                    src={EditLogo}
                    alt="Icon"
                    onClick={() => {
                      navigate("/admin/tenants/addTenant/" + tenantId);
                    }}
                  ></img>
                  <div className={styles.tenant_action_buttons}>
                    <ActionButton
                      buttonText={
                        tenantDetails[1].value === "Yes"
                          ? "Deactivate Tenant"
                          : "Activate Tenant"
                      }
                      onClick={() => {
                        // if (tenantDetails[1].value === "No") {
                        //   setFormFields(initializeFormState(activateForm));
                        // }
                        setOpenFormModal(true);
                        setFormTitle(
                          tenantDetails[1].value === "Yes"
                            ? "Deactivate Tenant"
                            : "Activate Tenant"
                        );
                        setActionButtonText(
                          tenantDetails[1].value === "Yes"
                            ? "Deactivate"
                            : "Activate"
                        );
                      }}
                    ></ActionButton>
                    {tenantDetails[1].value === "Yes" && (
                      <ActionButton
                        buttonText={"Revise Rent Amount"}
                        onClick={() => {
                          setOpenFormModal(true);
                          // setFormFields(initializeFormState(reviseForm));
                          setFormTitle("Revise Rent Amount");
                          setActionButtonText("Revise");
                        }}
                        extraClass={styles.primaryBackground}
                      ></ActionButton>
                    )}
                  </div>
                </div>
              }
            />
            {documents.map((item, index) => {
              return (
                <FileViewer
                  key={index}
                  header={item.field_name}
                  fileName={item.fileName}
                  fileId={item.fileId}
                  tenantId={tenantId}
                  state={() => {
                    console.log("clicked");
                  }}
                />
              );
            })}
          </>
        )}
        {section === "Payment History" && (
          <StatsTable
            tableTitle={""}
            tableHeaders={payments_statsTable_headers}
            tableData={paymentHistoryData}
            tableFieldMapping={payments_tableFieldMapping}
            totalPages={stateDataTotalPages.tenantPaymentHistoryTotalPages}
            removePadding={true}
          ></StatsTable>
        )}

        {section === "Billing History" && (
          <StatsTable
            tableTitle={""}
            tableHeaders={billing_statsTable_headers}
            tableData={billingHistoryData}
            tableFieldMapping={billing_tableFieldMapping}
            totalPages={stateDataTotalPages.tenantBillingHistoryTotalPages}
            removePadding={true}
          ></StatsTable>
        )}

        {section === "Unit Stay History" && (
          <>
            <StatsTable
              tableTitle={""}
              tableHeaders={unitStayHistoryStatsTable_headers}
              tableData={unitStayHistory}
              tableFieldMapping={unitStayHistoryTableFieldMapping}
              totalPages={stateDataTotalPages.tenantUnitStayHistoryTotalPages}
              removePadding={true}
              addScroll={false}
            ></StatsTable>
            {futureUnitStayHistory?.length > 0 && (
              <>
                <span>Scheduled Future Action</span>
                <StatsTable
                  tableTitle={""}
                  tableHeaders={futureUnitStayHistoryStatsTable_headers}
                  tableData={futureUnitStayHistory}
                  tableFieldMapping={futureUnitStayHistoryTableFieldMapping}
                  totalPages={
                    stateDataTotalPages.tenantFutureUnitStayHistoryTotalPages
                  }
                  removePadding={true}
                  addScroll={false}
                ></StatsTable>
              </>
            )}
          </>
        )}

        {section === "Rent Revise History" && (
          <>
            <StatsTable
              tableTitle={""}
              tableHeaders={rentRevisionHistoryStatsTable_headers}
              tableData={rentRevisionHistory}
              tableFieldMapping={rentRevisionHistoryTableFieldMapping}
              totalPages={
                stateDataTotalPages.tenantRentRevisionHistoryTotalPages
              }
              removePadding={true}
              addScroll={false}
            ></StatsTable>
            {futureRentRevisionHistory?.length > 0 && (
              <>
                <span>Scheduled Future Action</span>
                <StatsTable
                  tableTitle={""}
                  tableHeaders={futureRentRevisionHistoryStatsTable_headers}
                  tableData={futureRentRevisionHistory}
                  tableFieldMapping={futureRentRevisionHistoryTableFieldMapping}
                  totalPages={
                    stateDataTotalPages.futureRentRevisionHistoryTotalPages
                  }
                  removePadding={true}
                  addScroll={false}
                ></StatsTable>
              </>
            )}
          </>
        )}

        {sendContractForm && (
          <SendContractForm
            setSendContractForm={setSendContractForm}
            tenantId={tenantId}
          />
        )}
        {addPaymentRecordForm && (
          <AddPaymentRecordForm
            setAddPaymentRecordForm={setAddPaymentRecordForm}
            tenantId={tenantId}
            selectedPaymentId={selectedPaymentId}
            // setSelectedPaymentId={setSelectedPaymentId}
          />
        )}
        {/* {printStart && (
         {
          createportal(

          )
         }
        )} */}
        {printStart &&
          createPortal(
            <div
              id="tenants_invoice_warapper"
              className={styles.tenants_invoice_warapper}
            >
              {section === "Payment History" ? (
                <PaymentInvoice
                  rentDetails={paymentHistoryData}
                  tenantDetailsData={tenantDetails}
                />
              ) : (
                <BillInvoice
                  rentDetails={billingHistoryData}
                  tenantDetailsData={tenantDetails}
                />
              )}
            </div>,
            document.body
          )}
      </div>
      {openFormModal && (
        <ActionForm
          setOpenFormModal={setOpenFormModal}
          formTitle={formTitle}
          buttonText={actionButtonText}
          tenantId={tenantId}
        />
      )}
    </>
  );
};

export default TenantDetails;
