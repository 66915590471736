import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/cleaning/Cleaning.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import BackArrowIcon from "../../../../assets/common/back_arrow.svg";
import EditIcon from "../../../../assets/common/edit.svg";
import UnitsDue from "../../../../assets/sections/admin/cleaning/units_due.svg";
import UnitsCleaned from "../../../../assets/sections/admin/cleaning/units_cleaned.svg";
import AverageCleaningFee from "../../../../assets/sections/admin/cleaning/avg_cost.svg";

import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";
import AddIcon from "../../../../assets/common/add_icon.svg";
import IconButton from "../../../common/icon_button/IconButton";
import { useNavigate, useParams } from "react-router-dom";
import StatsBoard from "../../../common/stats_board/StatsBoard";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import {
  cleanFormObject,
  formatDate,
} from "../../../../utils/generalFunctions";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import { cleaningSchema } from "../../../../utils/validators";
import CleaningDetailsForm from "./CleaningDetailsForm";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce } from "lodash";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";

const CleaningDetails = () => {
  const currentUnitId = useParams().unitId.split("______")[0];
  const currentUnitName = useParams().unitId.split("______")[1];
  const [currentCleanerId, setCurrentCleanerId] = useState(0);

  const statePageStats = useSelector((state) => state.pageStats);
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const scroll = useSelector((state) => state.pageScroll);

  const [searchText, setSearchText] = useState("");
  const [openFormModal, setOpenFormModal] = useState(false);
  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const [cleanerTableData, setCleanerTableData] = useState([]);

  const initialRender = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );
    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };

  // cleaning Stats Board
  const stats_icons = [UnitsDue, UnitsCleaned, AverageCleaningFee];
  const stats_names = [
    "Units Due This Week",
    "Units Cleaned This Week",
    "Average Cleaning Fee",
  ];

  const cleaning_stats_mapping = [
    "unitsDueThisWeek",
    "unitsCleanedThisWeek",
    "averageCleaningFee",
  ];

  // Cleaning Stats Table
  const cleaningDetails_table_headers = [
    "Cleaner Name",
    "Cleaning Type",
    "Fee Paid",
    "Cleaning Date",
    "Edit",
  ];

  const cleaningDetails_tableFieldMapping = [
    "cleaner_name",
    "cleaning_type",
    "fee_paid",
    "cleaning_date",
    "edit",
  ];

  const addCarParking = [
    {
      field_name: "Cleaner Name",
      field_id: "cleanerName",
      placeholder: "Enter Cleaner Name",
      type: "text",
    },
    {
      field_name: "Cleaning Type",
      field_id: "cleaningType",
      placeholder: "Enter Cleaning Type",
      type: "dropdown",
      // options: ["Regular", "Deep", "Special"],
      options: [
        { id: "Regular", name: "Regular" },
        { id: "Deep", name: "Deep" },
        { id: "Special", name: "Special" },
      ],
    },
    {
      field_name: "Fee Paid",
      field_id: "fee",
      placeholder: "Enter Fee Paid",
      type: "number",
    },
    {
      field_name: "Cleaning Date",
      field_id: "cleaningDate",
      placeholder: "Enter Cleaning Date",
      type: "date",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return addCarParking.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newCleanerRecord = {};
    formFields.map((item) => {
      newCleanerRecord[item.field_id] = item.value;
    });

    newCleanerRecord = cleanFormObject(newCleanerRecord);

    // Validate the form data
    const { error } = cleaningSchema.validate(newCleanerRecord);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // upadte
      if (currentCleanerId !== 0) {
        response = await postFormData({
          form_key: "ADMIN__CLEANING__UPDATE_CLEANER",
          data: newCleanerRecord,
          urlId: currentCleanerId,
          method: "put",
        });
        if (response.success === true) {
          window.location.reload();
        }
      } else {
        // add
        newCleanerRecord.unitId = currentUnitId;
        response = await postFormData({
          form_key: "ADMIN__CLEANING__ADD_CLEANER",
          data: newCleanerRecord,
        });
        if (response.success === true) {
          window.location.reload();
        }
      }

      if (response.success) {
        setOpenFormModal(false);
        setCurrentCleanerId(0);
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData._id = item._id;
      mappedData.cleaner_name = item.cleanerName;
      mappedData.cleaning_type = item.cleaningType;
      mappedData.fee_paid = "$" + item.fee;
      mappedData.cleaning_date = formatDate(item.cleaningDate);

      mappedData.edit = (
        <IconButton
          buttonText={""}
          buttonIcon={EditIcon}
          onClick={() => {
            setCurrentCleanerId(item._id);
            setOpenFormModal(true);
          }}
          whiteBg={true}
        ></IconButton>
      );

      mappedDataList.push(mappedData);
    });

    setCleanerTableData(mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANER",
        scroll.pageNo,
        searchText,
        { unitId: currentUnitId }
      );
      // fetchPageStats(dispatch, STATS_KEYS.ADMIN__TENANTS__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANER",
        scroll.pageNo,
        searchText,
        { unitId: currentUnitId }
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANER",
        scroll.pageNo,
        searchText,
        { unitId: currentUnitId }
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANER",
        scroll.pageNo,
        searchText,
        { unitId: currentUnitId }
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CLEANING__CLEANER",
        scroll.pageNo,
        searchText,
        { unitId: currentUnitId }
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__UNITS__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.cleanCleaner?.length >= 0)
      processTableData(stateDataTableData.cleanCleaner);
  }, [stateDataTableData.cleanCleaner]);

  useEffect(() => {
    if (currentCleanerId !== 0) {
      const currentCleanerData = stateDataTableData.cleanCleaner.find(
        (item) => item._id === currentCleanerId
      );
      setFormFields((prevFields) => {
        const updatedFormFields = [...prevFields];

        updatedFormFields[0] = {
          ...updatedFormFields[0],
          value: currentCleanerData.cleanerName,
          error: false,
          error_message: "",
        };

        updatedFormFields[1] = {
          ...updatedFormFields[1],
          value: currentCleanerData.cleaningType,
          error: false,
          error_message: "",
        };

        updatedFormFields[2] = {
          ...updatedFormFields[2],
          value: currentCleanerData.fee,
          error: false,
          error_message: "",
        };

        updatedFormFields[3] = {
          ...updatedFormFields[3],
          value: formatDate(currentCleanerData.cleaningDate),
          error: false,
          error_message: "",
        };

        return updatedFormFields;
      });
    }
  }, [currentCleanerId]);

  return (
    <>
      <StatsBoard
        boardName="Cleaning"
        statIcons={stats_icons}
        statNames={stats_names}
        statValues={statePageStats?.adminCleaningStats}
        statValuesMapping={cleaning_stats_mapping}
      ></StatsBoard>
      <StatsTable
        tableTitle={
          <div className={styles.table_title_cta}>
            <img
              src={BackArrowIcon}
              alt="BackArrow"
              onClick={() => {
                navigate(-1);
              }}
            />
            <span>{currentUnitName} Cleaning</span>
          </div>
        }
        tableHeaders={cleaningDetails_table_headers}
        tableData={cleanerTableData}
        tableFieldMapping={cleaningDetails_tableFieldMapping}
        tableActionsElement={
          <div className={styles.cleaning_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <ActionButton
              buttonText="Add Entry"
              icon={AddIcon}
              onClick={() => {
                setCurrentCleanerId(0);
                setOpenFormModal(true);
              }}
            />
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>
                <div className={styles.table_title_cta}>
                  <img
                    src={BackArrowIcon}
                    alt="BackArrow"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <span>{currentUnitName} Cleaning</span>
                </div>
              </div>
              <div className={styles.units_stats_table_actions}>
                <ActionButton
                  buttonText="Add Entry"
                  icon={AddIcon}
                  onClick={() => {
                    setCurrentCleanerId(0);
                    setOpenFormModal(true);
                  }}
                />
              </div>
            </div>

            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        totalPages={stateDataTotalPages.adminCleanerTotalPages}
      ></StatsTable>

      {openFormModal && (
        <CleaningDetailsForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          setOpenFormModal={setOpenFormModal}
          formHandler={formHandler}
          currentUnitId={currentUnitId}
          currentCleanerId={currentCleanerId}
        />
      )}
    </>
  );
};

export default CleaningDetails;
