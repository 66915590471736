import Axios from "../axios";
import { apiEndpointname } from "../utils/constants";

const getSingleData = async ({ API_NAME, oneId, additionData }) => {
  let apiPath = "";
  if (oneId) {
    apiPath = apiEndpointname[API_NAME] + "/" + oneId;
  } else {
    apiPath = apiEndpointname[API_NAME];
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "api/v1" + apiPath,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };

  if (additionData) {
    config.params = additionData;
  }

  try {
    const response = await Axios.request(config);

    if (
      (response.status === 200 || response.status === 201) &&
      response.data.success === true
    ) {
      // console.log("response.data.data ", response.data);
      return response.data.data;
    } else {
      // console.log("error getSingleData 1 ", apiPath, response.data);
      return [];
    }
  } catch (error) {
    console.log("error getSingleData ", apiPath, error);

    // Check if the error has a response with a status code
    if (error.response && error.response.status === 701) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userType");
    }

    return [];
  }
};

export { getSingleData };
