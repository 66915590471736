import React from "react";
import styles from "../../../styles/common/approval_action_button/ApprovalActionButton.module.css";

const ApprovalActionButton = ({ buttonText, click }) => {
  return (
    <div
      className={
        styles.approval_action_button +
        " " +
        (buttonText.includes("Approve")
          ? styles.approval_action_button_approve
          : styles.approval_action_button_decline)
      }
      onClick={() => click(buttonText)}
    >
      {buttonText}
    </div>
  );
};

export default ApprovalActionButton;
