import React from "react";
import AnalyticsItem from "../../../common/analytics_item/AnalyticsItem";
import styles from "../../../../styles/sections/admin/analytics/Analytics.module.css";
import OccupiedUnits from "../../../../assets/sections/admin/analytics/occupied_units.svg";
import VacantUnits from "../../../../assets/sections/admin/analytics/vacant_units.svg";
import Bed from "../../../../assets/sections/admin/analytics/bed.svg";

const UnitAnalytics = () => {
  const itemIconsList = [OccupiedUnits, VacantUnits, Bed, Bed];
  const itemTextList = [
    "Occupied Units",
    "Vacant Units",
    "Available Beds",
    "Total Beds",
  ];
  const itemValueList = ["70", "5", "15", "210"];

  return (
    <div className={styles.revenue_analytics_top_section}>
      {itemIconsList.map((item, index) => (
        <AnalyticsItem
          key={index}
          itemIcon={itemIconsList[index]}
          itemText={itemTextList[index]}
          itemValue={itemValueList[index]}
        ></AnalyticsItem>
      ))}
    </div>
  );
};

export default UnitAnalytics;
