import styles from "../../../../styles/sections/admin/tenants/ActionForm.module.css";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import {
  deactivateActivateTenantSchema,
  // paymentValidationSchema,
  reviseRentSchema,
} from "../../../../utils/validators";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { useEffect, useState } from "react";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { getTableData } from "../../../../generalApiCalls/getTableData";
import { postFormData } from "../../../../generalApiCalls/postFormData";
const ActionForm = ({
  setOpenFormModal,
  formTitle,
  buttonText = "Save Details",
  tenantId,
}) => {
  const dispatch = useDispatch();
  let deactiveForm = [
    {
      field_name: "Effective Date",
      field_id: "date",
      placeholder: "Enter Effective Date",
      type: "date",
    },
  ];

  let activateForm = [
    {
      field_name: "Effective Date",
      field_id: "date",
      placeholder: "Enter Effective Date",
      type: "date",
    },
    {
      field_name: "Unit",
      field_id: "unitId",
      type: "dropdown",
      options: [
        // { id: "NoUnit", name: "NoUnit" },
      ],
    },
    {
      field_name: "Rent Amount",
      field_id: "rentPerWeek",
      placeholder: "Enter Rent Amount",
      type: "number",
    },
    {
      field_name: "Bond Amount",
      field_id: "bondAmount",
      placeholder: "Enter Bond Amount",
      type: "number",
    },
  ];

  let reviseForm = [
    {
      field_name: "Effective Date",
      field_id: "date",
      placeholder: "Enter Effective Date",
      type: "date",
    },
    {
      field_name: "Rent Amount",
      field_id: "rentPerWeek",
      placeholder: "Enter Rent Amount",
      type: "number",
    },
    {
      field_name: "Description",
      field_id: "description",
      placeholder: "Enter Description",
      type: "text",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = (formFieldsList) => {
    return formFieldsList?.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };
  const [formFields, setFormFields] = useState(
    initializeFormState(deactiveForm)
  );

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const formHandler = async () => {
    // Form data creation
    let newformData = {};
    let validationSchema = null;
    let formKey = "";

    console.log("actionType", buttonText);

    if (buttonText === "Activate") {
      newformData["flag"] = "ACTIVATE";
      validationSchema = deactivateActivateTenantSchema;
      formKey = "ADMIN__TENANTS__ACTIVATE_DEACTIVATE_TENANT";
    } else if (buttonText === "Deactivate") {
      newformData["flag"] = "DEACTIVATE";
      validationSchema = deactivateActivateTenantSchema;
      formKey = "ADMIN__TENANTS__ACTIVATE_DEACTIVATE_TENANT";
    } else if (buttonText === "Revise") {
      validationSchema = reviseRentSchema;
      formKey = "ADMIN__TENANTS__REVISE_RENT";
    }

    formFields?.map((item) => {
      newformData[item.field_id] = item.value;
    });

    newformData = cleanFormObject(newformData);

    // Validate the form data
    const { error } = validationSchema.validate(newformData);

    console.log("error", error);

    // If validation fails, update the error state and msg
    if (error) {
      error?.details?.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: formKey,
        data: newformData,
        method: "put",
        urlId: tenantId,
      });

      if (response.success) {
        // navigate("/tenant/payment");
        // alert(response?.message);
        alert("Details updated successfully");
        window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const allUnitsIds = async () => {
    dispatch(showLoader(true));
    let resp = await getTableData({
      currentTableKey: "",
      page: 1,
      search: "",
      limit: 500,
      apiEndpointKey: "ADMIN__CLEANING__GET_ALL_UNITS",
    });

    let data = [];

    if (resp["data"] === undefined) {
      dispatch(showLoader(false));
      return;
    }

    resp["data"]["data"]?.map((item) => {
      data.push({ id: item._id, name: item.unitName });
    });

    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === "unitId"
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        options: data,
      };
      return updatedFormFields;
    });

    dispatch(showLoader(false));
  };

  useEffect(() => {
    allUnitsIds();

    if (buttonText === "Activate") {
      setFormFields(initializeFormState(activateForm));
    } else if (buttonText === "Deactivate") {
      setFormFields(initializeFormState(deactiveForm));
    } else if (buttonText === "Revise") {
      setFormFields(initializeFormState(reviseForm));
    }
  }, []);

  return (
    <div className={styles.back_drop}>
      <div className={styles.modal}>
        <div className={styles.back_drop_navBar}>
          <span
          // className={styles.active}
          >
            {formTitle}
          </span>

          <span
            onClick={() => {
              setOpenFormModal(false);
            }}
          >
            <button className={styles.close_handler}>X</button>
          </span>
        </div>

        <ProfileUpdateForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          submit={buttonText}
          formHandler={formHandler}
          showLabel={false}
          formClass={styles.centre_form}
        />
      </div>
    </div>
  );
};

export default ActionForm;
