import styles from "../../../../styles/sections/tenant/personal_details/PersonalDetails.module.css";
import ImageVerification from "../../../common/image_verification/ImageVerification";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
// import UploadLogo from "../../../../assets/sections/tenant/personalDetails/file_upload.svg";
import { useEffect, useState } from "react";
import NavBar from "../../../common/nav_bar/NavBar";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { useDispatch } from "react-redux";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { useNavigate } from "react-router-dom";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { tenantFormIIValidation } from "../../../../utils/validators";
import FileViewer from "../../../common/image_verification/FileViewer";
import SendSignedContractForm from "./SendSignedContractForm";
import ActionButton from "../../../common/action_button/ActionButton";

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tenantId, setTenantId] = useState("");
  const [contractRequestId, setContractRequestId] = useState("");
  const [sendSignedContractForm, setSendSignedContractForm] = useState(false);
  const [contractAvailableForSigning, setContractAvailableForSigning] =
    useState(false);

  const [tenantDetails, setTenantDetails] = useState([
    {
      data_type: "Tenant Name",
      data_value_type: "text",
      value: "",
      id: "tenantName",
    },
    {
      data_type: "Unit Name",
      data_value_type: "text",
      value: "",
      id: "unitName",
    },
    {
      data_type: "Email",
      data_value_type: "email",
      value: "",
      id: "email",
    },
    {
      data_type: "Mobile",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
    {
      data_type: "Whatsapp",
      data_value_type: "number",
      value: "",
      id: "whatsapp",
    },
    {
      data_type: "Emergency Contact",
      data_value_type: "number",
      value: "",
      id: "emergencyContact",
    },
    {
      data_type: "India Contact Number",
      data_value_type: "number",
      value: "",
      id: "indianContactNumber",
    },
    {
      data_type: "Rent Amount (Weekly)",
      data_value_type: "currency",
      value: "",
      id: "weeklyRent",
    },
    {
      data_type: "Deposit Amount",
      data_value_type: "currency",
      value: "",
      id: "bondAmount",
    },
    {
      data_type: "Increment Percentage",
      data_value_type: "text",
      value: "",
      id: "incrementPercent",
    },
    {
      data_type: "Start Date",
      data_value_type: "date",
      value: "",
      id: "startDate",
    },
    {
      data_type: "Tentative Duration (Months)",
      data_value_type: "text",
      value: "",
      id: "tentativeStayDuration",
    },

    {
      data_type: "Payment Mode",
      data_value_type: "text",
      value: "",
      id: "paymentMode",
    },

    {
      data_type: "Company",
      data_value_type: "text",
      value: "",
      id: "companyName",
    },
    {
      data_type: "Country",
      data_value_type: "text",
      value: "",
      id: "country",
    },
    {
      data_type: "State",
      data_value_type: "text",
      value: "",
      id: "state",
    },

    {
      data_type: "Visa Type",
      data_value_type: "text",
      value: "",
      id: "visaType",
    },
    {
      data_type: "Visa Validity (Months)",
      data_value_type: "text",
      value: "",
      id: "visaValidity",
    },
    {
      data_type: "Bank Name",
      data_value_type: "text",
      value: "",
      id: "bankName",
    },

    {
      data_type: "Bank Account No.",
      data_value_type: "text",
      value: "",
      id: "bankAccountNo",
    },
    {
      data_type: "BSB Code",
      data_value_type: "text",
      value: "",
      id: "ifscCode",
    },
    {
      data_type: "Permanent Address",
      data_value_type: "text",
      value: "",
      id: "permanentAddress",
    },
  ]);

  // Form

  let tenantForm = [
    {
      field_name: "Company",
      field_id: "companyName",
      placeholder: "Enter Company Name",
      type: "text",
    },
    {
      field_name: "Country",
      field_id: "country",
      placeholder: "Enter Country",
      type: "text",
    },
    {
      field_name: "State",
      field_id: "state",
      placeholder: "Enter State",
      type: "text",
    },
    {
      field_name: "Mobile Number",
      field_id: "mobile",
      placeholder: "Enter Mobile Number",
      type: "text",
    },
    {
      field_name: "Whatsapp Number",
      field_id: "whatsapp",
      placeholder: "Enter Whatsapp Number",
      type: "text",
    },
    {
      field_name: "Indian Contact Number",
      field_id: "indianContactNumber",
      placeholder: "Enter Indian Contact Number",
      type: "text",
    },
    {
      field_name: "Visa Type",
      field_id: "visaType",
      placeholder: "Enter Visa Type",
      type: "dropdown",
      options: [
        { id: "short term", name: "Short Term" },
        { id: "long term", name: "Long Term" },
      ],
    },
    {
      field_name: "Visa Validity (Months)",
      field_id: "visaValidity",
      placeholder: "Enter Visa Validity",
      type: "number",
    },
    {
      field_name: "Bank Name",
      field_id: "bankName",
      placeholder: "Enter Bank Name",
      type: "text",
    },
    {
      field_name: "Bank Account No.",
      field_id: "bankAccountNo",
      placeholder: "Enter Bank Account No.",
      type: "text",
    },
    {
      field_name: "BSB Code",
      field_id: "ifscCode",
      placeholder: "Enter BSB",
      type: "text",
    },
    {
      field_name: "Emergency Contact",
      field_id: "emergencyContact",
      placeholder: "Enter Emergency Contact",
      type: "text",
    },
    {
      field_name: "Permanent Address",
      field_id: "permanentAddress",
      placeholder: "Enter Permanent Address",
      type: "text",
    },

    {
      field_name: "Passport Photo",
      field_id: "passportUrl",
      fileName: "No File uploaded",
      type: "file",
    },
    {
      field_name: "Drivers Liscence",
      field_id: "identificationDocUrl",
      fileName: "No File uploaded",
      type: "file",
    },
  ];

  const [documents, setDocuments] = useState([
    {
      field_name: "Passport Photo",
      field_id: "passportUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
    {
      field_name: "Drivers Liscence",
      field_id: "identificationDocUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
    {
      field_name: "Original Contract",
      field_id: "contractUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
    {
      field_name: "Signed Contract",
      field_id: "signedContractUrl",
      type: "file",
      fileName: "No File uploaded",
      fileId: "",
    },
  ]);

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return tenantForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : null,
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  const handleGetMoreDetails = async () => {
    dispatch(showLoader(true));

    const response = await getSingleData({
      API_NAME: "TENANT__PERSONAL__GET_DETAILS",
    });

    setTenantId(response._id);

    setTenantDetails((prev) => {
      return prev.map((item) => {
        if (item.id in response) {
          return { ...item, value: response[item.id] };
        } else {
          return item;
        }
      });
    });

    formFields.map((item) => {
      if (item.field_id in response) {
        if (
          item.field_id === "passportUrl" ||
          item.field_id === "identificationDocUrl"
        ) {
        } else {
          handleFieldChange(item.field_id, response[item.field_id], false, "");
        }
      }
    });

    // fetching documents details
    documents.map(async (item) => {
      if (item.field_id in response) {
        setDocuments((prev) => {
          return prev.map((item) => {
            if (item.field_id in response) {
              return {
                ...item,
                fileName: response[item.field_id].originalName,
                fileId: response[item.field_id].assetId,
              };
            } else {
              return item;
            }
          });
        });
      }
    });

    if (response?.contractUrl?.requestId) {
      if (response?.isContractApproved === false) {
        setContractRequestId(response.contractUrl.requestId);
        setContractAvailableForSigning(true);
      }
    }

    dispatch(showLoader(false));
  };

  useEffect(() => {
    handleGetMoreDetails();
  }, []);

  const formHandler = async () => {
    // Form data creation
    let newTenant = {};
    formFields.map((item) => {
      newTenant[item.field_id] = item.value;
    });

    newTenant = cleanFormObject(newTenant);

    // Validate the form data
    const { error } = tenantFormIIValidation.validate(newTenant);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      let isFile = false;

      if (
        typeof newTenant.passportUrl === "object" ||
        typeof newTenant.identificationDocUrl === "object"
      ) {
        isFile = true;
      }

      // add
      response = await postFormData({
        form_key: "TENANT__PERSONAL__UPDATE_DETAILS",
        data: newTenant,
        isFile: isFile,
      });

      if (response.success) {
        navigate("/tenant/personal");
        window.location.reload();
        // alert(response.message);
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  const navSections = ["Personal Details", "Send Profile Update"];

  const [section, setSection] = useState("Personal Details");

  return (
    <div className={styles.persoal_details}>
      <NavBar state={setSection} active={section} navLinks={navSections} />
      {section === "Personal Details" && (
        <div className={styles.personal_details_wrapper}>
          {contractAvailableForSigning && (
            <div className={styles.extra_margin}>
              <ActionButton
                buttonText={"Send Signed Contract"}
                onClick={() => {
                  setSendSignedContractForm(true);
                }}
              ></ActionButton>
            </div>
          )}

          {sendSignedContractForm && (
            <SendSignedContractForm
              requestId={contractRequestId}
              setSendSignedContractForm={setSendSignedContractForm}
            />
          )}

          <CustomTableV2 data={tenantDetails} />

          {documents.map((item, index) => {
            return (
              <FileViewer
                key={index}
                header={item.field_name}
                fileName={item.fileName}
                fileId={item.fileId}
                tenantId={tenantId}
                state={() => {
                  console.log("clicked");
                }}
              />
            );
          })}
        </div>
      )}

      {section === "Send Profile Update" && (
        <ProfileUpdateForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          submit="Send for Approval"
          formHandler={formHandler}
        />
      )}
    </div>
  );
};

export default PersonalDetails;
