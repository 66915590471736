import { Route, Routes, useNavigate } from "react-router-dom";
import styles from "../../../../styles/sections/admin/tenants/Tenants.module.css";
import Invoices from "./Invoices";
import { CreateInvoiceForm } from "./CreateInvoiceForm";

const InvoiceRoutes = () => {
  return (
    <div className={styles.admin_tenants_content}>
      <Routes>
        <Route exact path="/" element={<Invoices />} />
        <Route
          exact
          path="/addInvoice/:invoiceId?"
          element={<CreateInvoiceForm />}
        />
      </Routes>
    </div>
  );
};

export default InvoiceRoutes;
