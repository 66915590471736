import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/approvals/Approvals.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import StatsTable from "../../../common/stats_table/StatsTable";
import ApprovalActionButton from "../../../common/approval_action_button/ApprovalActionButton";
import SearchField from "../../../common/search_field/SearchField";
import NavBar from "../../../common/nav_bar/NavBar";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../utils/generalFunctions";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { STATS_KEYS } from "../../../../utils/constants";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import ApprovalsDetails from "./AmenitieApprovalDetails";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce, set } from "lodash";
import ActionButton from "../../../common/action_button/ActionButton";
import EditIcon from "../../../../assets/common/edit.svg";
import DeleteLogo from "../../../../assets/common/delete_red.svg";
import AddIcon from "../../../../assets/common/add_icon.svg";

import IconButton from "../../../common/icon_button/IconButton";
import AddInventoryForm from "./AddInventoryForm";
import RequestForm from "./RequestForm";
import { showLoader } from "../../../../redux/reducers/loaderSlice";

const Amenities = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const statePageStats = useSelector((state) => state.pageStats);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const scroll = useSelector((state) => state.pageScroll);

  const [searchText, setSearchText] = useState("");
  const [sectionActive, setSectionActive] = useState("Amenities");
  const [isDetailsActive, setIsDetailsActive] = useState(false);
  // const [pageNumber, setPageNumber] = useState(1);
  const initialRender = useRef(true);
  const dispatch = useDispatch();
  const [openFormModal, setOpenFormModal] = useState(false);

  const [isSearchUsed, setIsSearchUsed] = useState(false);
  const [addInventoryModalState, setAddInventoryModalState] = useState(false);
  const [currentInventoryId, setCurrentInventoryId] = useState(0);
  const [currentInventoryData, setCurrentInventoryData] = useState({});
  const [navLinkActive, setNavLinkActive] = useState("Assigned Amenity");
  const navSections = [
    "Assigned Amenity",
    "Amenity Approvals",
    "Amenity Inventory",
  ];

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  const handleSearch = (text) => {
    debouncedHandleSearch(text);
    // Add your search logic here, e.g., filter/search items based on 'text'
  };

  // Units Stats Table
  const units_statsTable_headers = [
    "Request Date",
    "Requester",
    "Unit Address",
    "Rent Paid",
    "Action",
  ];

  const units_tableFieldMapping = [
    "request_date",
    "requestor",
    "unit_address",
    "rent_paid",
    "action",
  ];

  const [approvalTableData, setApprovalTableData] = useState([]);

  const updateRequestStatus = async (requestIdCall, status) => {
    try {
      dispatch(showLoader(true));
      let data = {
        requestStatus: status,
      };
      console.log("dat", data);
      let response = await postFormData({
        form_key: "ADMIN__AMENITIES__UPDATE_STATUS",
        data: data,
        method: "PUT",
        urlId: requestIdCall,
      });
      if (response.success === true) {
        window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    } catch (err) {
      dispatch(showLoader(false));
      console.log(err);
    }
  };

  const deleteRAmenityRecord = async (amenityRecordId) => {
    //  let { tenantId } = req.params;
    //   let { date, rentPerWeek } = req.body;
    try {
      dispatch(showLoader(true));
      const response = await postFormData({
        form_key: "ADMIN__AMENITIES__DELETE_AMENITY",
        urlId: amenityRecordId,
        data: {},
        method: "delete",
      });

      if (response.success) {
        window.location.reload();
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    } catch (err) {
      dispatch(showLoader(false));
      console.log(err);
    }
  };
  //'PENDING', 'APPROVED', 'DECLINED', 'RETURNED'
  let status_colors = {
    DECLINED: "#FF5733",
    PENDING: "#4169E1",
    RETURNED: "#808080",
    APPROVED: "#32CD32",
  };

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      if (navLinkActive === "Amenity Inventory") {
        mappedData.sr = index + 1;
        mappedData.name = item.name;
        mappedData.quantity = item.quantity;
        mappedData.available_quantity = item.availableQuantity;
        mappedData.action = (
          <div className={styles.action_buttons_wrapper}>
            <IconButton
              buttonText={""}
              buttonIcon={EditIcon}
              onClick={() => {
                setCurrentInventoryId(item._id);
                setAddInventoryModalState(true);
                setCurrentInventoryData(item);
              }}
              whiteBg={true}
            ></IconButton>
            <IconButton
              buttonText={""}
              buttonIcon={DeleteLogo}
              onClick={() => {
                deleteRAmenityRecord(item._id);
              }}
              whiteBg={true}
            ></IconButton>
          </div>
        );
        mappedDataList.push(mappedData);
      } else {
        // console.log("data 111 2 22 ", data);
        mappedData._id = item._id;
        mappedData.request_date = formatDate(item.createdAt);
        mappedData.tenant_name = item.tenantName || "Assigned to Unit";
        mappedData.unit_address = item.unitName;
        mappedData.status = (
          <p
            style={{
              color: status_colors[item.requestStatus?.toUpperCase()],
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {item.requestStatus?.toUpperCase()}
          </p>
        );
        mappedData.requestFor = item?.requestedItems;
        mappedData.action = (
          <div className={styles.action_buttons_wrapper}>
            {item.requestStatus === "PENDING" && (
              <>
                <ApprovalActionButton
                  buttonText={"Approve"}
                  click={() => {
                    updateRequestStatus(item._id, "APPROVED");
                  }}
                ></ApprovalActionButton>
                <ApprovalActionButton
                  buttonText={"Decline"}
                  click={() => {
                    updateRequestStatus(item._id, "DECLINED");
                  }}
                ></ApprovalActionButton>
              </>
            )}
            {item.requestStatus === "APPROVED" && (
              <ApprovalActionButton
                buttonText={"Returned"}
                click={() => {
                  updateRequestStatus(item._id, "RETURNED");
                }}
              ></ApprovalActionButton>
            )}
            {item.requestStatus === "DECLINED" && (
              <ApprovalActionButton
                buttonText={"Declined"}
                click={() => {
                  alert("Already Declined");
                }}
              ></ApprovalActionButton>
            )}
          </div>
        );
        mappedDataList.push(mappedData);
      }
    });

    setApprovalTableData(mappedDataList);
  };

  const getApiEndpoint = () => {
    let apiEndpoint = "";
    if (navLinkActive.includes("Assigned Amenity")) {
      apiEndpoint = "ADMIN__AMENITIES__APPROVALS";
    } else if (navLinkActive.includes("Amenity Approvals")) {
      apiEndpoint = "ADMIN__AMENITIES__APPROVALS";
    } else if (navLinkActive.includes("Amenity Inventory")) {
      apiEndpoint = "ADMIN__AMENITIES__AMENITIES";
    }
    console.log("apiEndpoint", apiEndpoint);
    return apiEndpoint;
  };

  const getApiAdditionalParams = () => {
    let requestType = "";
    let requestStatus = "";
    if (navLinkActive.includes("Assigned Amenity")) {
      requestType = "Amenities";
      requestStatus = "APPROVED";
    } else if (navLinkActive.includes("Amenity Approvals")) {
      requestType = "Amenities";
      requestStatus = "PENDING";
    }

    return { requestType: requestType, requestStatus: requestStatus };
  };

  useEffect(() => {
    // console.log("I got triggered -1");

    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        getApiEndpoint(),
        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__AMENITIES__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        getApiEndpoint(),

        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        getApiEndpoint(),

        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        getApiEndpoint(),

        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        getApiEndpoint(),

        scroll.pageNo,
        searchText,
        getApiAdditionalParams()
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__AMENITIES__STATS, "");
    }
  }, [scroll.pageNo, searchText, navLinkActive, isDetailsActive]);

  useEffect(() => {
    if (stateDataTableData.adminAmenities?.length >= 0)
      processTableData(stateDataTableData.adminAmenities);
  }, [stateDataTableData.adminAmenities]);

  //profile Approval
  const profile_approval_header = [
    "Request Date",
    "Tenant Name",
    "Unit Address",
    "Request For",
    "Status",
    "Action",
  ];

  const profile_approval_field_mapping = [
    "request_date",
    "tenant_name",
    "unit_address",
    "requestFor",
    "status",
    "action",
  ];

  const amenities_header = [
    "Sr.no",
    "Name",
    "Quantity",
    "Available Quantity",
    "Action",
  ];
  const amenities_field_mapping = [
    "sr",
    "name",
    "quantity",
    "available_quantity",
    "action",
  ];

  return (
    <div className={styles.admin_approvals_content}>
      {sectionActive === "Amenities" && (
        <StatsTable
          tableTitle={"Amenities"}
          tableHeaders={
            navLinkActive === "Assigned Amenity"
              ? profile_approval_header
              : navLinkActive === "Amenity Approvals"
              ? profile_approval_header
              : amenities_header
          }
          tableData={approvalTableData}
          tableFieldMapping={
            navLinkActive === "Assigned Amenity"
              ? profile_approval_field_mapping
              : navLinkActive === "Amenity Approvals"
              ? profile_approval_field_mapping
              : amenities_field_mapping
          }
          tableActionsElement={
            <div className={styles.approvals_stats_table_actions}>
              <SearchField searchText={searchText} onSearch={handleSearch} />

              {navLinkActive === "Amenity Inventory" && (
                <ActionButton
                  buttonText={"Add Inventory"}
                  onClick={() => {
                    console.log("I got triggered Add");
                    setAddInventoryModalState(true);
                  }}
                ></ActionButton>
              )}

              {navLinkActive === "Assigned Amenity" && (
                <ActionButton
                  buttonText={"Assign Amenity to Unit"}
                  icon={AddIcon}
                  onClick={() => {
                    setOpenFormModal(true);
                  }}
                ></ActionButton>
              )}
            </div>
          }
          tableActionsElementMobile={
            <div
              className={
                statsStyles.stats_title_actions_container +
                " " +
                statsStyles.stats_title_actions_container_show
              }
            >
              <div className={statsStyles.statsTable_title_container}>
                <div className={statsStyles.stats_table_title}>Amenities</div>

                {navLinkActive === "Amenity Inventory" && (
                  <div className={styles.units_stats_table_actions}>
                    <ActionButton
                      buttonText={"Add Inventory"}
                      onClick={() => {
                        console.log("I got triggered Add");
                        setAddInventoryModalState(true);
                      }}
                    ></ActionButton>
                  </div>
                )}
              </div>
              <SearchField searchText={searchText} onSearch={handleSearch} />
            </div>
          }
          tableNavBar={
            <NavBar
              state={(navLink) => {
                setNavLinkActive(navLink);
                setApprovalTableData([]);
                dispatch(resetTableData());
                initialRender.current = true;
                dispatch(
                  updatePageNumber({
                    pageNo: 1,
                  })
                );
              }}
              active={navLinkActive}
              navLinks={navSections}
            />
          }
          totalPages={stateDataTotalPages.adminAmnitiesTotalPages}
        ></StatsTable>
      )}

      {sectionActive === "History" && (
        <StatsTable
          tableTitle={navLinkActive}
          tableHeaders={
            navLinkActive === "Assigned Amenity History"
              ? units_statsTable_headers
              : navLinkActive === "Amenity Approvals History"
              ? profile_approval_header
              : profile_approval_header
          }
          tableData={approvalTableData}
          tableFieldMapping={
            navLinkActive === "Assigned Amenity History"
              ? units_tableFieldMapping
              : navLinkActive === "Amenity Approvals History"
              ? profile_approval_field_mapping
              : profile_approval_field_mapping
          }
          tableActionsElement={
            <div className={styles.approvals_stats_table_actions}>
              <SearchField searchText={searchText} onSearch={handleSearch} />
            </div>
          }
          tableIcon={BackArrow}
          state={() => {
            dispatch(resetTableData());
            initialRender.current = true;
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            setSectionActive("Amenities");
            navLinkActive === "Assigned Amenity History"
              ? setNavLinkActive("Assigned Amenity")
              : setNavLinkActive("Amenity Approvals");
          }}
          totalPages={stateDataTotalPages.adminAmnitiesTotalPages}
        ></StatsTable>
      )}

      {sectionActive === "Details" && (
        <ApprovalsDetails
          state={() => {
            console.log("I got triggered Details Back");
            dispatch(resetTableData());
            initialRender.current = true;
            dispatch(
              updatePageNumber({
                pageNo: 1,
              })
            );
            navLinkActive.includes("History")
              ? setSectionActive("History")
              : setSectionActive("Amenities");
            setIsDetailsActive(false);
          }}
          header={
            navLinkActive === "Assigned Amenity" ||
            navLinkActive === "Assigned Amenity History"
              ? "Assigned - Amenity Details"
              : navLinkActive === "Amenity Approvals" ||
                navLinkActive === "Amenity Approvals History"
              ? "Approvals - Amenity Details"
              : ""
          }
          // activeRequestId={activeRequestId}
          navLinkActive={navLinkActive}
        />
      )}
      {
        // Add Inventory Modal
        addInventoryModalState && (
          <AddInventoryForm
            setOpenFormModal={setAddInventoryModalState}
            currentInventoryId={currentInventoryId}
            currentInventoryData={currentInventoryData}
          />
        )
      }
      {openFormModal && <RequestForm setOpenFormModal={setOpenFormModal} />}
    </div>
  );
};

export default Amenities;
