import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useEffect, useRef, useState } from "react";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import {
  parkingSlotValidationSchema,
  tenantRequestSchema,
} from "../../../../utils/validators";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { getTableData } from "../../../../generalApiCalls/getTableData";

const RequestForm = ({ setOpenFormModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newRequestForm = [
    // {
    //   field_name: "Reuest Type",
    //   field_id: "requestType",
    //   placeholder: "Please select Request Type",
    //   type: "dropdown",
    //   options: [{ id: "Amenities", name: "Amenities" }],
    // },
    {
      field_name: "Unit",
      field_id: "unitId",
      placeholder: "Select a Unit",
      type: "dropdown",
      options: [],
    },
    {
      field_name: "Amenity Name",
      field_id: "requestedItems",
      placeholder: "Enter Amenity Name",
      type: "dropdown",
      options: [
        // { id: "Swimming Pool", name: "Swimming Pool" },
        // { id: "Gym", name: "Gym" },
      ],
    },
  ];

  const continueRequestFormForAmenities = [];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return newRequestForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  const initialRender = useRef(true);
  const allAmenitiesAndUnits = async () => {
    if (initialRender.current) {
      dispatch(showLoader(true));
      initialRender.current = false;

      let allAmenitiesData = await getTableData({
        currentTableKey: "",
        page: 1,
        search: "",
        limit: 5000,
        apiEndpointKey: "ADMIN__GET_ALL_AMENITIES_NAME",
      });

      let allUnitsData = await getTableData({
        currentTableKey: "",
        page: 1,
        search: "",
        limit: 5000,
        apiEndpointKey: "ADMIN__CLEANING__GET_ALL_UNITS",
      });

      let finalAllAmenitiesData = [];
      let finalAllUnitsData = [];

      if (allAmenitiesData["data"] === undefined) {
        dispatch(showLoader(false));
        return;
      }
      if (allUnitsData["data"] === undefined) {
        dispatch(showLoader(false));
        return;
      }

      allAmenitiesData["data"]["data"]?.map((item) => {
        finalAllAmenitiesData.push({ id: item._id, name: item.name });
      });

      allUnitsData["data"]["data"]?.map((item) => {
        finalAllUnitsData.push({ id: item._id, name: item.unitName });
      });

      setFormFields((prevFields) => {
        const updatedFormFields = [...prevFields];

        const index = updatedFormFields.findIndex(
          (item) => item.field_id === "requestedItems"
        );

        const index2 = updatedFormFields.findIndex(
          (item) => item.field_id === "unitId"
        );

        updatedFormFields[index] = {
          ...updatedFormFields[index],
          options: finalAllAmenitiesData,
        };

        updatedFormFields[index2] = {
          ...updatedFormFields[index2],
          options: finalAllUnitsData,
        };

        return updatedFormFields;
      });

      dispatch(showLoader(false));
    }
  };

  useEffect(() => {
    allAmenitiesAndUnits();
  }, []);

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });

    // add form fields for car parking and amenities as per the request type (don't duplicate the fields if already added) (while changing the request type remove the fields of the other type if added)
  };

  const formHandler = async () => {
    // Form data creation
    let newRequestData = {};
    formFields.map((item) => {
      newRequestData[item.field_id] = item.value;
    });
    newRequestData["requestType"] = "Amenities";

    newRequestData = cleanFormObject(newRequestData);

    // Validate the form data
    const { error } = tenantRequestSchema.validate(newRequestData);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: "ADMIN__AMENITIES__ADD_UNIT_REQUEST",
        data: newRequestData,
      });

      if (response.success) {
        setOpenFormModal(false);
        window.location.reload();
        // navigate("/tenant/requestApprovals");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>Assign Amenity to Unit</div>
          <button
            onClick={() => {
              setOpenFormModal(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <ProfileUpdateForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          formClass={styles.centre_form}
          submit={"Assign"}
          formHandler={formHandler}
        />
      </form>
    </div>
  );
};

export default RequestForm;
