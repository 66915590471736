import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageNo: 1,
};

const pageScrollSlice = createSlice({
  name: "pageScroll",
  initialState,
  reducers: {
    updatePageNumber: (state, action) => {
      const { pageNo } = action.payload;
      console.log("pageNo", pageNo);
      state.pageNo = pageNo;
    },
  },
});

export const { updatePageNumber } = pageScrollSlice.actions;
export default pageScrollSlice.reducer;
