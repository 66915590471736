import React, { useEffect, useState } from "react";
import styles from "../../../../styles/sections/admin/employees/EmployeeDetails.module.css";

import CustomTableV2 from "../../../common/custom_table_v2/CustomTableV2";
import { useDispatch, useSelector } from "react-redux";
import { getSingleData } from "../../../../generalApiCalls/getSingleData";
import { useNavigate, useParams } from "react-router-dom";

import EditLogo from "../../../../assets/common/edit.svg";
import BackArrow from "../../../../assets/common/back_arrow.svg";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import ActionButton from "../../../common/action_button/ActionButton";
import { USER_TYPE } from "../../../../utils/constants";

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.userInfo);
  const [profileEditable, setProfileEditable] = useState(true);

  const navigate = useNavigate();
  const employeeId = useParams().employeeId;

  const [employeeDetails, setEmployeeDetails] = useState([
    {
      data_type: "Employee Name",
      data_value_type: "text",
      value: "",
      id: "name",
    },
    {
      data_type: "Email",
      data_value_type: "email",
      value: "",
      id: "email",
    },
    {
      data_type: "Mobile",
      data_value_type: "number",
      value: "",
      id: "mobile",
    },
    {
      data_type: "WhatsApp Number",
      data_value_type: "number",
      value: "",
      id: "whatsapp",
    },
    {
      data_type: "Designation",
      data_value_type: "text",
      value: "",
      id: "designation",
    },
    {
      data_type: "Salary",
      data_value_type: "number",
      value: "",
      id: "salary",
    },
    {
      data_type: "Is Active",
      data_value_type: "boolean",
      value: "",
      id: "isActive",
    },
    {
      data_type: "Joining Date",
      data_value_type: "date",
      value: "",
      id: "startDate",
    },
    // {
    //   data_type: "End Date",
    //   data_value_type: "date",
    //   value: "",
    //   id: "endDate",
    // },
    {
      data_type: "Feature Access",
      data_value_type: "array",
      value: "",
      id: "featureAccess",
    },
  ]);

  const getProfileData = async () => {
    const response = await getSingleData({
      API_NAME: "ADMIN__EMPLOYEE__GET_EMPLOYEE_DETAILS",
      oneId: employeeId,
    });

    setProfileEditable(response?.profileEditable);

    setEmployeeDetails((prev) => {
      return prev.map((item) => {
        if (item.id in response) {
          if (item.data_value_type === "boolean") {
            return { ...item, value: response[item.id] ? "Yes" : "No" };
          }
          return { ...item, value: response[item.id] };
        } else {
          return item;
        }
      });
    });
  };

  const resendOnBoardingEmail = async () => {
    dispatch(showLoader(true));

    let data = {
      userId: employeeId,
      userType: USER_TYPE.EMPLOYEE,
    };

    const response = await postFormData({
      form_key: "ADMIN__TENANTS__RESEND_ONBOARDING_EMAIL",
      data: data,
    });

    if (response?.success === true) {
      alert("Email sent successfully");
    }

    dispatch(showLoader(false));
  };

  useEffect(() => {
    getProfileData();
  }, [employeeData]);

  return (
    <div className={styles.employee_details}>
      <div className={styles.details_header}>
        <div className={styles.detailed_section}>
          <img src={BackArrow} alt="" onClick={() => navigate(-1)}></img>
          Details
        </div>
        <div className={styles.paymentButtonGroup}>
          <ActionButton
            buttonText={"Resend Onboarding Email"}
            onClick={() => {
              resendOnBoardingEmail();
            }}
          ></ActionButton>
        </div>
      </div>

      <CustomTableV2
        data={employeeDetails}
        header="Employee Details"
        editable={profileEditable}
        logo={EditLogo}
        handleClick={() =>
          navigate("/admin/employees/addEmployee/" + employeeId)
        }
      />
    </div>
  );
};

export default EmployeeDetails;
