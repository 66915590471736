import { useEffect, useState } from "react";
import styles from "../../../styles/common/custom_table_v2/CustomTableV2.module.css";
import { formatDate } from "../../../utils/generalFunctions";
import ImageVerification from "../image_verification/ImageVerification";
const CustomTableV2 = (props) => {
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    // console.log(props?.editable);
    if (props?.editable !== undefined) {
      setEditable(props.editable);
    }
  }, [props.editable]);

  return (
    props?.data?.length > 0 && (
      <div className={styles.tenants_table}>
        <span className={styles.table_header}>
          {props.header}
          {props.logo && editable && (
            <img src={props.logo} alt="Icon" onClick={props.handleClick}></img>
          )}
        </span>
        {props?.data?.map((content, index) => {
          return (
            <div className={styles.content_wrap} key={index}>
              <span className={styles.content_wrap_title}>
                {content.data_type}
              </span>
              <span className={styles.content_wrap_value}>
                {content.data_value_type === "date"
                  ? formatDate(content.value)
                  : content.data_value_type === "array"
                  ? (content.value || []).join(", ")
                  : content.value}
                {content.data_value_type === "file" && (
                  <ImageVerification header={content.value.name} />
                )}
              </span>
            </div>
          );
        })}
      </div>
    )
  );
};

export default CustomTableV2;
