import { Route, Routes, useNavigate } from "react-router-dom";

import styles from "../../../../styles/sections/admin/employees/Employees.module.css";
import Employees from "./Employees";
import AddEmployee from "./AddEmployee";
import EmployeeDetails from "./EmployeeDetails";

const EmployeeRoutes = () => {
  return (
    <div className={styles.admin_employees_content}>
      <Routes>
        <Route exact path="/" element={<Employees />} />
        <Route
          exact
          path="/addEmployee/:employeeId?"
          element={<AddEmployee />}
        />
        <Route path="/:employeeId" element={<EmployeeDetails />} />
      </Routes>
    </div>
  );
};

export default EmployeeRoutes;
