import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenantInfo: {},
};

const tenantInfoSlice = createSlice({
  name: "tenantInfo",
  initialState,
  reducers: {
    updateTenantInfo: (state, action) => {
      state.tenantInfo = action.payload;
    },
    updateTenantOneInfo: (state, action) => {
      state.tenantInfo = {
        ...state.tenantInfo,
        [action.payload.key]: action.payload.value,
      };
    },
  },
});

export const { updateTenantInfo, updateTenantOneInfo } =
  tenantInfoSlice.actions;
export default tenantInfoSlice.reducer;
