import React from "react";
import styles from "../../../styles/common/analytics_item/AnalyticsItem.module.css";

const AnalyticsItem = ({ itemIcon, itemValue, itemText }) => {
  return (
    <div className={styles.analytics_item}>
      <img src={itemIcon} alt={itemIcon}></img>
      <div className={styles.analytics_item_values_wrapping}>
        <span className={styles.analytics_item_value}>{itemValue}</span>
        <span className={styles.analytics_item_text}>{itemText}</span>
      </div>
    </div>
  );
};

export default AnalyticsItem;
