/*
Table keys are named as 
Section name - ADMIN, TENANT
Current page name - Units, Tenants, Approvals etc.
Data Table Name - All Units, Tenants, Approvals etc.

So, a All Units table on the Units page on the admin section would be names as ADMIN__UNITS__ALL_UNITS
*/

const USER_TYPE = {
  ADMIN: "ADMIN",
  TENANT: "TENANT",
  EMPLOYEE: "EMPLOYEE",
};

const tableKey = {
  ADMIN__UNITS__ALL_UNITS: "ADMIN__UNITS__ALL_UNITS",
  ADMIN__TENANTS__TENANTS: "ADMIN__TENANTS__TENANTS",
  ADMIN__APPROVALS__APPROVALS: "ADMIN__APPROVALS__APPROVALS",
  ADMIN__AMENITIES__AMENITIES: "ADMIN__AMENITIES__AMENITIES",
  ADMIN__AMENITIES__APPROVALS: "ADMIN__AMENITIES__APPROVALS",
  ADMIN__NOTES__NOTES: "ADMIN__NOTES__NOTES",
  ADMIN__MESSAGES__MESSAGES: "ADMIN__MESSAGES__MESSAGES",
  ADMIN__INVOICES__INVOICES: "ADMIN__INVOICES__INVOICES",
  ADMIN__CLEANING__CLEANING: "ADMIN__CLEANING__CLEANING",
  ADMIN__CLEANING__CLEANER: "ADMIN__CLEANING__CLEANER",
  ADMIN__CAR_PARKING__CAR_PARKING: "ADMIN__CAR_PARKING__CAR_PARKING",
  TENANT__PAYMENT_HISTORY: "TENANT__PAYMENT_HISTORY",
  ADMIN__TENANT__PAYMENT_HISTORY: "ADMIN__TENANT__PAYMENT_HISTORY",
  ADMIN__EMPLOYEES__EMPLOYEES: "ADMIN__EMPLOYEES__EMPLOYEES",
  ADMIN__ISSUES__ISSUES: "ADMIN__ISSUES__ISSUES",
  TENANT__ISSUES__ISSUES: "TENANT__ISSUES__ISSUES",
  TENANT__REQUEST__REQUESTS: "TENANT__REQUEST__REQUESTS",
};

// Stats
const STATS_KEYS = {
  ADMIN_STATS_DASHBOARD: "ADMIN_STATS_DASHBOARD",
  ADMIN__UNITS__STATS: "ADMIN__UNITS__STATS",
  ADMIN__TENANTS__STATS: "ADMIN__TENANTS__STATS",
  ADMIN__APPROVALS__STATS: "ADMIN__APPROVALS__STATS",
  ADMIN__AMENITIES__STATS: "ADMIN__AMENITIES__STATS",
  ADMIN__CLEANING__STATS: "ADMIN__CLEANING__STATS",
  ADMIN__CAR_PARKING__STATS: "ADMIN__CAR_PARKING__STATS",
  ADMIN__INVOICE__STATS: "ADMIN__INVOICE__STATS",
  ADMIN__EMPLOYEE__STATS: "ADMIN__EMPLOYEE__STATS",
  ADMIN__ISSUES__STATS: "ADMIN__ISSUES__STATS",

  // Internal stats
  ADMIN__UNITS__STATS_INTERNAL: "ADMIN__UNITS__STATS_INTERNAL",
  ADMIN__TENANTS__STATS_INTERNAL: "ADMIN__TENANTS__STATS_INTERNAL",
};

const apiEndpointname = {
  // Auth
  EMPLOYEE_SETPASSWORD: "/admin/setPassword",
  TENANT_SETPASSWORD: "/tenant/setPassword",

  // Stats
  ADMIN_STATS: "/admin/stats",
  TENANT_STATS: "/tenant/stats",

  // Asset Download
  ASSET_DOWNLOAD: "/download",

  // TENANT
  TENANT_AGREE_TO_TERMS: "/tenant/agreedOnRules",
  TENANT_ADD_REFERRAL_DETAILS: "/tenant/addReferralDetails",

  // units
  ADMIN__UNITS__ADD_UNIT: "/admin/units/addUnit",
  ADMIN__UNITS__GET_UNIT_DETAILS: "/admin/units/getUnitDetails",
  ADMIN__UNITS__UPDATE_UNIT_DETAILS: "/admin/units/updateUnitDetails",
  ADMIN__UNITS__GET_ALL_UNITS: "/admin/units/getAllUnitDetails",

  // setup expenses
  ADMIN__UNITS__SETUP_ADD_EXPENSE: "/admin/units/addExpense",
  ADMIN__UNITS__SETUP_UPDATE_EXPENSE: "/admin/units/updateExpense",
  ADMIN__UNITS__SETUP_DELETE_EXPENSE: "/admin/units/deleteExpense",

  // tenants
  ADMIN__TENANTS__ADD_TENANT: "/admin/tenants/addTenant",
  ADMIN__TENANTS__RESEND_ONBOARDING_EMAIL: "/admin/resendEmail",
  ADMIN__TENANTS__UPDATE_TENANT_DETAILS: "/admin/tenants/updateTenantDetails",
  ADMIN__TENANTS__GET_TENANT_DETAILS: "/admin/tenants/getTenantDetails",
  ADMIN__TENANTS__GET_ALL_TENANTS: "/admin/tenants/getAllTenantDetails",
  ADMIN__TENANTS__GET_TENANT_PAYMENT_HISTORY:
    "/admin/tenants/payment/getPaymentHistory",
  ADMIN__TENANTS__GET_TENANT_BILLING_HISTORY:
    "/admin/tenants/payment/getBillingHistory",
  ADMIN__TENANTS__ADD_PAYMNET_RECORD:
    "/admin/tenants/payment/shareTenantPaymentDetails",
  ADMIN__TENANTS__DELETE_PAYMNET_RECORD: "/admin/tenants/payment/deletePayment",

  ADMIN__TENANTS__SEND_CONTRACT: "/admin/tenants/sendContract",
  ADMIN__TENANTS__ACTIVATE_DEACTIVATE_TENANT:
    "/admin/tenants/markActiveOrInactive",
  ADMIN__TENANTS__DELETE__ACTIVATE_DEACTIVATE:
    "/admin/tenants/deleteUnitStayHistory",

  ADMIN__TENANTS__REVISE_RENT: "/admin/tenants/updateTenantDetails/reviseRent",
  ADMIN__TENANTS__DELETE_REVISED_RENT:
    "/admin/tenants/deleteRentalHistory/reviseRent",

  // parking
  ADMIN__PARKING__ADD_CAR_PARKING: "/admin/parking/addParking",
  ADMIN__PARKING__UPDATE_CAR_PARKING_DETAILS: "/admin/parking/slots",
  ADMIN__PARKING__GET_ALL_CAR_PARKING: "/admin/parking/listAll",

  // amenities
  ADMIN__AMENITIES__ADD_AMENITY: "/admin/amenities/addAmenity",
  ADMIN__AMENITIES__UPDATE_AMENITY: "/admin/amenities/updateAmenity",
  ADMIN__AMENITIES__DELETE_AMENITY: "/admin/amenities/deleteAmenity",
  ADMIN__AMENITIES__GET_ALL_AMENITIES: "/admin/amenities/getAllAmenities",
  ADMIN__AMENITIES__GET_ALL_APPROVALS: "/admin/amenities/getAllApprovals",
  ADMIN__AMENITIES__UPDATE_STATUS: "/admin/amenities/updateRequestStatus",
  ADMIN__AMENITIES__GET_ALL_ASSIGNED: "/admin/amenities/getAllAssigned",
  TENANT__GET_ALL_AMENITIES_NAME: "/tenant/amenities/getAllAmenitiesName",
  ADMIN__GET_ALL_AMENITIES_NAME: "/admin/amenities/getAllAmenitiesName",
  ADMIN__AMENITIES__ADD_UNIT_REQUEST: "/admin/amenities/addUnitrequest",

  // TENANT REQUEST
  TENANT__REQUEST__ADD_REQUEST: "/tenant/request/addtenantrequest",
  TENANT__REQUEST__GET_ALL_REQUESTS: "/tenant/request/getAllTenantRequest",

  // approvals
  ADMIN__APPROVALS__GETALL__APPROVALS: "/admin/approvals/getApproval",
  ADMIN__APPROVALS__GET_APPROVAL_DETAILS: "/admin/approvals/getApprovalDetails",
  ADMIN__APPROVAL_ACTION: "/admin/approvals/acceptOrReject",

  // notes
  ADMIN__NOTES__ADD_NOTE: "/admin/notes/addNote",
  ADMIN__NOTES__UPDATE_NOTE: "/admin/notes/updateNote",
  ADMIN__NOTES__DELETE_NOTE: "/admin/notes/deleteNote",
  ADMIN__NOTES__GET_ONE_NOTE: "/admin/notes/getNote",
  ADMIN__NOTES__GET_ALL_NOTE: "/admin/notes/getAllNotes",

  // messages
  ADMIN__MESSAGES__GET_ALL_MESSAGES: "/admin/broadcast/wa_msg/getAllMsgs",
  ADMIN__MESSAGES__GET_ONE_MESSAGE: "/admin/broadcast/wa_msg/getMsg",
  ADMIN__MESSAGES__ADD_MESSAGE: "/admin/broadcast/wa_msg/sendMsg",
  ADMIN__MESSAGES__UPDATE_MESSAGE: "/admin/broadcast/wa_msg/updateMsg",
  ADMIN__MESSAGES__DELETE_MESSAGE: "/admin/broadcast/wa_msg/deleteMsg",

  // invoices
  ADMIN__INVOICES__GET_ALL_INVOICE: "/admin/invoices/getAllInvoice",
  ADMIN__INVOICES__GET_INVOICE_DETAILS: "/admin/invoices/getInvoice",
  ADMIN__INVOICES__UPDATE_INVOICE_DETAILS: "/admin/invoices/updateInvoice",
  ADMIN__INVOICES__ADD_INVOICE: "/admin/invoices/addInvoice",
  ADMIN__INVOICES__DELETE_INVOICE: "/admin/invoices/deleteInvoice",

  // Cleaning
  ADMIN__CLEANING__GET_ALL_UNITS: "/admin/cleaning/getAllUnitDetails",
  ADMIN__CLEANING__GET_ALL_CLEANERS: "/admin/cleaning/getAllRecords",
  ADMIN__CLEANING__ADD_CLEANER: "/admin/cleaning/addCleanerRecord",
  ADMIN__CLEANING__UPDATE_CLEANER: "/admin/cleaning/updateCleanerRecord",

  // Tenants
  TENANT__HOME__COTENANTS: "/tenant/co-tenants",
  TENANT__PERSONAL__GET_DETAILS: "/tenant/getTenantDetails",
  TENANT__PERSONAL__UPDATE_DETAILS: "/tenant/profileReview",
  TENANT__SEND__SIGNED_CONTRACT: "/tenant/sendSignedContract",

  // Tenant - Payment History
  TENANT_SHARE_PAYMENT_DETAILS: "/tenant/payment/sharePaymentDetails",
  TENANT__GET_TENANT_PAYMENT_HISTORY: "/tenant/payment/getPaymentHistory",
  TENANT__GET_TENANT_BILLING_HISTORY: "/tenant/payment/getBillingHistory",

  // Plots
  ADMIN_DASHBOARD_PLOTS: "/admin/dashboard/plots",

  // Employees
  ADMIN__EMPLOYEE__GET_ALL_EMPLOYEES: "/admin/employees/getAllEmployees",
  ADMIN__EMPLOYEE__ADD_EMPLOYEE: "/admin/employees/addEmployee",
  ADMIN__EMPLOYEE__GET_EMPLOYEE_DETAILS: "/admin/employees/getEmployeeDetails",
  ADMIN__EMPLOYEE__UPDATE_EMPLOYEE_DETAILS:
    "/admin/employees/updateEmployeeDetails",

  // Issues
  TENANT__ISSUES__ADD_ISSUE: "/tenant/issues/recordIssue",
  ADMIN__ISSUES__ADD_ISSUE: "/admin/issues/recordIssue",
  TENANT__ISSUES__GET_ALL_ISSUES: "/tenant/issues/getAllIssues",
  ADMIN__ISSUES__GET_ALL_ISSUES: "/admin/issues/getAllIssues",
  ADMIN__ISSUES__UPDATE_ISSUE: "/admin/issues/updateIssue",
};

// // Plot Constants
// const PLOTS = {
//   PLOT_DASHBOARD: "dashboard",
// };

export { USER_TYPE, tableKey, apiEndpointname, STATS_KEYS };
