import React from "react";
import AnalyticsItem from "../../../common/analytics_item/AnalyticsItem";
import styles from "../../../../styles/sections/admin/analytics/Analytics.module.css";
import AnalyticsItemGeneral from "../../../../assets/sections/admin/analytics/analytics_item_general.svg";

const RevenueAnalytics = () => {
  const itemIconsList = [
    AnalyticsItemGeneral,
    AnalyticsItemGeneral,
    AnalyticsItemGeneral,
  ];
  const itemTextList = [
    "This Months Revenue",
    "Previous Months Revenue",
    "Average Revenue",
  ];
  const itemValueList = ["$ 68.5k", "$ 68.5k", "$ 68.5k"];

  return (
    <div className={styles.revenue_analytics_top_section}>
      {itemIconsList.map((item, index) => (
        <AnalyticsItem
          key={index}
          itemIcon={itemIconsList[index]}
          itemText={itemTextList[index]}
          itemValue={itemValueList[index]}
        ></AnalyticsItem>
      ))}
    </div>
  );
};

export default RevenueAnalytics;
