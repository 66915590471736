import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/car_parking/CarParking.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import SlotsAvailable from "../../../../assets/sections/admin/car_parking/slots_available.svg";
import SlotsAllocated from "../../../../assets/sections/admin/car_parking/slots_allocated.svg";
import OccupancyPercentage from "../../../../assets/sections/admin/car_parking/occupancy_percentage.svg";
import ParkingRevenue from "../../../../assets/sections/admin/units/total_revenue.svg";
import EditIcon from "../../../../assets/common/edit.svg";

import StatsBoard from "../../../common/stats_board/StatsBoard";
import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import SearchField from "../../../common/search_field/SearchField";
import AddIcon from "../../../../assets/common/add_icon.svg";
import IconButton from "../../../common/icon_button/IconButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { STATS_KEYS } from "../../../../utils/constants";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import CarParkingForm from "./CarParkingForm";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import { parkingSlotValidationSchema } from "../../../../utils/validators";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { fetchPageStats } from "../../../../generalApiCalls/getStatsData";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { debounce } from "lodash";

const CarParking = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const statePageStats = useSelector((state) => state.pageStats);
  const scroll = useSelector((state) => state.pageScroll);
  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialRender = useRef(true);

  // Define a debounced version of handleSearch
  const debouncedHandleSearch = debounce((text) => {
    dispatch(
      updatePageNumber({
        pageNo: 1,
      })
    );

    setSearchText(text);
    setIsSearchUsed(true);
  }, 2000);

  // Original handleSearch function now calls the debounced version
  const handleSearch = (text) => {
    debouncedHandleSearch(text);
  };

  const addCarParking = [
    {
      field_name: "Parking Slot",
      field_id: "parkingSlot",
      placeholder: "Enter Parking Slot",
      type: "text",
    },
    {
      field_name: "Provider",
      field_id: "provider",
      placeholder: "Enter Provider",
      type: "text",
    },
    {
      field_name: "Vehicle Number",
      field_id: "vehicleNumber",
      placeholder: "Enter Vehicle Number",
      type: "text",
    },
    {
      field_name: "Contact Name",
      field_id: "contactName",
      placeholder: "Enter Contact Name",
      type: "text",
    },
    {
      field_name: "Contact Number",
      field_id: "contactNumber",
      placeholder: "Enter Contact Number",
      type: "text",
    },
    {
      field_name: "Rent",
      field_id: "rent",
      placeholder: "Enter Rent",
      type: "number",
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return addCarParking.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });
  };

  // Parking Stats Board
  const stats_icons = [
    SlotsAvailable,
    SlotsAllocated,
    SlotsAllocated,
    SlotsAvailable,
    SlotsAllocated,
    SlotsAvailable,
    OccupancyPercentage,
    ParkingRevenue,
  ];

  const stats_names = [
    "Allocated",
    "Available",
    "Allocated LP",
    "Available LP",
    "Allocated PH",
    "Available PH",
    "Occupancy %",
    "Revenue",
  ];

  const car_parking_stats_mapping = [
    "totalSlotsAllocated",
    "totalSlotsAvailable",
    "allocatedSlotsLp",
    "availableSlotsLp",
    "allocatedSlotsParkhound",
    "availableSlotsParkhound",
    "occupancyPercentage",
    "parkingRevenue",
  ];

  // Parking Stats Table
  const parking_table_headers = [
    "Sr No.",
    "Parking Slot",
    "Provider",
    "Car Number",
    "Contact Name",
    "Contact Number",
    "Rent",
    "Edit",
  ];

  const parking_tableFieldMapping = [
    "sr_no",
    "parkingSlot",
    "provider",
    "vehicleNumber",
    "contactName",
    "contactNumber",
    "rent",
    "edit",
  ];

  const [currentParkingId, setCurrentParkingId] = useState(0);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [parkingTableData, setParkingTableData] = useState([]);

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      mappedData = {};

      mappedData._id = item._id;
      mappedData.sr_no = index + 1;
      mappedData.parkingSlot = item.parkingSlot;
      mappedData.provider = item.provider;
      mappedData.vehicleNumber = item.vehicleNumber;
      mappedData.contactName = item.contactName;
      mappedData.contactNumber = item.contactNumber;
      mappedData.rent = item.rent;
      mappedData.edit = (
        <IconButton
          buttonText={""}
          buttonIcon={EditIcon}
          onClick={() => {
            setCurrentParkingId(item._id);
            setOpenFormModal(true);
          }}
          whiteBg={true}
        ></IconButton>
      );

      mappedDataList.push(mappedData);
    });

    setParkingTableData(mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CAR_PARKING__CAR_PARKING",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__CAR_PARKING__STATS, "");
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "ADMIN__CAR_PARKING__CAR_PARKING",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CAR_PARKING__CAR_PARKING",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "ADMIN__CAR_PARKING__CAR_PARKING",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "ADMIN__CAR_PARKING__CAR_PARKING",
        scroll.pageNo,
        searchText
      );
      fetchPageStats(dispatch, STATS_KEYS.ADMIN__CAR_PARKING__STATS, "");
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.adminCarParking?.length >= 0)
      processTableData(stateDataTableData.adminCarParking);
  }, [stateDataTableData.adminCarParking]);

  useEffect(() => {
    if (currentParkingId !== 0) {
      const currentParkingData = stateDataTableData.adminCarParking.find(
        (item) => item._id === currentParkingId
      );
      setFormFields((prevFields) => {
        const updatedFormFields = [...prevFields];

        updatedFormFields[0] = {
          ...updatedFormFields[0],
          value: currentParkingData.parkingSlot,
        };
        updatedFormFields[1] = {
          ...updatedFormFields[1],
          value: currentParkingData.provider,
        };
        updatedFormFields[2] = {
          ...updatedFormFields[2],
          value: currentParkingData.vehicleNumber,
        };
        updatedFormFields[3] = {
          ...updatedFormFields[3],
          value: currentParkingData.contactName,
        };
        updatedFormFields[4] = {
          ...updatedFormFields[4],
          value: currentParkingData.contactNumber,
        };
        updatedFormFields[5] = {
          ...updatedFormFields[5],
          value: currentParkingData.rent,
        };

        return updatedFormFields;
      });
    }
  }, [currentParkingId]);

  const formHandler = async () => {
    // Form data creation
    let newCarParking = {};
    formFields.map((item) => {
      newCarParking[item.field_id] = item.value;
    });

    newCarParking = cleanFormObject(newCarParking);

    // Validate the form data
    const { error } = parkingSlotValidationSchema.validate(newCarParking);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // upadte
      if (currentParkingId !== 0) {
        response = await postFormData({
          form_key: "ADMIN__PARKING__UPDATE_CAR_PARKING_DETAILS",
          data: newCarParking,
          urlId: currentParkingId,
          method: "put",
        });
        if (response.success === true) {
          window.location.reload();
        }
      } else {
        // add
        response = await postFormData({
          form_key: "ADMIN__PARKING__ADD_CAR_PARKING",
          data: newCarParking,
        });
        if (response.success === true) {
          window.location.reload();
        }
      }

      if (response.success) {
        setOpenFormModal(false);
        navigate("/admin/car_parking");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  useEffect(() => {
    if (currentParkingId === 0) {
      handleFieldChange("parkingSlot", "", false, "");
      handleFieldChange("provider", "", false, "");
      handleFieldChange("vehicleNumber", "", false, "");
      handleFieldChange("contactName", "", false, "");
      handleFieldChange("contactNumber", "", false, "");
      handleFieldChange("rent", "", false, "");
    }
  }, [currentParkingId]);

  return (
    <div className={styles.admin_parking_content}>
      <StatsBoard
        boardName="Car Parking"
        statIcons={stats_icons}
        statNames={stats_names}
        statValuesMapping={car_parking_stats_mapping}
        statValues={statePageStats?.adminCarParkingStats}
      ></StatsBoard>

      <StatsTable
        tableTitle={"Car Parking"}
        tableHeaders={parking_table_headers}
        tableData={parkingTableData}
        tableFieldMapping={parking_tableFieldMapping}
        tableActionsElement={
          <div className={styles.parking_stats_table_actions}>
            <SearchField searchText={searchText} onSearch={handleSearch} />
            <ActionButton
              buttonText="Add Parking Slot"
              icon={AddIcon}
              onClick={() => {
                setCurrentParkingId(0);
                setOpenFormModal(true);
              }}
            />
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>Car Parking</div>
              <div className={styles.units_stats_table_actions}>
                <ActionButton
                  buttonText="Add Parking Slot"
                  icon={AddIcon}
                  onClick={() => {
                    setCurrentParkingId(0);
                    setOpenFormModal(true);
                  }}
                />
              </div>
            </div>

            <SearchField searchText={searchText} onSearch={handleSearch} />
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.adminCarParkingTotalPages}
      ></StatsTable>
      {/* {openFormModal && <CarParkingForm />} */}
      {openFormModal && (
        <CarParkingForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          setOpenFormModal={setOpenFormModal}
          formHandler={formHandler}
          currentParkingId={currentParkingId}
        />
      )}
    </div>
  );
};

export default CarParking;
