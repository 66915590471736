import { Route, Routes, useNavigate } from "react-router-dom";
import styles from "../../../../styles/sections/admin/tenants/Tenants.module.css";
import Tenants from "./Tenants";
import AddTenant from "./AddTenant";
import TenantDetails from "./TenantDetail";

const TenantsRoutes = () => {
  return (
    <div className={styles.admin_tenants_content}>
      <Routes>
        <Route exact path="/" element={<Tenants />} />
        <Route exact path="/addTenant/:tenantId?" element={<AddTenant />} />
        <Route path="/:tenantId" element={<TenantDetails />} />
      </Routes>
    </div>
  );
};

export default TenantsRoutes;
