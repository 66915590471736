import styles from "../../../styles/common/back_drop/BackDrop.module.css";
import backArrow from "../../../assets/common/back_arrow.svg";
import { useEffect, useState } from "react";
import { showLoader } from "../../../redux/reducers/loaderSlice";
import { getFileData } from "../../../generalApiCalls/getFileData";
import { useDispatch } from "react-redux";

const BackDrop = (props) => {
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const dispatch = useDispatch();

  const readFileFromServer = async () => {
    dispatch(showLoader(true));
    try {
      let data = {
        tenantId: props.tenantId,
        assetName: props.assetName,
      };

      if (props?.additionalData) {
        data = { ...data, ...props.additionalData };
      }

      let responseBlob = await getFileData({
        API_NAME: "ASSET_DOWNLOAD",
        data: data,
      });

      responseBlob = responseBlob.fileBlob;
      setFileType(responseBlob.type.split("/")[0]);
      // console.log("response    ///// ", response);
      // setFile(window.URL.createObjectURL(new Blob([responseBlob])));
      setFile(URL.createObjectURL(responseBlob));

      // console.log("responseBlob    ///// ", responseBlob);

      // responseBlob    /////  Blob {size: 1719, type: 'image/png'}

      // console.log(URL.createObjectURL(responseBlob));
    } catch {
      props.state();
      console.log("error in fetching file");
    }

    dispatch(showLoader(false));
  };

  useEffect(
    () => {
      console.log("props", props);
      if (props.tenantId && props.assetName) {
        console.log("props 1");

        readFileFromServer();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.backdrop}>
      <div className={styles.modal}>
        <div className={styles.backdrop_header}>
          <span className={styles.backdrop_img}>
            <img
              src={backArrow}
              alt=""
              className={styles.back_arrow}
              onClick={() => {
                props.state();
              }}
            ></img>
            {props.title}
          </span>
          {fileType === "image" && (
            <div
              className={styles.send_contact}
              onClick={() => {
                // download file
                const a = document.createElement("a");
                a.href = file;
                a.download = props?.title;
                a.click();
              }}
            >
              Download
            </div>
          )}
        </div>
        <div className={styles.body}>
          {file && (
            <>
              {/* <embed src={file} width="100%" height="100%"></embed> */}
              {/* <img src={file} alt="hih" className={styles.fileViewer} /> */}
              {fileType === "image" ? (
                <img
                  src={file}
                  alt="hih"
                  className={styles.fileviewer_backdrop_img}
                />
              ) : (
                <embed
                  src={file}
                  title="file"
                  type="application/pdf"
                  width="100%"
                  height="100%"
                ></embed>
              )}

              {/* <div className={styles.pdfControls}>
                <a
                  href={URL.createObjectURL(props.file)}
                  download="file.pdf"
                  className={styles.downloadButton}
                >
                  Download
                </a>
                <button
                  onClick={() => {
                    const embedElement = document.querySelector(
                      `.${styles.fileViewer} embed`
                    );
                    if (embedElement) {
                      embedElement.requestFullscreen();
                    }
                  }}
                  className={styles.fullScreenButton}
                >
                  Full Screen
                </button>
              </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackDrop;
