import styles from "../../../../styles/sections/admin/car_parking/CarParkingForm.module.css";
import { useEffect, useRef, useState } from "react";
import { showLoader } from "../../../../redux/reducers/loaderSlice";
import { postFormData } from "../../../../generalApiCalls/postFormData";
import { cleanFormObject } from "../../../../utils/generalFunctions";
import {
  parkingSlotValidationSchema,
  tenantRequestSchema,
} from "../../../../utils/validators";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ProfileUpdateForm from "../../../common/profile_update_form/ProfileUpdateForm";
import { getTableData } from "../../../../generalApiCalls/getTableData";

const RequestForm = ({ setOpenFormModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newRequestForm = [
    {
      field_name: "Reuest Type",
      field_id: "requestType",
      placeholder: "Please select Request Type",
      type: "dropdown",
      options: [
        { id: "Amenities", name: "Amenities" },
        { id: "Car Parking", name: "Car Parking" },
      ],
    },
  ];

  const continueRequestFormForCarParking = [
    {
      field_name: "Car Number",
      field_id: "vehicleNumber",
      placeholder: "Enter Car Number",
      type: "text",
    },
  ];

  const continueRequestFormForAmenities = [
    {
      field_name: "Amenity Name",
      field_id: "requestedItems",
      placeholder: "Enter Amenity Name",
      type: "dropdown",
      options: [
        // { id: "Swimming Pool", name: "Swimming Pool" },
        // { id: "Gym", name: "Gym" },
      ],
    },
  ];

  // Define a function to create the state variables for each item
  const initializeFormState = () => {
    return newRequestForm.map((item) => ({
      ...item,
      value: item.type === "text" ? null : item.type === "number" ? null : "",
      error: false,
      error_message: "",
    }));
  };

  const [formFields, setFormFields] = useState(initializeFormState());

  const initialRender = useRef(true);
  const allAmenities = async () => {
    if (initialRender.current) {
      dispatch(showLoader(true));
      initialRender.current = false;

      let resp = await getTableData({
        currentTableKey: "",
        page: 1,
        search: "",
        limit: 5000,
        apiEndpointKey: "TENANT__GET_ALL_AMENITIES_NAME",
      });

      let data = [];

      if (resp["data"] === undefined) {
        dispatch(showLoader(false));
        return;
      }

      resp["data"]["data"]?.map((item) => {
        data.push({ id: item._id, name: item.name });
      });

      setFormFields((prevFields) => {
        const updatedFormFields = [...prevFields];

        const index = updatedFormFields.findIndex(
          (item) => item.field_id === "requestedItems"
        );

        updatedFormFields[index] = {
          ...updatedFormFields[index],
          options: data,
        };
        return updatedFormFields;
      });

      dispatch(showLoader(false));
    }
  };

  // useEffect(() => {
  //   allAmenities();
  // }, []);

  // Define a function to handle state update for an item
  const handleFieldChange = (fieldId, value, error, errorMessage) => {
    setFormFields((prevFields) => {
      const updatedFormFields = [...prevFields];

      const index = updatedFormFields.findIndex(
        (item) => item.field_id === fieldId
      );

      updatedFormFields[index] = {
        ...updatedFormFields[index],
        value,
        error,
        error_message: errorMessage,
      };
      return updatedFormFields;
    });

    // add form fields for car parking and amenities as per the request type (don't duplicate the fields if already added) (while changing the request type remove the fields of the other type if added)
    if (fieldId === "requestType") {
      if (value === "Car Parking") {
        setFormFields((prevFields) => {
          const updatedFormFields = [...prevFields];

          continueRequestFormForCarParking.map((item) => {
            const index = updatedFormFields.findIndex(
              (item) => item.field_id === "vehicleNumber"
            );

            if (index === -1) {
              updatedFormFields.push({
                ...item,
                value:
                  item.type === "text"
                    ? null
                    : item.type === "number"
                    ? null
                    : "",
                error: false,
                error_message: "",
              });
            }
          });

          const index = updatedFormFields.findIndex(
            (item) => item.field_id === "requestedItems"
          );

          if (index !== -1) {
            updatedFormFields.splice(index, 1);
          }

          return updatedFormFields;
        });
      } else if (value === "Amenities") {
        setFormFields((prevFields) => {
          const updatedFormFields = [...prevFields];

          continueRequestFormForAmenities.map((item) => {
            const index = updatedFormFields.findIndex(
              (item) => item.field_id === "requestedItems"
            );

            if (index === -1) {
              updatedFormFields.push({
                ...item,
                value:
                  item.type === "text"
                    ? null
                    : item.type === "number"
                    ? null
                    : "",
                error: false,
                error_message: "",
              });
            }
          });

          const index = updatedFormFields.findIndex(
            (item) => item.field_id === "vehicleNumber"
          );

          if (index !== -1) {
            updatedFormFields.splice(index, 1);
          }

          return updatedFormFields;
        });
        allAmenities();
      }
    }
  };

  const formHandler = async () => {
    // Form data creation
    let newRequestData = {};
    formFields.map((item) => {
      newRequestData[item.field_id] = item.value;
    });

    newRequestData = cleanFormObject(newRequestData);

    // Validate the form data
    const { error } = tenantRequestSchema.validate(newRequestData);

    console.log(error);

    // If validation fails, update the error state and msg
    if (error) {
      error.details.map((item) => {
        let errorMessage = item.message;

        let errorFieldName = item.path[0];

        let fieldName = formFields.find(
          (item) => item.field_id === errorFieldName
        ).field_name;

        errorMessage = errorMessage
          .replace(/"/g, "")
          .replace(errorFieldName, fieldName);
        handleFieldChange(errorFieldName, "", true, errorMessage);
      });
    } else {
      dispatch(showLoader(true));
      // If validation passes, call the api to save the data

      let response = null;

      // add
      response = await postFormData({
        form_key: "TENANT__REQUEST__ADD_REQUEST",
        data: newRequestData,
      });

      if (response.success) {
        setOpenFormModal(false);
        window.location.reload();
        // navigate("/tenant/requestApprovals");
      } else {
        alert(response.message);
      }
      dispatch(showLoader(false));
    }
  };

  return (
    <div className={styles.form_modal}>
      <form className={styles.form_container}>
        <div className={styles.header}>
          <div className={styles.title}>New Request</div>
          <button
            onClick={() => {
              setOpenFormModal(false);
            }}
            className={styles.close_handler}
          >
            X
          </button>
        </div>
        <ProfileUpdateForm
          form_fields={formFields}
          handleFieldChange={handleFieldChange}
          formClass={styles.centre_form}
          submit={"Request"}
          formHandler={formHandler}
        />
      </form>
    </div>
  );
};

export default RequestForm;
