import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../styles/sections/admin/car_parking/CarParking.module.css";
import statsStyles from "../../../../styles/common/stats_table/StatsTable.module.css";

import StatsTable from "../../../common/stats_table/StatsTable";
import ActionButton from "../../../common/action_button/ActionButton";
import AddIcon from "../../../../assets/common/add_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { resetTableData } from "../../../../redux/reducers/admin/GetTableDataSlice";
import { fetchTableData } from "../../../../generalApiCalls/getTableData";
import RequestForm from "./TenantNewIssueForm";
import { updatePageNumber } from "../../../../redux/reducers/PageScrollSlice";
import { formatDate } from "../../../../utils/generalFunctions";

const TenantIssues = () => {
  const stateDataTableData = useSelector((state) => state.getTableData);
  const stateDataTotalPages = useSelector((state) => state.totalPages);
  const scroll = useSelector((state) => state.pageScroll);
  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const initialRender = useRef(true);

  // Parking Stats Table
  const approval_table_headers = ["Date", "Issue", "Status"];

  const approval_tableFieldMapping = ["date", "issue", "status"];

  const [openFormModal, setOpenFormModal] = useState(false);
  const [approvalTableData, setApprovalTableData] = useState([]);

  let status_colors = {
    open: "#FF5733",
    in_progress: "#4169E1",
    on_hold: "#808080",
    resolved: "#32CD32",
  };

  const processTableData = async (data) => {
    let mappedDataList = [];
    let mappedData = {};

    data?.map((item, index) => {
      console.log("Item: ", item);
      mappedData = {};

      mappedData._id = item._id;
      mappedData.sr_no = index + 1;
      mappedData.date = formatDate(item.createdAt);
      mappedData.issue = item.content;
      mappedData.status = (
        <p
          style={{
            color: status_colors[item.status?.toLowerCase()],
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {item.status?.toUpperCase()}
        </p>
      );

      mappedDataList.push(mappedData);
    });

    setApprovalTableData(mappedDataList);
    console.log("Mapped Data List: ", mappedDataList);
  };

  useEffect(() => {
    // To fetch the data for the first time
    if (initialRender.current) {
      console.log("I got triggered 1");
      initialRender.current = false;
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "TENANT__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }

    // To enable infinite scrolling when the search text is empty
    if (scroll.pageNo > 1 && searchText === "") {
      console.log("I got triggered 2");
      fetchTableData(
        dispatch,
        "TENANT__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      scroll.pageNo === 1
    ) {
      console.log("I got triggered 3");
      dispatch(
        updatePageNumber({
          pageNo: 1,
        })
      );
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "TENANT__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when the search text is not empty, pageNo is greater than 1 and scrolling is available
    if (
      searchText !== "" &&
      searchText?.length > 2 &&
      initialRender.current === false &&
      isSearchUsed &&
      scroll.pageNo > 1
    ) {
      console.log("I got triggered 4");
      fetchTableData(
        dispatch,
        "TENANT__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }

    // To fetch the data when search text is empty and pageNo is 1
    if (
      searchText === "" &&
      scroll.pageNo === 1 &&
      initialRender.current === false &&
      isSearchUsed
    ) {
      console.log("I got triggered 5");
      dispatch(resetTableData());
      fetchTableData(
        dispatch,
        "TENANT__ISSUES__ISSUES",
        scroll.pageNo,
        searchText
      );
    }
  }, [scroll.pageNo, searchText]);

  useEffect(() => {
    if (stateDataTableData.tenantIssues?.length >= 0)
      processTableData(stateDataTableData.tenantIssues);
  }, [stateDataTableData.tenantIssues]);

  return (
    <div className={styles.admin_parking_content}>
      <StatsTable
        tableTitle={"Issues"}
        tableHeaders={approval_table_headers}
        tableData={approvalTableData}
        tableFieldMapping={approval_tableFieldMapping}
        tableActionsElement={
          <div className={styles.parking_stats_table_actions}>
            <ActionButton
              buttonText="Raise an Issue Ticket"
              icon={AddIcon}
              onClick={() => {
                setOpenFormModal(true);
              }}
            />
          </div>
        }
        tableActionsElementMobile={
          <div
            className={
              statsStyles.stats_title_actions_container +
              " " +
              statsStyles.stats_title_actions_container_show
            }
          >
            <div className={statsStyles.statsTable_title_container}>
              <div className={statsStyles.stats_table_title}>Issues</div>
              <div className={styles.units_stats_table_actions}>
                <ActionButton
                  buttonText="Request Issue Ticket"
                  icon={AddIcon}
                  onClick={() => {
                    setOpenFormModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        }
        // setPageNumber={setPageNumber}
        totalPages={stateDataTotalPages.tenantIssuesTotalPages}
      ></StatsTable>
      {openFormModal && <RequestForm setOpenFormModal={setOpenFormModal} />}
    </div>
  );
};

export default TenantIssues;
