import axios from "axios";

// Extract the hostname from the window.location object
const hostname = window.location.hostname;

// Default API endpoint
let baseURL = "http://localhost:3000"; // Default endpoint

// Check if the app is running on localhost
if (
  hostname === "localhost" ||
  hostname === "127.0.0.1" ||
  hostname === "192.168.0.241"
) {
  baseURL = "http://localhost:3000"; // Local development endpoint
  // baseURL = "http://192.168.0.173:3000";
}
// Check if the app is running on app-dev.loyalpartners.com
else if (hostname === "app-dev.loyalpartners.com.au") {
  baseURL = "https://api-dev.loyalpartners.com.au"; // Development endpoint
}
// Check if the app is running on app.loyalpartners.com
else if (hostname === "app.loyalpartners.com.au") {
  baseURL = "https://api.loyalpartners.com.au"; // Production endpoint
}

const instance = axios.create({
  baseURL: baseURL,
});

export default instance;
